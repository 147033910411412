import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IconTheme146 from "../../../assets/SvgIcons/Themed/IconTheme146";
import layoutStorage from "../../../storages/layoutStorage";
import Core from "../../../core/Core";

class CookieNotification extends Component {
  onAcceptClick = () => {
    // document.cookie = "notification=true";
    localStorage.setItem("notification", 1);
    layoutStorage.hideCookieNotifications();
    this.forceUpdate();
  };

  render() {
    const { t } = this.props;
    if (!layoutStorage.allowCookieNotifications) return null;
    const className = classNames({
      "notification__inner px-sm-10 px-lg-15 mb-sm-10 mb-lg-15 js-notification-cookies": true,
      visible: layoutStorage.allowCookieNotifications,
      animate: layoutStorage.allowCookieNotifications,
    });

    return (
      <div className="notification position-relative js-notification">
        <div
          className={className}
          data-js-show-once="true"
          data-js-delay="0"
          data-js-cookies-life="1"
          data-js-notification-inner
        >
          <div className="notification-cookies position-relative p-20 text-center text-sm-left pointer-events-all">
            <div className="notification-cookies__bg position-absolute absolute-stretch" />
            <div className="position-relative">
              <p className="mb-0">{t("Cookie accept popup text")}</p>
              <div className="d-flex align-items-center justify-content-center justify-content-sm-end mt-15">
                <Link
                  to={Core.routing.createRoute("content", { slug: "cookie" })}
                  target="_blank"
                  className="notification-cookies__button-info btn-link py-10 py-sm-0 mr-20 text-uppercase"
                >
                  {t("Cookie Policy")}
                </Link>
                <div
                  onClick={this.onAcceptClick}
                  className="notification-cookies__button-close btn-link py-10 py-sm-0 d-flex align-items-center text-uppercase"
                  data-js-action="close"
                >
                  {t("Accept policy")}
                  <i>
                    <IconTheme146 />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CookieNotification.propTypes = {};

export default withTranslation()(CookieNotification);
