import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import layoutStorage from "../../../storages/layoutStorage";
// import DrawerPopUp from "./DrawerPopUp";
import Menu from "../HeaderParts/Menu";
import Drawer from "../../../UI/organisms/Drawer";
import "./NavigationPopUp.scss";
import MobileMenu from "../../../UI/organisms/MobileMenu";

class NavigationPopUp extends Component {
  render() {
    const { title } = this.props;
    if (!layoutStorage.popups.navigation) return null;
    return (
      <div className="container" style={{ fontSize: "15px" }}>
        <Drawer
          title={<span>&nbsp;</span>}
          opened={layoutStorage.popups.navigation}
          name="navigation"
          onClose={() => layoutStorage.closePopup("navigation")}
          position="left"
          hideChildren={false}
          className="popup-navigation"
        >
          {/* <div className="popup__body position-relative flex-lg-column"> */}
          {/*  <div className="popup-navigation js-popup-navigation"> */}
          {/*    <div */}
          {/*      className="popup-navigation__search search pt-lg-25 pb-lg-35 px-10 px-lg-0 js-popup-search-ajax" */}
          {/*    > */}
          <div className="popup-navigation__menu d-lg-none py-25 px-10 menu__megamenu">
            <MobileMenu variant="vertical" />
          </div>
          {/* </div> */}
          {/* <div className="popup-navigation__menu"> */}
          {/*  menu */}
          {/* </div> */}
          {layoutStorage.allowCurrencies && (
            <div className="popup-navigation__menu popup-navigation__currency">
              Curr
            </div>
          )}
          {layoutStorage.allowMultilang && (
            <div className="popup-navigation__menu popup-navigation__currency">
              Lang
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </Drawer>
      </div>
    );
  }
}

NavigationPopUp.propTypes = {};

export default observer(NavigationPopUp);
