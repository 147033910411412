import React from "react";
import PropTypes from "prop-types";

function Tab({ active, text, onClick }) {
  return (
    <div className={`tabs__btn ${active ? "active" : ""}`} onClick={onClick}>
      {text}
    </div>
  );
}

Tab.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
