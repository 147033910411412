import React, { Component } from "react";
import PropTypes from "prop-types";

class PlaceholderCard extends Component {
  render() {
    return (
      <div className=" ph-item w-100 h-100">
        <div className="ph-col-12">
          <div className="ph-picture"> </div>
          <div className="ph-row">
            <div className="ph-col-10 big"> </div>
            <div className="ph-col-2 empty big"> </div>
          </div>
          <div className="ph-row">
            <div className="ph-col-4"> </div>
            <div className="ph-col-2 empty"> </div>
            <div className="ph-col-4"> </div>
            <div className="ph-col-2 empty"> </div>
          </div>
          <div className="ph-row">
            <div className="ph-col-2 ph-avatar"> </div>
            <div className="ph-col-10 empty"> </div>
          </div>
          <div className="ph-row">
            <div className="ph-col-2"> </div>
            <div className="ph-col-1 empty"> </div>
            <div className="ph-col-2"> </div>
            <div className="ph-col-1 empty"> </div>
            <div className="ph-col-2"> </div>
            <div className="ph-col-1 empty"> </div>
            <div className="ph-col-2"> </div>
          </div>
          <div className="ph-row">
            <div className="ph-col-12 ph-button"> </div>
          </div>
        </div>
      </div>
    );
  }
}

PlaceholderCard.propTypes = {};

export default PlaceholderCard;
