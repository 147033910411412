import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * CSS pass
 *
 * @param link
 * @param title
 * @param position
 * @returns {JSX.Element}
 * @constructor
 */
function BreadcrumbItem({ link, title, position }) {
  return (
    <Link to={link} className="breadcrumbs__item" itemprop="item">
      <span itemProp="name">{title}</span>
      <meta itemProp="id" content={link} />
      <meta itemProp="position" content={position} />
    </Link>
  );
}

BreadcrumbItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default BreadcrumbItem;
