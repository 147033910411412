import React from "react";
import layoutStorage from "../../storages/layoutStorage";
import DropDown from "../../UI/molecules/DropDown";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function SelectCurrency() {
  return (
    <DropDown
      containerClassName="ml-lg-15"
      subcontainerClassName="js-currencies-list"
      selectorClassName="header__btn-currency"
      menuClassName="header__dropdown"
      currentValue={layoutStorage.currentCurrency}
      values={[
        { name: "UAH", value: "UAH" },
        { name: "USD", value: "USD" },
        { name: "EUR", value: "EUR" },
      ]}
      onSelect={layoutStorage.changeLanguage}
    />
  );
}

SelectCurrency.propTypes = {};

export default SelectCurrency;
