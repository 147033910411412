import React, { useState } from "react";
import classNames from "classnames";
import IconTheme229 from "../../assets/SvgIcons/Themed/IconTheme229";

function DropDown({
  containerClassName,
  subcontainerClassName,
  selectorClassName,
  currentValue,
  menuClassName,
  onSelect,
  values,
}) {
  const [opened, setOpened] = useState(false);
  const containerClass = classNames({
    "position-lg-relative d-none d-lg-block": true,
    [containerClassName]: !!containerClassName,
  });
  const subcontainerClass = classNames({
    "js-position js-dropdown": true,
    hovered: opened,
    [subcontainerClassName]: !!subcontainerClassName,
  });
  const selectorClass = classNames({
    " position-relative d-none d-lg-flex align-items-lg-center cursor-pointer": true,
    [selectorClassName]: !!selectorClassName,
  });
  const menuClass = classNames({
    "dropdown d-lg-none position-lg-absolute top-lg-100 right-lg-0": true,
    "show animate visible": opened,
    [menuClassName]: !!menuClassName,
  });
  const current = values.find((v) => v.value === currentValue);
  return (
    <div className={containerClass}>
      <div className={subcontainerClass}>
        <div className={selectorClass} onClick={() => setOpened(!opened)}>
          <span className="mt-lg-3">{current?.name ? current.name : ""}</span>{" "}
          <i>
            <IconTheme229 />
          </i>
        </div>
        <div className={menuClass}>
          <ul className="list-unstyled w-100 px-15 pb-30 py-lg-15">
            {values.map(({ name, value }) => (
              <li
                key={name}
                className={currentValue === value ? "active" : ""}
                onClick={() => {
                  setOpened(false);
                  onSelect(value);
                }}
              >
                <span>{name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

DropDown.propTypes = {};

export default DropDown;
