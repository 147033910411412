import React from "react";
import { Link } from "react-router-dom";
import layoutStorage from "../../storages/layoutStorage";
import Core from "../../core/Core";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function Logo() {
  return (
    <div
      className="header__logo header__logo--sticky-hidden d-flex align-items-center w-100"
      style={{ maxWidth: "120px" }}
    >
      <Link
        to={Core.routing.predefinedRoutes.home}
        className="d-block text-hide logo h6 w-100 m-0"
      >
        <img src={layoutStorage.logoImageUrl} alt="" />
        {/* Kid.ua.moda */}
      </Link>
    </div>
  );
}

Logo.propTypes = {};

export default Logo;
