import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import ProductCardPrice from "./components/prices/ProductCardPrice";
import ProductCardTitle from "./components/title/ProductCardTitle";
import ProductCardColorsCircleBlock from "./components/colors/ProductCardColorsCircleBlock";
import ProductSizesBlock from "./components/sizes/ProductSizesBlock";
import ProductCardActionsIcon from "./components/actions/ProductCardActionsIcon";
import ProductCardDescription from "./components/description/ProductCardDescription";
import ProductCardDetails from "./components/details/ProductCardDetails";
import ProductImageNavigationBlock from "./components/image/navigation/ProductImageNavigationBlock";
import ProductCardImage from "./components/image/ProductCardImage";
import ProductButton from "../../components/ProductButton";
import productsListStorage from "../../storages/productsListStorage";
import PlaceholderCard from "./components/placeholder/PlaceholderCard";
import ProductModel from "../../storages/models/productModel";
import layoutStorage from "../../storages/layoutStorage";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";
import Core from "../../core/Core";

class ProductCard extends Component {
  state = {
    imageIndex: 0,
  };

  // componentDidMount() {
  //   console.log("WREST Mounted", this.props.refNum);
  // }
  //
  // componentWillUnmount() {
  //   console.log("WREST UnMounted", this.props.refNum);
  // }

  nextImage = () =>
    this.setState((state) => {
      const { refNum } = this.props;
      const len = productsListStorage.products[refNum].images.length;
      return {
        imageIndex: state.imageIndex + 1 >= len ? 0 : state.imageIndex + 1,
      };
    });

  prevImage = () => {
    const { refNum } = this.props;
    const len = productsListStorage.products[refNum].images.length;
    this.setState((state) => ({
      imageIndex: state.imageIndex > 0 ? state.imageIndex - 1 : len - 1,
    }));
  };

  render() {
    const {
      refNum,
      columns,
      disableColorsAndSizesBlock,
      disableActionIcon,
      isCompareCard,
    } = this.props;
    // if (!productsListStorage.products[refNum]) return <PlaceholderCard />;
    const { imageIndex } = this.state;
    const containerClass = classNames({
      "products-card": true,
      "col-12 col-sm-6 col-md-4 col-lg-3": columns >= 4 || columns <= 0,
      "col-12 col-sm-6 col-md-4 col-lg-4": columns == 3,
      "col-12 col-sm-6 col-md-4 col-lg-6": columns == 2,
      "col-12 col-sm-12 col-md-12 col-lg-12": columns == 1,
    });
    const contentClass = classNames({
      "product-collection d-flex flex-column h-100": true,
      "mb-30": !isCompareCard,
      "mb-0": isCompareCard,
    });
    const product =
      productsListStorage.products[refNum] || new ProductModel(null, null);
    const {
      vendor,
      attributes: { gender },
      title,
      shortDescription,
      productMainTitle,
      color_sizes,
      selectedColor,
      selectedSize,
    } = product;

    const currentProduct =
      product.childProducts.find((p) => p.refNum === refNum && !!p.title) ||
      product;
    const titleText = currentProduct.productTitle;
    const selectedProductRefNum =
      color_sizes[`${selectedColor}-${selectedSize}`];

    const productDetailsLink = currentProduct.relativeLink;
    // const productDetailsLink = Core.routing.createRoute(
    //   Core.routing.routingSegments.SEGMENT_PRODUCT,
    //   {
    //     slug: `${refNum}/${selectedProductRefNum}`,
    //     title: currentProduct?.[`slug_${layoutStorage.currentLanguage}`]
    //       ? currentProduct[`slug_${layoutStorage.currentLanguage}`]
    //       : titleText,
    //     // title: product[`name${layoutStorage.currentLanguage === 'uk' ? "" : `_${layoutStorage.currentLanguage}`}`],
    //     // title: product.productMainTitle,
    //     // gender,
    //     // vendor,
    //     // refNum: selectedProductRefNum
    //   }
    // );
    return (
      <div className={containerClass}>
        <div className={contentClass}>
          {productsListStorage.loading ||
          !refNum ||
          !productsListStorage.products[refNum] ? (
            <PlaceholderCard />
          ) : (
            <>
              <div
                className="product-collection__image product-image product-image--hover-emersion-z
                        position-relative w-100 js-product-images-navigation
                        js-product-images-hovered-end js-product-images-hover"
              >
                <ProductCardImage
                  refNum={refNum}
                  imageIndex={imageIndex}
                  link={productDetailsLink}
                  title={titleText}
                  alt={titleText}
                  haveInStock={productsListStorage.products[refNum].haveInStock}
                />
                <ProductImageNavigationBlock
                  refNum={refNum}
                  prevImage={this.prevImage}
                  nextImage={this.nextImage}
                  imageIndex={imageIndex}
                  imagesCount={
                    productsListStorage.products[refNum].images.length
                  }
                  link={productDetailsLink}
                  product={productsListStorage.products[refNum]}
                />
              </div>
              <div className="product-collection__content d-flex flex-column align-items-start mt-15">
                <ProductCardTitle refNum={refNum} link={productDetailsLink} />
                {layoutStorage.allowProductCardDetails && (
                  <ProductCardDetails
                    refNum={refNum}
                    link={productDetailsLink}
                  />
                )}
                {layoutStorage.allowProductCardDescription && (
                  <ProductCardDescription
                    text={shortDescription}
                    link={productDetailsLink}
                  />
                )}
                <ProductCardPrice
                  link={productDetailsLink}
                  price={productsListStorage.products[refNum].price}
                  oldPrice={productsListStorage.products[refNum].oldPrice}
                />
                <div className="d-flex flex-column w-100 m-0">
                  {/* <input type="hidden" name="form_type" value="product" /> */}
                  {/* <input type="hidden" name="utf8" value="✓" /> */}
                  {/* <div className="product-collection__options"> */}

                  {!disableColorsAndSizesBlock && (
                    <div className="product-options product-options--type-collection js-product-options">
                      <ProductCardColorsCircleBlock
                        refNum={refNum}
                        link={productDetailsLink}
                      />
                      <ProductSizesBlock
                        refNum={refNum}
                        product={productsListStorage.products[refNum]}
                        link={productDetailsLink}
                        allowLink={false}
                      />
                    </div>
                  )}

                  {/* </div> */}
                  {/* <ProductCardVariants */}
                  {/*  refNum={refNum} */}
                  {/*  link={productDetailsLink} */}
                  {/* /> */}
                  <div className="product-collection__buttons d-flex flex-row flex-lg-row flex-nowrap align-items-lg-center mt-5 mt-lg-10">
                    {/* <div className="product-collection__button-add-to-cart mb-10"> */}
                    <ProductButton
                      refNum={refNum}
                      variant="bigButton"
                      icon={<SvgContaineredImage id="cartButtonIcon" />}
                      product={productsListStorage.products[refNum]}
                      isButtonLarge
                    />
                    {/* </div> */}
                    {/* <div className="product-collection__buttons-section d-flex px-lg-10"> */}
                    {!disableActionIcon && (
                      <ProductCardActionsIcon
                        refNum={refNum}
                        link={productDetailsLink}
                      />
                    )}
                    {/* </div> */}
                  </div>
                </div>
                {/* <ProductCardReviews refNum={refNum} link={productDetailsLink} /> */}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

ProductCard.defaultProps = {
  columns: 4,
  disableColorsAndSizesBlock: false,
  disableActionIcon: false,
  isCompareCard: false,
};
ProductCard.propTypes = {};

export default observer(ProductCard);
