import i18n from "i18next";
import Core from "../Core";
import layoutStorage from "../../storages/layoutStorage";

function createRoute(segment, config) {
  // console.log("CREATE ROUTE:", segment, config);
  const structure = Core.routing.parseRoute(window.location);
  const lang = structure.language || layoutStorage?.currentLanguage || "ru";
  let routeUrl = `/${lang}/`;
  switch (segment) {
    case Core.routing.routingSegments.SEGMENT_ACCOUNT:
      return `${routeUrl}${Core.routing.routingSegments.SEGMENT_ACCOUNT}`;
      break;
    case Core.routing.routingSegments.SEGMENT_ORDER:
      return Core.routing.createOrderRoute(routeUrl, config.slug, config.id);
    case Core.routing.routingSegments.SEGMENT_PRODUCT:
      return Core.routing.createProductRoute(
        routeUrl,
        config.slug,
        config.title,
        config.vendor,
        i18n.t(config.gender),
        config.refNum
      );
    case Core.routing.routingSegments.SEGMENT_CITY:
      return Core.routing.createFilterRoute(
        config.slug || config.city,
        Core.routing.routingSegments.SEGMENT_CITY
      );
    case Core.routing.routingSegments.SEGMENT_SEASONS:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.seasons),
        Core.routing.routingSegments.SEGMENT_SEASONS
      );
    case Core.routing.routingSegments.SEGMENT_SINGLES:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.singles),
        Core.routing.routingSegments.SEGMENT_SINGLES
      );
    case Core.routing.routingSegments.SEGMENT_SIZE:
    case Core.routing.routingSegments.SEGMENT_SIZES:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.size),
        Core.routing.routingSegments.SEGMENT_SIZE
      );
    case Core.routing.routingSegments.SEGMENT_VGROUP:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.vgroup),
        Core.routing.routingSegments.SEGMENT_VGROUP
      );
    case Core.routing.routingSegments.SEGMENT_GROUP:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.group),
        Core.routing.routingSegments.SEGMENT_GROUP
      );
    case Core.routing.routingSegments.SEGMENT_CART:
    case Core.routing.routingSegments.SEGMENT_CONTENT:
      if (config.slug !== undefined) {
        routeUrl = `${routeUrl}${segment}/${config.slug}`;
      } else {
        routeUrl = `${routeUrl}${segment}`;
      }
      break;
    case Core.routing.routingSegments.SEGMENT_GENDER:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.gender),
        Core.routing.routingSegments.SEGMENT_GENDER
      );
    case Core.routing.routingSegments.SEGMENT_BRAND:
      return Core.routing.createFilterRoute(
        i18n.t(config.slug || config.gender),
        Core.routing.routingSegments.SEGMENT_BRAND
      );
    case Core.routing.routingSegments.SEGMENT_CATALOG:
    case Core.routing.routingSegments.SEGMENT_CATEGORY:
      console.log("CREATE MENU:", config);
      if (config.category) {
        const catTranslit = Core.lang.translit(config.category);
        routeUrl = `${routeUrl}catalog/cat-${catTranslit}`;
      }
      if (config.full && config.slug) {
        const catTranslit = Core.lang.translit(config.slug);
        routeUrl = `${routeUrl}catalog/cat-${catTranslit}`;
      }
      if (!config.full && config.slug) {
        const catTranslit = Core.lang.translit(config.slug);
        routeUrl = `cat-${catTranslit}`;
      }
      break;
    case Core.routing.routingSegments.SEGMENT_TRANSLIT:
      return Core.lang.translit(config.slug);
    case Core.routing.routingSegments.SEGMENT_HOME:
      return `/${lang}`;
    default:
      if (config.slug) {
        routeUrl = Core.lang.translit(config.slug);
      }
  }
  return routeUrl;
}

export default createRoute;
