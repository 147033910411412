import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import productsListStorage from "../../../../storages/productsListStorage";

class ProductCardTitle extends Component {
  render() {
    const { refNum, link } = this.props;
    if (!productsListStorage.products || !productsListStorage.products[refNum])
      return null;
    const { productTitle } = productsListStorage.products[refNum];
    return (
      // <div
      //   className="product-collection__title h6 m-0 mb-3"
      //   style={{
      //     overflow: "hidden",
      //     width: "100%",
      //   }}
      // >
      <Link
        to={link}
        title={productTitle}
        className="product-collection__title h6 m-0 mb-6"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "100%",
          textOverflow: "ellipsis",
        }}
      >
        {productTitle}
      </Link>
      // </div>
    );
  }
}

ProductCardTitle.propTypes = {};

export default observer(ProductCardTitle);
