import urls from "../../storages/api/urls";
import productsListStorage from "../../storages/productsListStorage";
import productsConverter from "../../storages/adapters/optModa/productsConverter";
import contentStorage from "../../storages/contentStorage";

const newAndSalesApiProcessor = {
  url: `${urls.baseUrl}/api/v7/products/newandsales`,
  method: "post",
  data: {
    limit: 8,
  },
  processor: (data) => {
    productsListStorage.newProductsList = [];
    productsListStorage.specialProductsList = [];
    productsListStorage.featuredProductsList = [];
    const result = productsConverter(data);
    productsListStorage.products = {
      ...productsListStorage.products,
      ...result.products,
    };
    productsListStorage.newProductsList = data?.newProductsList || [];
    productsListStorage.specialProductsList = data?.saleProductsList || [];
    productsListStorage.featuredProductsList = data?.featuredProduct || [];
    productsListStorage.featuredProductsList = data?.featuredProductList || [];
    productsListStorage.featuredProductsIdList =
      data?.featuredProductList || [];
    productsListStorage.loading = false;
    contentStorage.setContent(data?.content);
    // filtersStorage.updateDictionaries(response?.data?.dictionaries);
    // contentStorage.setContent(response?.data?.content);
  },
};

export default newAndSalesApiProcessor;
