import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import layoutStorage from "../../../storages/layoutStorage";
import servicePageStorage from "../../../storages/forPages/servicePageStorage";
// import DrawerPopUp from "./DrawerPopUp";
import contentStorage from "../../../storages/contentStorage";
import Drawer from "../../../UI/organisms/Drawer";

function ServicesPopup({ t }) {
  const { title, mainText } = servicePageStorage;
  if (!layoutStorage.popups.service) return null;
  // console.log(contentStorage.pages);
  return (
    <Drawer
      title={t(title)}
      opened={layoutStorage.popups.service}
      id="services"
      onClose={() => layoutStorage.closePopup("service")}
      position="right"
    >
      <div
        className="popup-services__content"
        dangerouslySetInnerHTML={{
          __html:
            contentStorage.pages.service &&
            contentStorage.pages.service.content.content,
        }}
      />
    </Drawer>
  );
}

ServicesPopup.propTypes = {};

export default withTranslation()(observer(ServicesPopup));
