import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { observer } from "mobx-react";
import PopperItem from "../../../components/PopperItem";
import productsListStorage from "../../../storages/productsListStorage";

function Indicator({
  name,
  icon,
  openPopup,
  popperText,
  popperPlacement,
  className,
  href,
  withMargin,
  showPopperTextOnClick,
  counterValue,
}) {
  const indicatorClass = classNames({
    "position-relative d-flex align-items-center ml-25 ml-lg-15": withMargin,
    "position-relative d-flex align-items-center": !withMargin,
    [className]: !!className,
  });
  // if (showIndicatorOnMaunt) {
  //   openPopup(name);
  // }
  return (
    <PopperItem
      popperText={popperText}
      placement={popperPlacement}
      className={indicatorClass}
      href={href}
      showPopperTextOnClick={showPopperTextOnClick}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        openPopup(name);
      }}
    >
      <i>{icon}</i>
      {counterValue > 0 && (
        <span className="header__counter">{counterValue}</span>
      )}
    </PopperItem>
  );
}

Indicator.propTypes = {
  openPopup: PropTypes.func.isRequired,
  name: PropTypes.string,
  icon: PropTypes.any,
  popperText: PropTypes.string,
  popperPlacement: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  withMargin: PropTypes.bool,
  showIndicatorOnMaunt: PropTypes.bool,
};
Indicator.defaultProps = {
  withMargin: true,
};

export default observer(Indicator);
