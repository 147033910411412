import React from 'react';
import PropTypes from "prop-types";

function ArrowRightIcon() {
  return (
    <svg
      aria-hidden="true"
      focusable="false" role="presentation"
      className="icon icon-theme-231"
      viewBox="0 0 24 24"
    >
      <path
        d="M10.806 7.232l3.75 3.75c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .877.877 0 0 1-.215-.127.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449l3.32-3.301L9.907 8.13a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.652.652 0 0 1 .449-.176c.169 0 .319.058.45.176z"/>
    </svg>
  );
}

ArrowRightIcon.propTypes = {
};

export default ArrowRightIcon;
