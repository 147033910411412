import logger from "../helpers/logger";

function requestGet(url) {
  return (
    fetch(url, {
      method: "get",
      mode: "cors",
      credentials: "include",
    })
      // .post(url, rq.data || null)
      .catch((e) => {
        logger.log("RQ FAIL:", url, e);
      })
      .then((r) => (r ? r.json() : null))
  );
}

export default requestGet;
