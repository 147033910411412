import React, { useState } from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import currentCategoryStorage from "../../../storages/currentCategoryStorage";
import "./FooterLinks.scss";
import { Link } from "react-router-dom";
import TabularPanel from "../TabularPanel";

function FootersLinks({ t }) {
  const [currentTab, setCurrentTab] = useState(0);
  if (!currentCategoryStorage.footersLinks) return null;
  if (!currentCategoryStorage.haveFootersLinks) return null;
  return (
    <div id="footers-links">
      <TabularPanel
        tabs={
          currentCategoryStorage.footersLinks &&
          currentCategoryStorage.footersLinks.map((f) => t(f.title))
        }
        tabsContent={
          currentCategoryStorage.footersLinks &&
          currentCategoryStorage.footersLinks.map((section) => (
            <div className="container">
              {(section.type === undefined || section.type === "standard") &&
                section.links &&
                section.links.map((item) => (
                  <Link
                    className="col-md-6 col-sm-6 col-xs-12 col-lg-3 pb-5"
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                ))}
              {section.type === "multilevel" && (
                <div className="vertical-sections">
                  {/* <div>{JSON.stringify(item.children)}</div> */}
                  <TabularPanel
                    dataType="vertical"
                    tabs={
                      section.links &&
                      section.links.map &&
                      section.links.map((childItem) => childItem.title)
                    }
                    tabsContent={
                      section.links &&
                      section.links.map &&
                      section.links.map((childItem) => (
                        <div>
                          {childItem.children.map((childLink) => (
                            <Link
                              className="col-lg-4 col-md-6 col-sm-6"
                              to={childLink.link}
                            >
                              {childLink.title}
                            </Link>
                          ))}
                        </div>
                      ))
                    }
                  />
                </div>
              )}
            </div>
          ))
        }
      />
    </div>
  );
  return (
    <div id="footers-links" className="footers-links container tabs js-tabs">
      <div className="footers-links__tabs tabs__head">
        <div className="tabs__slider justify-content-lg-center">
          {currentCategoryStorage.footersLinks.map((block, index) => (
            <div
              className={`footers-links__tab tabs__btn ${
                currentTab === index ? " active" : ""
              }`}
              onClick={() => setCurrentTab(index)}
              data-type="horizontal"
            >
              {block.title}
            </div>
          ))}
        </div>
      </div>
      <div className="footers-links__content-container ">
        <div className="footers-links__block">
          {currentCategoryStorage.footersLinks?.[currentTab]?.links &&
            currentCategoryStorage.footersLinks[currentTab].links.map(
              (item) => (
                <Link className="col-lg-4 pb-5" to={item.link}>
                  {item.title}
                </Link>
              )
            )}
        </div>
      </div>
    </div>
  );
}

FootersLinks.propTypes = {};

export default withTranslation()(observer(FootersLinks));
