import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import ProductCardColorsCircleItem from "./ProductCardColorsCircleItem";
import productsListStorage from "../../../../storages/productsListStorage";

class ProductCardColorsCircleBlock extends Component {
  render() {
    const { refNum } = this.props;
    const {
      colors,
      selectedColor,
      setField,
      haveInStock,
    } = productsListStorage.products[refNum];
    return (
      <>
        {/* <label className="d-none">Color:</label> */}
        <div className="product-options__section d-flex flex-wrap px-2 align-items-center">
          {colors.map((color) => (
            <ProductCardColorsCircleItem
              key={color}
              value={color}
              active={selectedColor === color}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setField("selectedColor", color);
              }}
              disabled={!haveInStock}
            />
          ))}
        </div>
      </>
    );
  }
}

ProductCardColorsCircleBlock.propTypes = {};

export default observer(ProductCardColorsCircleBlock);
