import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import LoadableImage from "./LoadableImage";

class HoveredImage extends Component {
  state = {
    hovered: false,
  };

  onMouseEnter = () => {
    this.setState((state) => ({ hovered: true }));
  };

  onMouseLeave = () => {
    this.setState((state) => ({ hovered: false }));
  };

  render() {
    const {
      link,
      style,
      mediaUrl,
      altText,
      srcSet,
      secondMediaUrl,
      allowSpinner,
      title,
      alt,
      t,
    } = this.props;
    const { hovered } = this.state;
    return (
      <Link to={link} className="d-block cursor-default" onClick={this.onClick}>
        <picture
          className="rimage"
          style={style}
          title={title}
          alt={alt}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {allowSpinner && (
            <LoadableImage
              link={
                hovered && window.innerWidth >= 778 ? secondMediaUrl : mediaUrl
              }
              title={title}
              alt={alt}
            />
          )}
          {!allowSpinner && (
            <img
              // style={{
              //   backgroundImage: `url(${hovered ? secondMediaUrl : mediaUrl}`,
              //   backgroundRepeat: "no-repeat",
              //   backgroundPosition: "center",
              //   backgroundSize: "contain",
              // }}
              src={hovered ? secondMediaUrl : mediaUrl}
              title={
                hovered
                  ? `${title || ""} - ${t("Foto")} 2`
                  : `${title || ""} - ${t("Foto")} 1`
              }
              alt={
                hovered
                  ? `${title || ""} - ${t("Foto")} 2`
                  : `${title || ""} - ${t("Foto")} 1`
              }
              // src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              loading="lazy"
              // srcSet={srcSet.map((s) => `${s.url} ${s.width}w`).join(",")}
              sizes="(max-width: 480px) 150px,(min-width: 481px) 300px"
            />
          )}
        </picture>
      </Link>
    );
  }
}

HoveredImage.propTypes = {};

export default withTranslation()(HoveredImage);
