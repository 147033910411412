import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import SizeItem from "../../../../components/SizeItem";
import shoppingCartStorage from "../../../../storages/shoppingCartStorage";
import Core from "../../../../core/Core";

class ProductSizesBlock extends Component {
  onSelectSize = (e, size) => {
    const { refNum, product, allowLink } = this.props;
    if (!allowLink) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { setField } = product;
    setField("selectedSize", size);
  };

  render() {
    const { t } = this.props;
    const { refNum, product } = this.props;
    const {
      sizes,
      sizes_inStock,
      color_sizes_inStock,
      selectedSize,
      selectedColor,
      setField,
      color_sizes,
      haveInStock,
      productTitle,
      vendor,
      title,
      attributes: { gender },
    } = product;
    return (
      <>
        {/* <label className="d-none">{t("Size")}</label> */}
        <div className="product-options__section d-flex flex-wrap">
          {sizes
            .slice()
            .sort((a, b) => {
              const a1 = parseInt(
                typeof a === "string" ? a.replace(/[0-9]/, "") : a,
                10
              );
              const b1 = parseInt(
                typeof b === "string" ? b.replace(/[0-9]/, "") : b,
                10
              );
              if (a1 > b1) return 1;
              if (a1 < b1) return -1;
              if (a1 === b1) return 0;
            })
            .map((size) => {
              const childRefNum = color_sizes[`${selectedColor}-${size}`];
              const haveInStock =
                color_sizes_inStock[`${selectedColor}-${size}`] > 0;
              const inCart =
                shoppingCartStorage.items[childRefNum] !== undefined;
              const currentProduct =
                product.childProducts.find(
                  (p) => p.refNum === childRefNum && !!p.title
                ) || product;
              const titleText = currentProduct.productTitle;
              return (
                <SizeItem
                  key={`${selectedColor}-${size}`}
                  value={size}
                  active={selectedSize === size}
                  inCart={inCart}
                  onClick={this.onSelectSize}
                  enabled={haveInStock}
                  storeScroll
                  link={
                    currentProduct.relativeLink
                    //   Core.routing.createRoute(
                    //   Core.routing.routingSegments.SEGMENT_PRODUCT,
                    //   {
                    //     title: titleText,
                    //     // vendor,
                    //     // gender,
                    //     slug: `${refNum}/${childRefNum}`,
                    //     // ${childRefNum}
                    //   }
                    // )
                  }
                />
              );
            })}
        </div>
      </>
    );
  }
}

ProductSizesBlock.defaultProps = {
  allowLink: false,
};
ProductSizesBlock.propTypes = {};

export default withTranslation()(observer(ProductSizesBlock));
