import React from "react";
import PropTypes from "prop-types";
import IconTheme190 from "../../../assets/SvgIcons/Themed/IconTheme190";

function ButtonCloseSmall({ className, onClick }) {
  return (
    <i className={`${className} cursor-pointer`} onClick={onClick}>
      <IconTheme190 />
    </i>
  );
}

ButtonCloseSmall.defaultProps = {
  className: null,
};
ButtonCloseSmall.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ButtonCloseSmall;
