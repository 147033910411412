import React, { Component } from "react";
import PropTypes from "prop-types";
// import waterfall from "async-es/waterfall";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
// import productsListStorage from "../storages/productsListStorage";
import logger from "../helpers/logger";
import layoutStorage from "../storages/layoutStorage";
import processRoute from "../helpers/processRoute";
import productsListStorage from "../storages/productsListStorage";

class Page extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    logger.log(
      "NAVIGATE FROM ",
      prevProps.location,
      " TO ",
      this.props.location
    );
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      // const urlParams = new URLSearchParams(window.location.search);
      // const currentPage = urlParams.get("page");
      // if (currentPage && +currentPage > 0) {
      //   productsListStorage.selectedPage(+currentPage);
      // } else {
      //   productsListStorage.currentPage = 0;
      // }
      // if (productsListStorage.currentPage != +urlParams.get("page")) {
      //   window.history.push(`?page=${currentValue + 1}`);
      // }
      processRoute();
      if (!this.props.storeScroll) {
        window.scroll(0, 0);
      }
    }
    // this.processRoute();
  }

  render() {
    const { id, className, children } = this.props;
    return (
      <main
        id={id}
        className={className}
        style={{
          paddingBottom:
            layoutStorage.isEditorEnabled && layoutStorage.editablePath
              ? "50vh"
              : null,
        }}
      >
        {/* <ScrollRestoration /> */}
        {children}
      </main>
    );
  }
}

Page.defaultProps = {
  id: null,
  className: "",
  children: null,
  storeScroll: false,
};

Page.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  loadingConfig: PropTypes.shape({
    initial: PropTypes.bool,
  }).isRequired,
  storeScroll: PropTypes.bool,
};

export default withRouter(observer(Page));
