import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import PopperItem from "./PopperItem";

class SizeItem extends Component {
  onClick = (e) => {
    const { enabled, value, onClick } = this.props;
    if (enabled) {
      onClick(e, value);
    } else {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  render() {
    const { link, value, enabled, active, inCart, t, storeScroll } = this.props;
    const className = classNames({
      "product-options__value product-options__value--large-text d-flex flex-center border cursor-pointer lazyload loaded": true,
      active,
      block__enabled: enabled,
      block__disabled: !enabled,
      block__text_bold: inCart,
    });
    return (
      <PopperItem
        popperText={enabled ? value : t("OUT OF STOCK")}
        enabled={!enabled}
        className={className}
        href={link}
        onClick={this.onClick}
      >
        {value}
      </PopperItem>
    );
  }
}

SizeItem.propTypes = {};

export default withTranslation()(SizeItem);
