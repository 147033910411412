import React, { Component } from "react";
import PropTypes from "prop-types";
import CompareProductCardList from "./CompareProductCardList";
import CompareContentTableLeftCell from "./CompareContentTableLeftCell";
import CompareContentTableRightCell from "./CompareContentTableRightCell";
import CompareContentTableRow from "./CompareContentTableRow";

class CompareContentTable extends Component {
  render() {
      const { productsForCompare } = this.props;
      return (
      <div className="container">
        <table
          className="table d-block pb-20"
          data-js-store-lists-has-items-compare
        >
          {/*<tr>*/}
          {/*  <td className="compare__title pt-0 pl-0 border-right border-top-0">*/}
          {/*    <h4 className="h6 mb-0">PRODUCTS</h4>*/}
          {/*  </td>*/}
          {/*  <td className="pt-0 border-top-0">*/}
          {/*    <div className="compare__items d-flex">*/}
          {/*      /!* <div className="compare__item col"> *!/*/}
          {/*      /!*  <div *!/*/}
          {/*      /!*      className="product-compare d-flex flex-column" *!/*/}
          {/*      /!*      data-js-product *!/*/}
          {/*      /!*      data-js-store-lists-product-compare *!/*/}
          {/*      /!*      data-product-handle="skinny-mid-rise-trousers" *!/*/}
          {/*      /!*      data-product-variant-id="13519945957428" *!/*/}
          {/*      /!*  > *!/*/}
          {/*      <CompareProductCardList />*/}
          {/*    </div>*/}
          {/*  </td>*/}
          {/*  <td className="compare__title d-lg-none w-100 pt-0 pr-0 text-right border-left border-top-0">*/}
          {/*    <h4 className="h6 mb-0">PRODUCTS</h4>*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {productsForCompare.map((row) => {
            return <CompareContentTableRow
                title={row.title}
                data={row.data}
            />
          })}

          {/*<CompareContentTableRow*/}
          {/*  title="COLLECTION"*/}
          {/*  text={compareProductList[0].attributes.collection}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="AVAILABILITY"*/}
          {/*  text={compareProductList[0].inStock}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="PRODUCT TYPE"*/}
          {/*  text={compareProductList[0].attributes.grooup}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="VENDOR"*/}
          {/*  text={compareProductList[0].vendor}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="SKU"*/}
          {/*  text={compareProductList[0].sku}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="BARCODE"*/}
          {/*  text={compareProductList[0].attributes.barcode}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="COLOR"*/}
          {/*  text={compareProductList[0].colors[0]}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="SIZE"*/}
          {/*  text={compareProductList[0].sizes[0]}*/}
          {/*/>*/}

          {/*<CompareContentTableRow*/}
          {/*  title="SELECT COLOR"*/}
          {/*  text="select color"*/}
          {/*/>*/}
        </table>
        <div
          className="d-flex flex-column align-items-center py-40 py-md-100 my-100 d-none-important"
          data-js-store-lists-dhas-items-compare
        >
          <h3>Compare</h3>
          <p>No products were added to the compare</p>
        </div>
      </div>
    );
  }
}

CompareContentTable.propTypes = {};

export default CompareContentTable;
