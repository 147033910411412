import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class ColorFilters extends Component {
  render() {
    const { t } = this.props;
    return (
      <div
        className="layer-navigation"
        data-js-collection-nav-section="filters"
        data-js-accordion="all"
      >
        <div
          className="layer-navigation__head py-10 cursor-pointer open"
          data-js-accordion-button
        >
          <div className="h5 d-flex align-items-center mb-0">
            {t("title")}
            <i className="layer-navigation__arrow">
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-theme-229"
                viewBox="0 0 24 24"
              >
                <path d="M11.783 14.088l-3.75-3.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l3.301 3.32 3.301-3.32a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .841.841 0 0 1-.215-.127z" />
              </svg>
            </i>
          </div>
        </div>
        <div className="layer-navigation__accordion" data-js-accordion-content>
          <div className="pt-2 pb-10">
            <div
              data-js-collection-replace="filter-1"
              data-js-collection-replace-only-full
            >
              <div
                className="collection-filters d-flex flex-wrap"
                data-js-collection-filters
                data-property="color"
              >
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="red"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-red lazyload"
                    data-value="red"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="orange"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-orange lazyload"
                    data-value="orange"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="yellow"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-yellow lazyload"
                    data-value="yellow"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="green"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-green lazyload"
                    data-value="green"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="black"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-black lazyload"
                    data-value="black"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="silver"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-silver lazyload"
                    data-value="silver"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="blue"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-blue lazyload"
                    data-value="blue"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="grey"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-grey lazyload"
                    data-value="grey"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="white"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-white lazyload"
                    data-value="white"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="violet"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-violet lazyload"
                    data-value="violet"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
                <label className="input-checkbox d-flex align-items-center mb-15 mb-lg-10 mr-15 mr-lg-10 input-checkbox--unbordered cursor-pointer">
                  <input
                    type="checkbox"
                    className="d-none"
                    name="filter_by_color"
                    value="pink"
                  />
                  <span
                    className="position-relative d-block rounded-circle standard-color-pink lazyload"
                    data-value="pink"
                    data-bg="none"
                  >
                    <i className="d-none standard-color-arrow">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-theme-146"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z" />
                      </svg>
                    </i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom" />
      </div>
    );
  }
}

ColorFilters.propTypes = {};

export default withTranslation()(ColorFilters);
