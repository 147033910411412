import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "./Label";
import ErrorBox from "../blocks/ErrorBox";
import currentOrderStorage from "../../../storages/orderStorage";

class TextInput extends Component {
  render() {
    const {
      type,
      id,
      name,
      value,
      placeholder,
      onChange,
      onKeyPress,
      isRequired,
      isOptional,
      autoComplete,
      isValid,
      className,
      label,
      labelClass,
      textErrorLabel,
      step,
    } = this.props;
    const cn = classNames({
      success: isValid,
      error: !isValid,
      "border-dark": isOptional,
      className: !!className,
      "d-flex flex-column w-100": textErrorLabel,
    });
    const component = (
      <div className={cn}>
        <ErrorBox text={textErrorLabel} />
        <input
          type={type}
          id={id}
          name={name || id}
          value={value}
          placeholder={placeholder}
          onInput={(e) => onChange(e.target.value)}
          onKeyUp={onKeyPress}
          required={isRequired}
          autoComplete={autoComplete}
          className={cn}
          isValid={isValid}
          step={step}
        />
      </div>
    );
    if (label)
      return (
        <label className={labelClass}>
          <span>{label}</span>
          {component}
        </label>
      );
    return component;
  }
}

TextInput.defaultProps = {
  type: "text",
  name: null,
  placeholder: null,
  isRequired: false,
  autoComplete: null,
  isValid: false,
  // isValid: false,
};
TextInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default TextInput;
