import React from "react";
import PropTypes from "prop-types";

function IconInButton({ icon }) {
  return <i className="btn__icon mr-5 mb-4">{icon}</i>;
}

IconInButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any.isRequired,
};

export default IconInButton;
