import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TreeFilterItem from "./TreeFilterItem";
import DropDownArrow from "../../../components/Dropdowns/DropDownArrow";

class TreeFilterContainer extends Component {
  render() {
    const { t, title, data } = this.props;
    const level = 0;
    return (
      <div
        className="layer-navigation"
        data-js-collection-nav-section="collections"
        data-js-accordion="all"
      >
        <div
          className="layer-navigation__head py-10 cursor-pointer open"
          data-js-accordion-button
        >
          <div className="h5 d-flex align-items-center mb-0">
            {t("title")}
            <DropDownArrow className="layer-navigation__arrow" />
            {/* <i className="layer-navigation__arrow"> */}
            {/*  <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-theme-229" viewBox="0 0 24 24"> */}
            {/*    <path d="M11.783 14.088l-3.75-3.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l3.301 3.32 3.301-3.32a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .841.841 0 0 1-.215-.127z"/> */}
            {/*  </svg> */}
            {/* </i> */}
          </div>
        </div>
        <div className="layer-navigation__accordion" data-js-accordion-content>
          <div className="pt-2 pb-10">
            <div className="collections-menu" data-js-collections-menu>
              {data &&
                data.map((item) => (
                  <TreeFilterItem
                    title={item.title}
                    name={item.name}
                    count={item.count}
                    level={level}
                    nested={item.children}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TreeFilterContainer.propTypes = {};

export default withTranslation()(TreeFilterContainer);
