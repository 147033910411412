import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import ProductCardBagesBlock from "../badges/ProductCardBadgesBlock";
import layoutStorage from "../../../../../storages/layoutStorage";
import SvgContaineredImage from "../../../../../UI/atoms/images/SvgContaineredImage";

class ProductImageNavigationBlock extends Component {
  render() {
    const {
      refNum,
      prevImage,
      nextImage,
      imagesCount,
      link,
      imageIndex,
      product,
    } = this.props;
    return (
      <>
        <div
          className="product-image__overlay-top position-absolute d-flex
                                    flex-wrap top-0 left-0 w-100 px-10 pt-10"
        >
          <Link to={link} className="absolute-stretch cursor-default" />
          <ProductCardBagesBlock
            refNum={refNum}
            variant="column"
            product={product}
          />

          {layoutStorage.allowQuickView && (
            <div
              className="product-image__overlay-top-right product-collection__button-quick-view
                                     position-lg-relative d-none d-lg-flex mb-lg-10 ml-lg-auto"
            >
              <Link
                to={link}
                className="button-quick-view d-flex flex-center rounded-circle js-popup-button"
              >
                {/* <i> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className="icon icon-theme-154"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.528 17.238c-1.107-.592-2.074-1.25-2.9-1.973-.827-.723-1.491-1.393-1.992-2.012-.501-.618-.771-.96-.811-1.025a.571.571 0 0 1-.117-.352c0-.13.039-.247.117-.352.039-.064.306-.406.801-1.025.495-.618 1.159-1.289 1.992-2.012.833-.723 1.803-1.38 2.91-1.973a7.424 7.424 0 0 1 3.555-.889c1.263 0 2.448.297 3.555.889 1.106.593 2.073 1.25 2.9 1.973.827.723 1.491 1.394 1.992 2.012.501.619.771.961.811 1.025a.573.573 0 0 1 .117.352.656.656 0 0 1-.117.371c-.039.053-.31.391-.811 1.016-.501.625-1.169 1.296-2.002 2.012-.833.717-1.804 1.371-2.91 1.963a7.375 7.375 0 0 1-3.535.889 7.415 7.415 0 0 1-3.555-.889zm.869-9.746c-.853.41-1.631.889-2.334 1.436s-1.312 1.101-1.826 1.66c-.515.561-.889.99-1.123 1.289.234.3.608.729 1.123 1.289.514.561 1.123 1.113 1.826 1.66s1.484 1.025 2.344 1.436 1.751.615 2.676.615c.924 0 1.813-.205 2.666-.615.853-.41 1.634-.889 2.344-1.436.709-.547 1.318-1.1 1.826-1.66.508-.56.885-.989 1.133-1.289a41.634 41.634 0 0 0-1.133-1.289c-.508-.56-1.113-1.113-1.816-1.66s-1.484-1.025-2.344-1.436-1.751-.615-2.676-.615c-.937 0-1.833.205-2.686.615zm.04 7.031c-.736-.735-1.104-1.617-1.104-2.646 0-1.028.368-1.91 1.104-2.646.735-.735 1.618-1.104 2.646-1.104 1.028 0 1.911.368 2.646 1.104.735.736 1.104 1.618 1.104 2.646 0 1.029-.368 1.911-1.104 2.646-.736.736-1.618 1.104-2.646 1.104-1.029 0-1.911-.367-2.646-1.104zm.878-4.414a2.41 2.41 0 0 0-.732 1.768c0 .69.244 1.279.732 1.768s1.077.732 1.768.732c.69 0 1.279-.244 1.768-.732s.732-1.077.732-1.768c0-.689-.244-1.279-.732-1.768s-1.078-.732-1.768-.732-1.279.244-1.768.732z" />
                </svg>
                {/* </i> */}
              </Link>
            </div>
          )}
        </div>

        <div
          className="product-image__overlay-bottom position-absolute
                            d-flex justify-content-center justify-content-lg-start
                            align-items-end bottom-0 left-0 w-100 px-10 pb-10"
        >
          <Link to={link} className="absolute-stretch cursor-default" />
          {/* <div className="product-image__overlay-bottom-left product-collection__countdown position-relative mt-10"> */}
          {/*  <div className="d-none-important-" data-js-product-countdown> */}
          {/*    <div */}
          {/*      className="countdown countdown--type-01 d-flex flex-wrap justify-content-center js-countdown" */}
          {/*      data-date="2019-03-31 17:10:09" */}
          {/*    > */}
          {/*      2019-03-31 17:10:09 */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </div> */}
          {imagesCount > 1 && (
            <div
              className="product-image__overlay-bottom-right product-collection__images-navigation
                                position-relative d-none d-lg-block mt-10 ml-auto"
            >
              <div className="product-images-navigation d-flex">
                {/* {imageIndex > 0 && ( */}
                <span
                  className="d-flex flex-center mr-3 rounded-circle cursor-pointer"
                  data-js-product-images-navigation="prev"
                  onClick={prevImage}
                >
                  <SvgContaineredImage
                    id="iconTheme006-leftArrow"
                    isWrapped
                    wrapperClass="mr-2"
                    className="icon icon-theme-006"
                  />
                </span>
                {/* {imageIndex < imagesCount - 1 && ( */}
                <span
                  className="d-flex flex-center rounded-circle cursor-pointer"
                  data-js-product-images-navigation="next"
                  onClick={nextImage}
                >
                  <SvgContaineredImage
                    id="iconTheme007-rightArrow"
                    isWrapped
                    wrapperClass="ml-3"
                    className="icon icon-theme-007"
                  />
                </span>
                {/* )} */}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

ProductImageNavigationBlock.propTypes = {};

export default observer(ProductImageNavigationBlock);
