import { runInAction } from "mobx";
import layoutStorage from "../../storages/layoutStorage";
import productsListStorage from "../../storages/productsListStorage";
import currentUserStorage from "../../storages/currentUserStorage";
import parallerProcessor from "../../core/api/parallerProcessor";
import dictionariesApiProcessor from "../../core/api/dictionariesApiProcessor";
import categoriesApiProcessor from "../../core/api/categoriesApiProcessor";
import profileApiProcessor from "../../core/api/profileApiProcessor";
import shoppingCartApiProcessor from "../../core/api/shoppingCartApiProcessor";
import newAndSalesApiProcessor from "../../core/api/newAndSalesApiProcessor";

export default function initialLoad(resolve) {
  // console.time("START INITIAL LOAD");
  if (window.garphild && window.garphild.statusCode == 404) {
    layoutStorage.set404();
  }
  const loads = [
    categoriesApiProcessor,
    dictionariesApiProcessor,
    profileApiProcessor,
    shoppingCartApiProcessor,
    newAndSalesApiProcessor,
  ];
  parallerProcessor(loads);
  //   .then((result) => {
  //   // console.time("INITIAL LOAD PROCESS");
  runInAction(() => {
    layoutStorage.isDataLoaded = true;
    currentUserStorage.isLoaded = true;
    productsListStorage.loading = false;
    //     // console.log("INITIAL LOAD SUCCESS...PROCESS");
    if (resolve) resolve(true);
    //     // console.timeEnd("START INITIAL LOAD");
    //   });
    //   // console.timeEnd("INITIAL LOAD PROCESS");
  });
}
