import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import i18next from "i18next";
import classNames from "classnames";
import Money from "../../UI/atoms/blocks/Money";
import shoppingCartStorage from "../../storages/shoppingCartStorage";
import currentUserStorage from "../../storages/currentUserStorage";
import orderStorage from "../../storages/orderStorage";
import currentOrderStorage from "../../storages/orderStorage";
import IconTheme127 from "../../assets/SvgIcons/Themed/IconTheme127";

function FreeShippingDiff({ t, maxCost }) {
  const cn = classNames({
    "popup-cart__free-shipping my-20": true,
    "popup-cart__free-shipping_red":
      shoppingCartStorage.cost <
      orderStorage.minTotalForFreeShipping *
        100 *
        (currentOrderStorage.deliveryType == 3 ? 2 : 1),
  });
  return (
    <div className={cn}>
      <div className="free-shipping position-relative px-6 py-3 text-lg-left js-free-shipping">
        <div
          className="free-shipping__progress position-absolute top-0 left-0 h-100"
          style={{ width: "100.0%" }}
        />
        <div className="free-shipping__text position-relative">
          <i className="mr-3">
            <IconTheme127 />
          </i>{" "}
          <span data-js-text>
            {(
              !maxCost
                ? shoppingCartStorage.freeShippingDiffCalc <= 0
                : maxCost - shoppingCartStorage.cost <= 0
            ) ? (
              <span>{t("Free Shipping")}</span>
            ) : (
              <>
                <span>{t("Before free shipping")}</span>
                <Money
                  className="data-js-text free-shipping-summ"
                  amount={
                    maxCost
                      ? maxCost - shoppingCartStorage.cost
                      : shoppingCartStorage.freeShippingDiffCalc
                  }
                  currency={t(currentUserStorage.currency)}
                  cents={0}
                />
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

FreeShippingDiff.propTypes = {};

export default withTranslation()(observer(FreeShippingDiff));
