import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ViewButtonIcon from "../../../../assets/SvgIcons/ViewButtonIcon";
import layoutStorage from "../../../../storages/layoutStorage";
import PopperItem from "../../../../components/PopperItem";
import wishlistStorage from "../../../../storages/wishlistStorage";
import SvgContaineredImage from "../../../../UI/atoms/images/SvgContaineredImage";
import productsListStorage from "../../../../storages/productsListStorage";

class ProductCardActionsIcon extends Component {
  render() {
    const { refNum, link, t } = this.props;
    const { toggleLikeStatus, wishes } = wishlistStorage;
    return (
      <div className="product-collection__buttons-section d-flex px-lg-10 product-collection__button-add-to-wishlist mb-10">
        {/* <div className="product-collection__button-add-to-wishlist mb-10"> */}
        {layoutStorage.allowLikes && (
          <PopperItem
            className="btn btn--text btn--status px-lg-6 js-store-lists-add-wishlist ml-6"
            popperText={t("Wishlist")}
            onClick={() => toggleLikeStatus(refNum)}
          >
            {/* <i className="mb-1 ml-1"> */}
            {/* <LikeButtonIcon refNum={refNum} /> */}
            {/* </i> */}
            {/* <i className="mb-1 ml-1" data-button-content="added"> */}
            {!wishes[refNum] && <SvgContaineredImage id="iconTheme180" />}
            {/* {!wishes[refNum] && <IconTheme180 />} */}
            {!!wishes[refNum] && <SvgContaineredImage id="iconTheme181" />}
            {/* {!!wishes[refNum] && <IconTheme181 />} */}
            {/* </i> */}
          </PopperItem>
        )}
        {/* </div> */}
        {layoutStorage.allowCompare && (
          <div
            className="product-collection__buttons-section d-flex px-lg-10 product-collection__button-add-to-wishlist"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              productsListStorage.addProductIdToCompareList(refNum);
            }}
          >
            <a
              href="account"
              className="btn btn--text btn--status px-lg-6 js-store-lists-add-compare"
              data-js-tooltip
              data-tippy-content="Compare"
              data-tippy-placement="top"
              data-tippy-distance="-3"
            >
              {!productsListStorage.compareProductsIdList.includes(refNum) && (
                <i className="">
                  <SvgContaineredImage
                    id="iconTheme039"
                    isWrapped
                    wrapperClass="mb-1 ml-1"
                  />
                </i>
              )}
              {productsListStorage.compareProductsCount > 0 &&
                productsListStorage.compareProductsIdList.includes(refNum) && (
                  <i>
                    <SvgContaineredImage
                      id="iconTheme235"
                      isWrapped
                      wrapperClass="mb-1 ml-1"
                    />
                  </i>
                )}
            </a>
          </div>
        )}
        {layoutStorage.allowQuickView && (
          <div className="product-collection__button-quick-view-mobile mb-10">
            <Link
              to={link}
              className="btn btn--text pt-2 px-lg-6 js-popup-button"
            >
              <i>
                <ViewButtonIcon />
              </i>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

ProductCardActionsIcon.propTypes = {};

export default withTranslation()(observer(ProductCardActionsIcon));
