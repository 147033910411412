import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconTheme191 from "../../../assets/SvgIcons/Themed/IconTheme191";

/**
 * +
 * @param mobileOnly
 * @param className
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
function ButtonBurger({ mobileOnly, className, onClick }) {
  const baseClass = classNames({
    "d-flex align-items-center cursor-pointer": true,
    "d-md-none": mobileOnly,
    [className]: !!className,
  });
  return (
    <span className={baseClass} onClick={onClick}>
      <i>
        <IconTheme191 />
      </i>
    </span>
  );
}

ButtonBurger.defaultProps = {
  mobileOnly: true,
  className: null,
};

ButtonBurger.propTypes = {
  mobileOnly: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ButtonBurger;
