import React from 'react';

function IconTheme127() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      className="icon icon-theme-127"
      viewBox="0 0 24 24"
    >
      <path d="M21.648 12.672c.104.052.188.13.254.234a.62.62 0 0 1 .098.332v5a.602.602 0 0 1-.186.439.601.601 0 0 1-.439.186h-2.559a3.043 3.043 0 0 1-1.074 1.787 3.03 3.03 0 0 1-1.992.713c-.756 0-1.42-.238-1.992-.713a3.028 3.028 0 0 1-1.074-1.787h-1.309a.597.597 0 0 1-.439-.186.6.6 0 0 1-.186-.439V4.488H2.625a.597.597 0 0 1-.439-.186A.597.597 0 0 1 2 3.863a.6.6 0 0 1 .186-.439.597.597 0 0 1 .439-.186h8.75a.6.6 0 0 1 .439.186c.123.124.186.27.186.439v2.5h5a.614.614 0 0 1 .586.41l1.797 4.766 2.265 1.133zM9.314 9.674c.123.124.186.27.186.439a.602.602 0 0 1-.186.439.601.601 0 0 1-.439.186h-3.75a.597.597 0 0 1-.439-.186.597.597 0 0 1-.186-.439.6.6 0 0 1 .186-.439.598.598 0 0 1 .439-.186h3.75a.6.6 0 0 1 .439.186zm0 3.125c.123.124.186.27.186.439a.602.602 0 0 1-.186.439.601.601 0 0 1-.439.186h-2.5a.597.597 0 0 1-.439-.186.6.6 0 0 1-.186-.439.6.6 0 0 1 .186-.439.598.598 0 0 1 .439-.186h2.5a.6.6 0 0 1 .439.186zm11.436 4.814v-3.984l-2.148-1.074a.635.635 0 0 1-.195-.146.557.557 0 0 1-.117-.205l-1.719-4.59H12v10h.684a3.033 3.033 0 0 1 1.074-1.787 3.026 3.026 0 0 1 1.992-.713 3.03 3.03 0 0 1 1.992.713 3.041 3.041 0 0 1 1.074 1.787h1.934zm-3.936-6.064c.123.124.186.27.186.439a.602.602 0 0 1-.186.439.601.601 0 0 1-.439.186h-2.5a.597.597 0 0 1-.439-.186.6.6 0 0 1-.186-.439v-2.5a.6.6 0 0 1 .186-.439.598.598 0 0 1 .439-.186.6.6 0 0 1 .439.186c.123.124.186.27.186.439v1.875h1.875a.6.6 0 0 1 .439.186zm.264 8.017a1.81 1.81 0 0 0 .547-1.328 1.81 1.81 0 0 0-.547-1.328 1.812 1.812 0 0 0-1.328-.547 1.81 1.81 0 0 0-1.328.547 1.808 1.808 0 0 0-.547 1.328c0 .521.182.964.547 1.328.364.365.807.547 1.328.547s.963-.182 1.328-.547z" />
    </svg>
  );
}

IconTheme127.propTypes = {};

export default IconTheme127;
