import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TrashIcon from "../../../../assets/SvgIcons/Themed/TrashIcon";
import CloseIcon from "../../../../assets/SvgIcons/CloseIcon";

class CompareHeader extends Component {
  render() {
    const { t, onClose, disableRemoveAll = false, onClick } = this.props;
    return (
      <>
        <div className="popup-compare-full__head d-flex align-items-center">
          <i className="popup-compare-full__close ml-auto cursor-pointer">
            <CloseIcon onClick={onClose} />
          </i>
        </div>
        <div className="compare__head d-flex justify-content-lg-center align-items-center position-relative px-15 mb-15 mb-lg-30">
          <h1 className="h3 mt-20 mb-0 text-center">{t("Compare")}</h1>
          {!disableRemoveAll && (
            <div
              className="compare__button-remove position-absolute d-inline-flex align-items-center cursor-pointer right-0 mr-5 js-store-lists-clear-compare"
              onClick={onClick}
            >
              <i className="mb-4 mr-4">
                <TrashIcon />
              </i>
              {t("REMOVE ALL")}
            </div>
          )}
        </div>
      </>
    );
  }
}

CompareHeader.propTypes = {};

export default withTranslation()(CompareHeader);
