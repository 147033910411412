import React from 'react';
import PropTypes from "prop-types";

function ArrowLeftIcon() {
  return (
    <svg
      aria-hidden="true"
      focusable="false" role="presentation"
      className="icon icon-theme-012"
      viewBox="0 0 24 24"
    >
      <path
        d="M21.036 12.569a.601.601 0 0 1-.439.186H4.601l4.57 4.551c.117.13.176.28.176.449a.652.652 0 0 1-.176.449.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .877.877 0 0 1-.215-.127l-5.625-5.625a2.48 2.48 0 0 1-.068-.107c-.02-.032-.042-.068-.068-.107a.736.736 0 0 1 0-.468 2.48 2.48 0 0 0 .068-.107c.02-.032.042-.068.068-.107l5.625-5.625a.652.652 0 0 1 .449-.176c.169 0 .319.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-4.57 4.551h15.996a.6.6 0 0 1 .439.186.601.601 0 0 1 .186.439.599.599 0 0 1-.186.437z"/>
    </svg>
  );
}

ArrowLeftIcon.propTypes = {
};

export default ArrowLeftIcon;
