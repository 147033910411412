import React from "react";
import IconTheme164 from "../../../assets/SvgIcons/Themed/IconTheme164";
import IconTheme154 from "../../../assets/SvgIcons/Themed/IconTheme154";

function NotificationComponent({
  link,
  image,
  tag,
  title,
  text,
  subtitle,
  altText,
  onClose,
}) {
  return (
    <div data-js-notification-products-item>
      <div className="product-notification d-flex position-relative">
        <div
          className="product-notification__button-close position-absolute top-0 right-0 cursor-pointer"
          data-js-action="close"
          onClick={onClose}
        >
          <i>
            <IconTheme164 />
          </i>
        </div>
        <div className="product-notification__image d-flex mr-20 mr-sm-10">
          <a
            href={link}
            className="d-block w-100"
            target="_blank"
            rel="noreferrer"
          >
            <div className="rimage" style={{ paddingTop: "128.2307692%" }}>
              <img
                src={image}
                className="rimage__img rimage__img--cover rimage__img--fade-in lazyload"
                alt={altText}
              />
            </div>
          </a>
        </div>
        <div className="product-notification__content d-flex flex-column justify-content-center align-items-start pr-20">
          <span>{title}</span>
          <span>{subtitle}</span>
          <div className="product-notification__title mb-3">
            <h3 className="h5 m-0">
              <a href={link} target="_blank" rel="noreferrer">
                {text}
              </a>
            </h3>
          </div>
          <span className="font-italic text-danger">{tag}</span>
        </div>
        <div className="product-notification__button-quick-view position-absolute bottom-0 right-0">
          <a
            href={link}
            className="button-quick-view d-flex flex-center rounded-circle js-popup-button"
            data-js-popup-button="quick-view"
            data-js-tooltip
            data-tippy-content="Quick View"
            data-tippy-placement="left"
            data-tippy-distance="5"
          >
            <i>
              <IconTheme154 />
            </i>
          </a>
        </div>
      </div>
    </div>
  );
}

NotificationComponent.propTypes = {};

export default NotificationComponent;
