import React from "react";
import { withTranslation } from "react-i18next";
import layoutStorage from "../../storages/layoutStorage";
import Indicator from "../../layouts/main/HeaderParts/Indicator";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

/**
 * +
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
function ProfileIndicator({ t }) {
  return (
    <Indicator
      openPopup={layoutStorage.openPopup}
      popperText={t("Account")}
      popperPlacement="bottom"
      className="header__btn-account js-popup-button"
      href="/account"
      icon={<SvgContaineredImage id="accountIndicator" />}
      name="account"
    />
);
}

ProfileIndicator.propTypes = {};

export default withTranslation()(ProfileIndicator);
