import React from "react";
import Modal from "./Modal";

function BottomDialog({ children, className }) {
  return (
    <Modal>
      <div className={`admin__bottom-dialog ${className}`}>{children}</div>
    </Modal>
  );
}

BottomDialog.propTypes = {};

export default BottomDialog;
