import { makeAutoObservable } from "mobx";
import notifications from "../mocks/messages.json";
import NotificationModel from "../models/NotificationModel";

class NotificationsStorage {
  notificationsModel = new NotificationModel();

  notifications = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadNotifications = () => {
    // this.notifications = notifications.notifications;
  };
}

const notificationsStorage = new NotificationsStorage();

export default notificationsStorage;
