import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TransparentBackground from "../../../UI/atoms/TransparentBackground";
import searchStorage from "../../../storages/searchStorage";
import SvgContaineredImage from "../../../UI/atoms/images/SvgContaineredImage";
import layoutStorage from "../../../storages/layoutStorage";
import Money from "../../../UI/atoms/blocks/Money";

function SearchPopUp({ t }) {
  if (!layoutStorage.popups.search) return null;
  return (
    <>
      <TransparentBackground
        onClick={() => layoutStorage.closePopup("search")}
      />
      <div
        className="popup-navigation__search search pt-lg-25 pb-lg-35 px-10 px-lg-0 js-popup-search-ajax"
        data-js-max-products="6"
      >
        <div className="container">
          <div className="d-none d-lg-flex align-items-lg-center mb-5 mb-lg-10">
            <p className="m-0">{t("WHAT ARE YOU LOOKING FOR")}</p>
            <i
              className="search__close ml-auto cursor-pointer"
              onClick={() => layoutStorage.closePopup("search")}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-theme-164"
                viewBox="0 0 24 24"
              >
                <path d="M19.583 4.965a.65.65 0 0 1-.176.449l-6.445 6.426 6.445 6.426c.117.131.176.28.176.449a.65.65 0 0 1-.176.449.846.846 0 0 1-.215.127.596.596 0 0 1-.468 0 .846.846 0 0 1-.215-.127l-6.426-6.445-6.426 6.445a.846.846 0 0 1-.215.127.596.596 0 0 1-.468 0 .846.846 0 0 1-.215-.127.65.65 0 0 1-.176-.449c0-.169.059-.318.176-.449l6.445-6.426-6.445-6.426a.65.65 0 0 1-.176-.449c0-.169.059-.318.176-.449a.652.652 0 0 1 .449-.176c.169 0 .319.059.449.176l6.426 6.445 6.426-6.445a.652.652 0 0 1 .449-.176c.169 0 .319.059.449.176.117.13.176.28.176.449z" />
              </svg>
            </i>
          </div>
          <div
            className="position-relative d-flex align-items-center pb-5 pb-lg-15 mb-0 border-bottom"
            role="search"
          >
            <input
              type="search"
              className="border-0 p-0 mb-0"
              name="q"
              id="Search"
              value={searchStorage.serchString}
              onInput={searchStorage.searchTyping}
              placeholder={t("Search Products...")}
              onKeyUp={(e) => {
                if (
                  e.code === "Enter" ||
                  e.code === "NumpadEnter" ||
                  e.keyCode === 13
                ) {
                  searchStorage.startSearch();
                }
                if (e.target.value.length >= 4) {
                  searchStorage.startSearch();
                }
              }}
            />
            <label
              className="position-absolute right-0 mb-0 mr-0 m-lg-0 cursor-pointer"
              htmlFor="Search"
              onClick={searchStorage.startSearch}
            >
              <SvgContaineredImage id="searchIcon" isWrapped />
            </label>
          </div>
          {searchStorage.searchedProductsList.length > 0 && (
            <div className="search__content">
              <div className="search__result row pt-10 pt-lg-0 mt-20 mt-lg-30">
                {searchStorage.searchedProductsList
                  .slice(0, 6)
                  .map((refNum) => {
                    const product = searchStorage.searchedProducts[refNum];
                    return (
                      <div className="col-12 col-lg-2">
                        <div className="product-search d-flex flex-row flex-lg-column align-items-start align-items-lg-stretch mb-10">
                          <div className="product-search__image position-relative mr-10 mr-lg-0">
                            <Link
                              to={product.relativeLink}
                              className="d-block"
                              onClick={() => {
                                searchStorage.closeForm();
                                layoutStorage.closePopup("search");
                              }}
                            >
                              <img src={product.mainImage.smallImageUrl} />
                            </Link>
                          </div>
                          <div className="product-search__content d-flex flex-column align-items-start mt-lg-15">
                            <div className="product-search__title mb-3">
                              <h3 className="h6 m-0">
                                <Link
                                  to={product.relativeLink}
                                  // className="d-block"
                                  onClick={() => {
                                    searchStorage.closeForm();
                                    layoutStorage.closePopup("search");
                                  }}
                                >
                                  {product.productTitle}
                                </Link>
                              </h3>
                            </div>
                            <div className="product-search__price mb-10">
                              <Money
                                amount={product.price}
                                currency={t(layoutStorage.currentCurrency)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* <div className="search__view-all pb-20 pb-lg-0 mt-20 mt-lg-10"> */}
              {/*  <a href="/search?q=pplp" className="btn-link"> */}
              {/*    View all products */}
              {/*  </a> */}
              {/* </div> */}
            </div>
          )}
          <p className="search__empty pb-20 pb-lg-0 mt-20 mt-lg-30 mb-0 d-none-important">
            Empty result
          </p>
        </div>
      </div>
    </>
  );
}

SearchPopUp.propTypes = {};

export default withTranslation()(observer(SearchPopUp));
