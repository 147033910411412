import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class ProductCardDetails extends Component {
  render() {
    const { t } = this.props;
    return null;
    return (
      <div className="product-collection__details mb-8">
        <div className="product-collection__sku mb-5">
          <p className="m-0" data-js-product-sku>
            {t("SKU")}: <span>00116с-23</span>
          </p>
        </div>
        <div className="product-collection__barcode d-none-important mb-5">
          <p className="m-0" data-js-product-barcode>
            {t("BARCODE")}: <span />
          </p>
        </div>
        <div className="product-collection__availability mb-5">
          <p
            className="m-0"
            data-js-product-availability
            data-availability="true"
          >
            {t("AVAILABILITY")}:{" "}
            <span>
              {t("In stock")} ({1}) {t("item")}
            </span>
          </p>
        </div>
        <div className="product-collection__type mb-5">
          <p className="m-0" data-js-product-type>
            {t("PRODUCT TYPE:")} <span>{t("Polo")}</span>
          </p>
        </div>
        <div className="product-collection__vendor mb-5">
          <p className="m-0" data-js-product-vendor>
            {t("VENDOR:")} <span>{t("Lacoste")}</span>
          </p>
        </div>
      </div>
    );
  }
}

ProductCardDetails.propTypes = {};

export default withTranslation()(ProductCardDetails);
