import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import layoutStorage from "../../../storages/layoutStorage";
import ParagraphCentered from "../../../UI/atoms/typographics/ParagraphCentered";
import ButtonCloseSmall from "../../../UI/atoms/buttons/ButtonCloseSmall";

function HeaderTape() {
  const containerClass = classNames({
    "d-none": !layoutStorage.showTape,
    "js-header-tape": true,
  });
  return (
    <div className={containerClass}>
      <div className="header__tape d-flex py-5">
        <div className="container d-flex flex-center">
          <div className="position-relative d-flex align-items-start align-items-lg-center px-35">
            {layoutStorage.popupsData.headerTape.link ? (
              <a href={layoutStorage.popupsData.headerTape.link}>
                <ParagraphCentered
                  text={layoutStorage.popupsData.headerTape.title}
                  className="m-0 px-30 px-lg-0"
                />
              </a>
            ) : (
              <ParagraphCentered
                text={layoutStorage.popupsData.headerTape.title}
                className="m-0 px-30 px-lg-0"
              />
            )}
            <ButtonCloseSmall
              className="header__tape-close position-absolute right-0"
              onClick={layoutStorage.closeHeaderTape}
            />
          </div>
        </div>
      </div>
      <div className="header__border border-bottom d-none d-lg-block" />
    </div>
  );
}

HeaderTape.propTypes = {};

export default observer(HeaderTape);
