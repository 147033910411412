import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";
import Badge from "../../../../../UI/atoms/blocks/Badge";

class ProductCardBadgesBlock extends Component {
  render() {
    const { t, refNum, variant, product } = this.props;
    const { badges, price, oldPrice } = product;

    const className = classNames({
      "product-image__overlay-top-left product-collection__labels": true,
      "position-relative d-flex align-items-start mb-10": true,
      "flex-column": variant === "column",
      "flex-row": variant === "row",
    });
    return (
      <div className={className}>
        <Badge text={t("Hot")} variant="hot" visible={badges.hot} />
        <Badge text={t("New")} variant="new" visible={badges.newItem} />
        <Badge
          text={`${(((price - oldPrice) / oldPrice) * 100).toFixed(0)}%`}
          variant="sale"
          visible={price !== oldPrice}
        />
        {/* <Badge text={t("Out Stock")} variant="out-stock" visible={!inStock} /> */}
        {/* <Badge text={t("In stock")} variant="in-stock" visible={inStock} /> */}
      </div>
    );
  }
}

ProductCardBadgesBlock.propTypes = {};

export default withTranslation()(observer(ProductCardBadgesBlock));
