import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Subscribe from "./Subscribe";
import FooterSection from "./FooterSection";
import layoutStorage from "../../../storages/layoutStorage";

class MainFoter extends Component {
  render() {
    return (
      <div className="footer__content pt-lg-55 pb-lg-50">
        <div className="container">
          <div className="row">
            <div className="footer__border border-top w-100 mb-45 d-lg-none" />
            <div className="col-lg-12">
              {layoutStorage.allowSubscribe && <Subscribe />}
            </div>
            <div className="col-lg-6">
              <div className="footer__menus row flex-column flex-lg-row">
                <div className="footer__border border-top d-lg-none" />
                <div className="col-lg">
                  <FooterSection section={0} />
                </div>
                <div className="footer__border border-top d-lg-none" />
                <div className="col-lg">
                  <FooterSection section={1} />
                </div>
                <div className="footer__border border-top d-lg-none" />
                <div className="col-lg">
                  <FooterSection section={2} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer__border border-top row d-lg-none" />
              <div className="footer__custom-html">
                <FooterSection section={3} />
              </div>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <FooterSection
                section={4}
                sectionClass="social-media"
                iconClass="mr-3"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MainFoter.propTypes = {};

export default observer(MainFoter);
