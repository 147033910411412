import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Footer from "./Footer";
import processRoute from "../../helpers/processRoute";
import layoutStorage from "../../storages/layoutStorage";
import Core from "../../core/Core";

/**
 * +
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Layout(props) {
  const { children, location, history } = props;
  useEffect(() => {
    const structure = Core.routing.parseRoute(location);
    history.listen((location, action) => {
      // location is an object like window.location
      console.log("HISTORY", action, location.pathname, location.state);
      if (!window.garphild) window.garphild = { statusCode: 200 };
      window.garphild.statusCode = 200;
      layoutStorage.set404(false);
      switch (action) {
        case "PUSH":
          // console.log("HISTORY NEW URL", location.pathname);
          processRoute();
      }
    });
    if (structure.language) {
      layoutStorage.changeLanguage(structure.language);
    }
  }, []);
  return (
    <>
      <Header />
      {children}
      <Footer />
      <ToastContainer />
    </>
  );
}

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default withRouter(Layout);
