import notificationsStorage from "../storages/NotificationsStorage";

class NotificationModel {
  id = 0;

  title = "";

  text = "";

  subtitle = "";

  tag = "";

  image = "";

  link = "";

  ttl = null;

  altText = "";

  closeNotification = (notification) => {
    if (!notification.id) {
      return;
    }
    notificationsStorage.notifications.splice(
        notificationsStorage.notifications.indexOf(notification),
      1
    );
    let storedNotificationsList = JSON.parse(
      localStorage.getItem("notifications")
    );
    if (!storedNotificationsList) {
      storedNotificationsList = [];
    }
    storedNotificationsList.push({
      id: notification.id,
      timeoutId: this.setNotificationClose(notification.id),
    });
    localStorage.setItem(
      "notifications",
      JSON.stringify(storedNotificationsList)
    );
    clearTimeout(this.setNotificationClose(notification));
  };

  closeAllNotificationsByTime = () => {
    notificationsStorage.notifications.forEach((item) => {
      this.setNotificationClose(item);
    });
  };

  setNotificationClose = (notification) =>
    setTimeout(() => {
      this.closeNotification(notification);
    }, 20000);
}

export default NotificationModel;
