import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TrashIcon from "../../../../assets/SvgIcons/Themed/TrashIcon";
import LinkButton from "../../../../UI/atoms/buttons/LinkButton/LinkButton";
import LinkedImage from "../../../../UI/atoms/images/LinkedImage";
import CompareFullPopupHeader from "./CompareHeader";
import CompareProductCard from "./CompareProductCard";
import productsListStorage from "../../../../storages/productsListStorage";
import ProductCardItem from "../../../../containers/ProductCard/ProductCard";
import layoutStorage from "../../../../storages/layoutStorage";
import ProductCard from "../../../../containers/ProductCard/ProductCard";
import CompareProductCardList from "./CompareProductCardList";
import CompareContentTable from "./CompareContentTable";

class CompareContent extends Component {
  render() {
    const { t, productsForCompare } = this.props;
    return (
      <div className="compare">
        <CompareContentTable productsForCompare={productsForCompare}/>
      </div>
    );
  }
}

CompareContent.propTypes = {};

export default withTranslation()(CompareContent);
