import React, { Component } from "react";
import PropTypes from "prop-types";

class Subscribe extends Component {
  render() {
    return (
      <div className="footer__subscription row align-items-center mb-25">
        <div className="col-lg-6 mb-10 mb-lg-0">
          <div className="h5 mb-10 text-uppercase">Newsletter subscription</div>
          <p className="mb-10">
            Sign up for Shella updates to receive information about new
            arrivals, future events and specials.
          </p>
        </div>
        <div className="col-lg-6">
          <form
            action="/subscribe/post?u=8a50b61e56896303a04d0243e&amp;id=3c3d06b907"
            method="post"
            className="d-flex flex-column flex-lg-row mb-10"
            target="_blank"
          >
            <input
              type="email"
              name="EMAIL"
              id="FooterSubscribe"
              className="mb-10 mb-lg-0 mr-lg-10"
              placeholder="Enter Your Email Address"
              required="required"
            />
            <input type="submit" className="btn px-lg-20" value="SUBSCRIBE!" />
          </form>
        </div>
      </div>
    );
  }
}

Subscribe.propTypes = {};

export default Subscribe;
