import filtersStorage from "../storages/filtersStorage";

export default function getFilterObject(section, slug) {
  const dictGroup = filtersStorage.filters.find((f) => f.field === section);
  if (dictGroup) {
    const dictField = dictGroup.items.find(
      (f) => f.name === slug || f.realName === slug
    );
    if (dictField) {
      return dictField;
    }
  }
  return null;
}
