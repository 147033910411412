import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import "./Badge.scss";

function Badge({ variant, text, visible }) {
  if (!visible) return null;
  const badgeClass = classNames({
    "label mb-3 mr-3 text-nowrap badge ": true,
    [`label--${variant}`]: !!variant,
  });
  return <div className={badgeClass}>{text}</div>;
}

Badge.defaultProps = {
  visible: true,
  variant: "basic",
};
Badge.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

export default Badge;
