import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import menuStorage from "../../storages/menuStorage";
import LinkMenuItem from "../../containers/MainMenuContainer/MegaMenu/LinkMenuItem";
import ArrowLeftIcon from "../../assets/SvgIcons/ArrowLeftIcon";

function MobileMenu({ t }) {
  console.log(
    "MENU DATA PATH RENDER",
    menuStorage.currentMenu,
    menuStorage.currentMenu?.[1]?.children?.length
  );
  return (
    <div className="container" data-js-position-mobile="menu">
      <div
        className="menu__panel d-flex flex-column flex-lg-row flex-lg-wrap menu__panel--bordered"
        style={{ fontSize: "15px" }}
      >
        <nav className="menu js-menu js-position" data-js-position-name="menu">
          {menuStorage.currentMenuPath.length > 0 && (
            <div
              onClick={menuStorage.goMenuBack}
              className="d-flex flex-row align-items-center mb-10"
            >
              <div className="mr-4">
                <ArrowLeftIcon />
              </div>
              <span className="text-uppercase text-dark">{t("Back")}</span>
            </div>
          )}
          {menuStorage.currentMenu
            // .filter((v) => v.enabled === true)
            .map((rootMenuItem, index) => (
              <div
                key={`${rootMenuItem.name || rootMenuItem.title}-${index}-${
                  menuStorage.currentMenuPath.length
                }`}
                className={`mb-4 menu__level-${
                  menuStorage.currentMenuPath.length / 2
                }`}
              >
                {/* <div>{JSON.stringify(rootMenuItem)}</div> */}
                <LinkMenuItem
                  menuItem={rootMenuItem}
                  haveSubitems={
                    rootMenuItem.children?.length > 0 ||
                    rootMenuItem.subitems?.length > 0 ||
                    (menuStorage.currentMenuPath.length === 0 && index === 1)
                  }
                  menuIndex={index}
                />
              </div>
            ))}
          {/* {menuStorage.menu.map((rootMenuItem) => { */}
          {/*  if (rootMenuItem.enabled === false) return null; */}
          {/*  switch (rootMenuItem.type) { */}
          {/*    case "link": */}
          {/*      return ( */}
          {/*        <RootMenuItem */}
          {/*          key={rootMenuItem.title} */}
          {/*          opened={true} */}
          {/*          menuItem={rootMenuItem} */}
          {/*          variant='vertical' */}
          {/*        /> */}
          {/*      ); */}
          {/*    case "catalog": */}
          {/*      return ( */}
          {/*          <div key={rootMenuItem.title}> */}
          {/*            */}
          {/*          </div> */}
          {/*        <MegaMenuItem */}
          {/*          key={rootMenuItem.title} */}
          {/*          title={rootMenuItem.title} */}
          {/*          menuTree={layoutStorage.categories} */}
          {/*          variant="vertical" */}
          {/*        /> */}
          {/*      ); */}
          {/*  } */}
          {/* }) */}
          {/* } */}
        </nav>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {};

export default withTranslation()(observer(MobileMenu));
