import React, { useState } from "react";
import TabsPanel from "../molecules/TabsPanel";
import TabContent from "../atoms/TabContent";
import IconTheme007_RightArrow from "../../assets/SvgIcons/Themed/IconTheme007_RightArrow";
import IconTheme006_LeftArrow from "../../assets/SvgIcons/Themed/IconTheme006_LeftArrow";
import "./TabularPanel.scss";

function TabularPanel({
  tabs,
  tabsContent,
  arrows = false,
  dataType = "horizontal",
  variant = "horizontal",
  className = null,
  tabsClassName = null,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <div
      className={`tabs js-tabs tabs__${variant} ${className} mw-100 ml-0 mr-0`}
      data-type={dataType}
    >
      <div className={`tabs__head ${tabsClassName}`} data-js-tabs-head>
        <TabsPanel
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        {arrows && (
          <>
            <div
              className="tabs__nav tabs__nav--prev d-lg-block position-absolute left-0 top-0"
              onClick={() =>
                setCurrentTab(currentTab > 0 ? currentTab - 1 : tabs.length - 1)
              }
            >
              <i>
                <IconTheme006_LeftArrow />
              </i>
            </div>
            <div
              className="tabs__nav tabs__nav--next d-lg-block  position-absolute right-0 top-0"
              onClick={() =>
                setCurrentTab(currentTab < tabs.length - 1 ? currentTab + 1 : 0)
              }
            >
              <i>
                <IconTheme007_RightArrow />
              </i>
            </div>
          </>
        )}
      </div>
      <div className="tabs__body">
        {tabs.map((tabName, index) => (
          <TabContent
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabIndex={index}
            tabName={tabName}
          >
            {tabsContent[index]}
          </TabContent>
        ))}
      </div>
    </div>
  );
}

TabularPanel.propTypes = {};

export default TabularPanel;
