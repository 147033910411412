import ProductCardItem from "../../containers/ProductCard/ProductCard";
import React from "react";
import get from 'lodash/get';

function compareProductsAdapter(listOfProductsForCompare = [], config = []) {
    const rows = config.map(item => ({title: item.title, data: []}))
    rows.unshift({title: "", data: []});
    console.log("WREST", listOfProductsForCompare, rows);
    listOfProductsForCompare?.[0].forEach(item => {
        if( !item) return null;
        rows[0].data.push(<ProductCardItem
            key={`pc-${941377}-1` || `pc-null-${941377}`}
            refNum={item.refNum}
            columns={1}
            disableColorsAndSizesBlock
            disableActionIcon
            isCompareCard
        />)
        config.forEach((configItem, index) => {
            rows[index + 1].data.push(get(item, configItem.path))
        })
    })
    return rows;
}

export default compareProductsAdapter;
