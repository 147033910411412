import React from 'react';
// import TwitterIcon from "./Socials/TwitterIcon";
// import GooglePlus from "./Socials/GooglePlus";
// import Pinterest from "./Socials/Pinterest";
// import BehanceIcon from "./Socials/BehanceIcon";
// import SkypeIcon from "./Socials/SkypeIcon";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

const icons = {
  197: <SvgContaineredImage id="iconTheme197" /> ,
  234: <SvgContaineredImage id="iconTheme234" />,
  153: <SvgContaineredImage id="iconTheme153" />,
  FacebookIcon: <SvgContaineredImage id="socialsFacebookIcon" width={14} height={16}/>,
  TwitterIcon: null,
  GooglePlusIcon: null,
  InstagramIcon: <SvgContaineredImage id="socialsInstagramIcon" width={14} height={16}/>,
  PinterestIcon: null,
  BehanceIcon: null,
  SkypeIcon: <SvgContaineredImage id="socialsSkypeIcon" width={14} height={16}/>,
  ViberIcon: <SvgContaineredImage id="socialsViberIcon" width={14} height={16}/>,
}

function Icons({ id }) {
  return icons[id] || null;
}

Icons.propTypes = {};

export default Icons;
