import React from "react";
import classNames from "classnames";
import Label from "../../atoms/inputs/Label";
import TextInput from "../../atoms/inputs/TextInput";
import ErrorBox from "../../atoms/blocks/ErrorBox";
import currentOrderStorage from "../../../storages/orderStorage";

function LabeledTextInput({
  label,
  id,
  name,
  onChange,
  value,
  placeholder,
  isRequired,
  autoComplete,
  labelAppend,
  type,
  isValid,
  textErrorLabel,
  isOptional,
  isAgreementLabel,
  onKeyPress,
}) {
  const cn = classNames({
    success: isValid,
    "text-danger": !isValid,
    "text-dark": isOptional,
  });
  const containerClass = classNames({
    "input-block": true,
    "d-flex": type == "radio" || type == "checkbox",
  });
  const labelClass = classNames({
    "text-danger": !isValid && isAgreementLabel,
  });
  return (
    <div className={containerClass}>
      {!labelAppend && (
        <Label className={cn} text={label} id={id} isRequired={isRequired} />
      )}
      <TextInput
        type={type}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        id={id}
        isValid={isValid}
        name={name}
        placeholder={placeholder}
        isRequired={isRequired}
        isOptional={isOptional}
        textErrorLabel={textErrorLabel}
        autoComplete={autoComplete}
      />
      {labelAppend && (
        <Label
          text={label}
          id={id}
          isRequired={isRequired}
          className={labelClass}
        />
      )}
    </div>
  );
}

LabeledTextInput.defaultProps = {
  labelAppend: false,
};
LabeledTextInput.propTypes = {};

export default LabeledTextInput;
