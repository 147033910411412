import urls from "../../storages/api/urls";
import productsListStorage from "../../storages/productsListStorage";
import shoppingCartStorage from "../../storages/shoppingCartStorage";

const shoppingCartApiProcessor = {
  url: `${urls.baseUrl}/api/cart/getData`,
  processor: (data) => {
    productsListStorage.setCartItems(data?.cartItems, data?.images);
    shoppingCartStorage.setCart(data?.cart);
  },
};

export default shoppingCartApiProcessor;
