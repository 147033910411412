import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import productsListStorage from "../../../../storages/productsListStorage";
import Image from "./Image";
import LinkedImage from "../../../../UI/atoms/images/LinkedImage";
import HoveredImage from "../../../../UI/atoms/images/HoveredImage";
import layoutStorage from "../../../../storages/layoutStorage";
import ProductModel from "../../../../storages/models/productModel";
import HoverZommedImage from "../../../../UI/atoms/images/HoverZommedImage";

const styles = {
  // rimage: {paddingTop:"128.0%"}
  // collections: {paddingTop:"72.98245614035088%", minHeight: "400px"}
  // cats: {paddingTop:"72.97297297297297%"}
};

class ProductCardImage extends Component {
  render() {
    const { refNum, imageIndex, link, title, alt, t, haveInStock } = this.props;
    if (
      !productsListStorage.products[refNum] ||
      !productsListStorage.products[refNum].images ||
      !productsListStorage.products[refNum].images[imageIndex]
    )
      return null;
    const { middleImageUrl, srcSet, altText } =
      productsListStorage.products[refNum].images[imageIndex];
    let nextImageIndex = imageIndex + 1;
    if (nextImageIndex >= productsListStorage.products[refNum].images.length) {
      nextImageIndex = 0;
    }
    const { middleImageUrl: secondImageUrl } =
      productsListStorage.products[refNum].images[nextImageIndex];
    return (
      <div className={haveInStock ? null : "grayscaled-image"}>
        {/* {altText} */}
        <HoveredImage
          link={link}
          mediaUrl={middleImageUrl}
          alt={imageIndex ? alt : `${alt} - ${t("Foto")} ${nextImageIndex}`}
          title={
            imageIndex ? title : `${title} - ${t("Foto")} ${nextImageIndex}`
          }
          srcSet={srcSet}
          secondMediaUrl={secondImageUrl}
          allowSpinner={layoutStorage.allowImagesSpinner}
        />
      </div>
    );
  }
}

ProductCardImage.propTypes = {};

export default withTranslation()(observer(ProductCardImage));
