import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

function Label({ id, className, isRequired, text }) {
  const labelClass = classNames({
    "label-required": isRequired,
    [className]: !!className,
  });
  return (
    <label htmlFor={id} className={labelClass}>
      {text}
    </label>
  );
}

Label.defaultProps = {
  className: null,
  isRequired: false,
};
Label.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default Label;
