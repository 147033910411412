import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Drawer.scss";

const positions = {
  left: "side-drawer_left",
  right: "side-drawer_right",
  center: "side-drawer_center",
};

function Drawer({
  opened,
  position,
  id,
  onClose,
  title,
  children,
  hideChildren,
  className,
  hideHeader = false,
}) {
  const [drawerOpened, setDrawerOpened] = useState(false);
  useEffect(() => {
    setDrawerOpened(opened);
  }, [opened]);
  let drawerClassName = ["side-drawer"];
  if (className) drawerClassName.push(className);
  drawerClassName = [
    ...drawerClassName,
    drawerOpened ? "side-drawer__opened" : "side-drawer__closed",
  ];
  if (positions[position])
    drawerClassName = [...drawerClassName, positions[position]];
  return (
    <>
      {opened && <div className="side-drawer__background" onClick={onClose} />}
      <aside className={drawerClassName.join(" ")} id={id}>
        {(opened || !hideChildren) && (
          <>
            {!hideHeader && (
              <div className="side-drawer__header">
                {title}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className="icon icon-theme-164"
                  viewBox="0 0 24 24"
                  onClick={onClose}
                >
                  <path d="M19.583 4.965a.65.65 0 0 1-.176.449l-6.445 6.426 6.445 6.426c.117.131.176.28.176.449a.65.65 0 0 1-.176.449.846.846 0 0 1-.215.127.596.596 0 0 1-.468 0 .846.846 0 0 1-.215-.127l-6.426-6.445-6.426 6.445a.846.846 0 0 1-.215.127.596.596 0 0 1-.468 0 .846.846 0 0 1-.215-.127.65.65 0 0 1-.176-.449c0-.169.059-.318.176-.449l6.445-6.426-6.445-6.426a.65.65 0 0 1-.176-.449c0-.169.059-.318.176-.449a.652.652 0 0 1 .449-.176c.169 0 .319.059.449.176l6.426 6.445 6.426-6.445a.652.652 0 0 1 .449-.176c.169 0 .319.059.449.176.117.13.176.28.176.449z" />
                </svg>
              </div>
            )}
            <div className="side-drawer__content">{children}</div>
          </>
        )}
      </aside>
    </>
  );
}

Drawer.defaultProps = {
  opened: false,
  position: "left",
  id: null,
  title: null,
  children: null,
  hideChildren: true,
};
Drawer.propTypes = {
  opened: PropTypes.bool,
  position: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  hideChildren: PropTypes.bool,
};

export default Drawer;
