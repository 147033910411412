import React from "react";
import PropTypes from "prop-types";
import BottomDialog from "../atoms/BottomDialog";
import CloseIcon from "../../assets/SvgIcons/CloseIcon";

function CloseableBottomDialog({ children, onClose, icons, className }) {
  return (
    <BottomDialog className={className}>
      <div
        className="position-fixed main-toolbar"
        style={{
          // bottom: "calc(40vh - 10px)",
          right: "15px",
          // zIndex: 1000001,
          backgroundColor: "white",
          boxShadow: "2px 2px 4px rgba(0,0,0,.3)",
          border: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {icons}
        <CloseIcon onClick={onClose} />
      </div>
      {children}
    </BottomDialog>
  );
}

CloseableBottomDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CloseableBottomDialog;
