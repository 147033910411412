import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CompareContentTableLeftCell extends Component {
    render() {
        const { title } = this.props;
        return (
            <td className="compare__title pl-0 border-right">
                <h4 className="h6 mb-0">{title}</h4>
            </td>
        );
    }
}

CompareContentTableLeftCell.propTypes = {};

export default CompareContentTableLeftCell;
