import Core from "../Core";
import cyrillicToTranslit from "../../helpers/cyrillicToTranslit";
import layoutStorage from "../../storages/layoutStorage";

function createProductRoute(
  baseUrl,
  slug,
  title,
  vendor,
  gender,
  refNum,
  parentRefNum
) {
  let routeUrl = baseUrl;
  let fullTitle = "";
  if (title) fullTitle = title.replace(/[,-\/]/g, "_").toLowerCase();
  if (vendor) fullTitle += ` ${vendor.replace(/[,-\/]/g, "_").toLowerCase()}`;
  if (refNum) fullTitle += ` ${refNum.replace(/[,-\/]/g, "_").toLowerCase()}`;
  if (gender) fullTitle += ` ${gender.replace(/[,-\/]/g, "_").toLowerCase()}`;
  const titleTranslit = cyrillicToTranslit({ preset: "uk" }).transform(
    fullTitle,
    "_"
  );
  if (slug !== undefined) {
    if (titleTranslit) {
      routeUrl = `${routeUrl}${Core.routing.routingSegments.SEGMENT_PRODUCT}/${titleTranslit}/${slug}`;
    } else {
      routeUrl = `${routeUrl}${Core.routing.routingSegments.SEGMENT_PRODUCT}/${slug}`;
    }
  }
  return routeUrl;
}

export default createProductRoute;
