import React from "react";
import PropTypes from "prop-types";
import LinkMenuItem from "./MegaMenu/LinkMenuItem";

/**
 * +
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RootMenuItem(props) {
  const { menuItem } = props;

  return <LinkMenuItem menuItem={menuItem} />;
}

RootMenuItem.defaultProps = {};
RootMenuItem.propTypes = {
  menuItem: PropTypes.any,
};

export default RootMenuItem;
