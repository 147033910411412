import React from "react";
import { observer } from "mobx-react";
import menuStorage from "../../../storages/menuStorage";
import RootMenuItem from "../../../containers/MainMenuContainer/RootMenuItem";
import MegaMenuItem from "./MegaMenuItem";
import layoutStorage from "../../../storages/layoutStorage";

function Menu({ variant, onMouse }) {
  const { isMenuOpened } = layoutStorage;
  return menuStorage.menu.map((rootMenuItem) => {
    if (rootMenuItem.enabled === false) return null;
    switch (rootMenuItem.type) {
      case "link":
        return (
          <RootMenuItem
            key={rootMenuItem.title}
            opened={isMenuOpened}
            menuItem={rootMenuItem}
            variant={variant}
          />
        );
      case "catalog":
        return (
          <MegaMenuItem
            key={rootMenuItem.title}
            title={rootMenuItem.title}
            menuTree={layoutStorage.categories}
            variant={variant}
            onMouse
          />
        );
    }
  });
}

Menu.defaultProps = {
  variant: "horizontal",
};
Menu.propTypes = {};

export default observer(Menu);
