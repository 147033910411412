import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/**
 * CSS pass
 *
 * @param title
 * @param link
 * @param delimiter
 * @param position
 * @returns {JSX.Element}
 * @constructor
 */
function BreadcrumbHome({ title, link, delimiter, position }) {
  return (
    <li
      className="breadcrumbs__block breadcrumbs__block-home"
      itemProp="itemListElement"
      itemType="https://schema.org/ListItem"
      itemScope
    >
      <Link to={link} className="breadcrumbs__item" itemprop="item">
        <span itemProp="name">{title}</span>
        <meta itemProp="id" content={link} />
        <meta itemProp="position" content={position} />
      </Link>
      {delimiter}
    </li>
  );
}

BreadcrumbHome.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  delimiter: PropTypes.string.isRequired,
};

export default BreadcrumbHome;
