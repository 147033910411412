import React from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import DropDown from "../../UI/molecules/DropDown";
import layoutStorage from "../../storages/layoutStorage";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function SelectLanguage() {
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <DropDown
        containerClassName="ml-lg-15"
        subcontainerClassName="js-languages-list"
        selectorClassName="header__btn-language"
        menuClassName="header__dropdown"
        currentValue={layoutStorage.currentLanguage}
        values={[
          // { name: "English", value: "en" },
          { name: "Українська", value: "uk" },
          { name: "Русский", value: "ru" },
        ]}
        onSelect={(langCode) => {
          const oldLanguage = layoutStorage.currentLanguage;
          const newRoute = location.pathname.replace(
            `/${oldLanguage}`,
            `/${langCode}`
          );
          layoutStorage.changeLanguage();
          history.push(`/${langCode}`);
        }}
      />
    </>
  );
}

SelectLanguage.propTypes = {};

export default observer(SelectLanguage);
