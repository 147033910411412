import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { observer } from "mobx-react";
import filtersStorage from "../../../storages/filtersStorage";
import productsListStorage from "../../../storages/productsListStorage";
import classNames from "classnames";

function PriceFilter({ title, onToggle, opened }) {
  const COLORS = ["#E5E5E5", "#000000", "#E5E5E5"];
    const accordionClass = classNames({
        "layer-navigation__accordion": true,
        accord: true,
        open: opened,
        // "d-none": !opened,
    });
  return (
    <div
      className="layer-navigation"
      data-js-collection-nav-section="filter_by_price"
      data-js-accordion="all"
    >
      <div
        className="layer-navigation__head py-10 cursor-pointer open"
        data-js-accordion-button
      >
        <div className="h5 d-flex align-items-center mb-0">
          <span className="text-uppercase">{title}</span>
          <i className="layer-navigation__arrow" onClick={onToggle}>
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="icon icon-theme-229"
              viewBox="0 0 24 24"
            >
              <path d="M11.783 14.088l-3.75-3.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l3.301 3.32 3.301-3.32a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .841.841 0 0 1-.215-.127z" />
            </svg>
          </i>
        </div>
      </div>
      <div className={accordionClass}>
        <div className="pt-2 pb-10">
          <div className="collection-filter-by-price pb-30">
            <Range
              min={filtersStorage.minPrice}
              max={filtersStorage.maxPrice}
              values={[
                filtersStorage.priceFilter.min,
                filtersStorage.priceFilter.max,
              ]}
              draggableTrack={false}
              onChange={(values) => {
                // console.log(values);
                filtersStorage.changePriceFilter(values);
                // setValues(values);
              }}
              onFinalChange={(values) => {
                console.log("FINAL:", values);
                filtersStorage.changePriceFilter(values);
                productsListStorage.loadProducts();
              }}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "2px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values: [
                          filtersStorage.priceFilter.min,
                          filtersStorage.priceFilter.max,
                        ],
                        colors: COLORS,
                        min: filtersStorage.minPrice,
                        max: filtersStorage.maxPrice,
                        rtl: false,
                      }),
                      alignSelf: "center",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  className="range-draggable-area"
                  {...props}
                  style={{
                    ...props.style,
                    height: "30px",
                    width: "30px",
                    backgroundColor: "transparent",
                    outline: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      ...props.style,
                      height: "10px",
                      width: "10px",
                      borderRadius: "5px",
                      backgroundColor: "#000000",
                      outline: "none",
                    }}
                  >
                    <div
                      className="position-absolute"
                      style={{ top: "-18px", left: "-50%" }}
                    >
                      {props["aria-valuenow"].toFixed(0)}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="border-bottom" />
    </div>
  );
}

PriceFilter.propTypes = {};

export default observer(PriceFilter);
