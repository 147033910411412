import React from "react";
import { observer } from "mobx-react";
import Logo from "../../../components/HeaderComponents/Logo";
import FaqIndicator from "../../../components/HeaderComponents/FaqIndicator";
import ProfileIndicator from "../../../components/HeaderComponents/ProfileIndicator";
import LikesIndicator from "../../../components/HeaderComponents/LikesIndicator";
import CompareIndicator from "../../../components/HeaderComponents/CompareIndicator";
import CartIndicator from "../../../components/HeaderComponents/CartIndicator";
import SelectCurrency from "../../../components/HeaderComponents/SelectCurrency";
import SelectLanguage from "../../../components/HeaderComponents/SelectLanguage";
import layoutStorage from "../../../storages/layoutStorage";
import ButtonBurger from "../../../UI/atoms/buttons/ButtonBurger";
import LoadableImage from "../../../UI/atoms/images/LoadableImage";
import homePageStorage from "../../../storages/forPages/homePageStorage";
import SearchIndicator from "../../../components/HeaderComponents/SearchIndicator";
import SearchPopUp from "../Popups/SearchPopUp";
import CompareFullPopup from "../Popups/CompareFullPopup/CompareFullPopup";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function TopLine() {
  return (
    <div className="header__content" data-js-mobile-sticky>
      <div className="header__line-top position-relative d-flex px-10 px-lg-0 py-lg-2">
        <div className="container d-flex align-items-center">
          <ButtonBurger
            className="header__btn-menu mr-20 js-popup-button"
            onClick={layoutStorage.burgerClick}
          />
          <Logo />
          <div className="header__sidebar d-flex align-items-center ml-auto">
            {layoutStorage.allowSearch && <SearchIndicator />}
            {layoutStorage.allowFaq && <FaqIndicator />}
            {layoutStorage.allowProfile && <ProfileIndicator />}
            {layoutStorage.allowLikes && <LikesIndicator />}
            {layoutStorage.allowCompare && <CompareIndicator />}
            {layoutStorage.allowCart && <CartIndicator />}
            {layoutStorage.allowCurrencies && <SelectCurrency />}
            {layoutStorage.allowMultilang && <SelectLanguage />}
          </div>
        </div>
      </div>
    </div>
  );
}

TopLine.propTypes = {};

export default observer(TopLine);
