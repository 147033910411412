const createRouteFromStructure = (structure) => {
  let url = "";
  url = `${url}/${structure.language}/catalog`;
  if (structure.cat && structure.cat.length > 0)
    url = `${url}/cat-${structure.cat.join(",")}`;
  if (structure.brands && structure.brands.length > 0)
    url = `${url}/brands-${structure.brands.join(",")}`;
  if (structure.group && structure.group.length > 0)
    url = `${url}/group-${structure.group.join(",")}`;
  if (structure.gender && structure.gender.length > 0)
    url = `${url}/gender-${structure.gender.join(",")}`;
  if (structure.seasons && structure.seasons.length > 0)
    url = `${url}/seasons-${structure.seasons.join(",")}`;
  if (structure.vgroup && structure.vgroup.length > 0)
    url = `${url}/vgroup-${structure.vgroup.join(",")}`;
  if (structure.size && structure.size.length > 0) {
    url = `${url}/size-${structure.size.join(",")}`;
  }
  if (structure.singles && structure.singles.length > 0) {
    url = `${url}/singles-${structure.singles.join(",")}`;
  }
  if (structure.cities && structure.cities.length > 0) {
    url = `${url}/cities-${structure.cities.join(",")}`;
  }
  if (structure.colors && structure.colors.length > 0)
    url = `${url}/color-${structure.colors.join(",")}`;
  // if (structure.price)  url = `${url}/price-${structure.brands.join(",")}`;
  // if (structure.singles
  // if (structure.selections: 0,
  return url;
};

export default createRouteFromStructure;
