import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import Money from "../../../../UI/atoms/blocks/Money";
import currentUserStorage from "../../../../storages/currentUserStorage";

class ProductCardPrice extends Component {
  render() {
    const { t, price, oldPrice } = this.props;
    return (
      // <div className="product-collection__price mb-10">
      <span
        className={`product-collection__price mb-10 price ${
          price !== oldPrice ? "price--sale" : ""
        }`}
      >
        {t("Price")}&nbsp;
        {price !== oldPrice && (
          <Money
            amount={oldPrice}
            currency={t(currentUserStorage.currency)}
            className="price price--sale"
          />
        )}
        <Money amount={price} currency={t(currentUserStorage.currency)} />
      </span>
      // </div>
    );
  }
}

ProductCardPrice.defaultProps = {
  detailed: false,
};
ProductCardPrice.propTypes = {};

export default withTranslation()(observer(ProductCardPrice));
