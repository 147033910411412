import logger from "../helpers/logger";

function requestPost(url, data) {
  return (
    fetch(url, {
      method: "post",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: data ? JSON.stringify(data) : null,
    })
      // .post(url, rq.data || null)
      .then((r) => r.json())
      .catch((e) => {
        if (navigator.onLine === false) {
        }
        logger.log("RQ FAIL:", url, e);
      })
  );
}

export default requestPost;
