import { runInAction } from "mobx";
import urls from "../../storages/api/urls";
import requestPost from "../../api/requestPost";
import requestGet from "../../api/requestGet";
import layoutStorage from "../../storages/layoutStorage";

function parallerProcessor(loadItems) {
  return setTimeout(
    () =>
      loadItems.map((rq, index) => {
        const url =
          rq.url.substr(0, 4) === "http" ? rq.url : `${urls.baseUrl}/${rq.url}`;
        switch (rq.method) {
          case "post":
            return requestPost(url, rq.data).then((data) => rq.processor(data));
          default:
            return requestGet(url).then((data) => rq.processor(data));
        }
      }),
    100
  );
  return Promise.all(
    loadItems.map((rq, index) => {
      const url =
        rq.url.substr(0, 4) === "http" ? rq.url : `${urls.baseUrl}/${rq.url}`;
      switch (rq.method) {
        case "post":
          return requestPost(url, rq.data);
        default:
          return requestGet(url);
      }
    })
  ).then((result) => {
    runInAction(() => {
      // console.log("INITIAL LOAD", result);
      let index = 0;
      for (const data of result) {
        // result.forEach((data, index) => {
        // console.log("INITIAL LOAD PROCESS", index, loads[index].url);
        loadItems[index].processor(data);
        index += 1;
      }
    });
    return result;
  });
}

export default parallerProcessor;
