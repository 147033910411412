import React, { Component } from "react";
import PropTypes from "prop-types";
import layoutStorage from "../../../storages/layoutStorage";
// import DrawerPopUp from "./DrawerPopUp";
import Filters from "../../../pages/ProductPageContent/Filters";
import Drawer from "../../../UI/organisms/Drawer";
import {observer} from "mobx-react";

class FiltersPopUp extends Component {
  render() {
    const { title } = this.props;
    return (
      <Drawer
        title={title}
        opened={layoutStorage.popups.filters}
        id="filters"
        onClose={() => layoutStorage.closePopup("filters")}
        position="left"
      >
        <div className="popup__body position-relative flex-lg-column">
          <div className="popup-filters js-popup-navigation">
            <Filters expanded={false} />
          </div>
        </div>
      </Drawer>
    );
  }
}

FiltersPopUp.propTypes = {};

export default observer(FiltersPopUp);
