import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import currentUserStorage from "../../storages/currentUserStorage";
import Money from "../../UI/atoms/blocks/Money";
import Core from "../../core/Core";

function ShortCartItem({
  refNum,
  title,
  color,
  size,
  price,
  onRemove,
  qty,
  image,
  t,
  vendor,
  gender,
  link,
}) {
  // const link = Core.routing.createRoute(
  //   Core.routing.routingSegments.SEGMENT_PRODUCT,
  //   {
  //     slug: refNum,
  //     title,
  //     vendor,
  //     gender,
  //   }
  // );
  return (
    <div>
      <div className="product-cart d-flex flex-row align-items-start mb-20">
        <div className="product-cart__image mr-15">
          <a href={link} className="d-block">
            {" "}
            <img src={image} alt="title" />
          </a>
        </div>
        <div className="product-cart__content d-flex flex-column align-items-start">
          <div className="product-cart__title mb-3">
            <Link className="h6 m-0" to={link}>
              {title}
            </Link>
          </div>
          <div className="product-cart__variant">
            {color} / {size}
          </div>
          <div className="product-cart__price mt-10 mb-10">
            {qty} x&nbsp;
            <Money
              amount={price}
              currency={t(currentUserStorage.currency)}
              className="price"
            />
          </div>
          <button
            type="button"
            // className="btn btn--text btn-link product-cart__remove btn-link js-product-button-remove-from-cart text-black-50 font-weight-bold"
            className="product-cart__variant btn--text btn-link js-product-button-remove-from-cart text-black-50 border-0"
            style={{ outline: "none" }}
            onClick={onRemove}
          >
            {t("Remove")}
          </button>
        </div>
      </div>
    </div>
  );
}

ShortCartItem.propTypes = {};

export default withTranslation()(observer(ShortCartItem));
