import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Transfered
 */
class IconTheme188Plus extends Component {
  render() {
    return (
      <svg
        aria-hidden="true"
        focusable="false"
        role="presentation"
        className="icon icon-theme-188"
        viewBox="0 0 24 24"
      >
        <path d="M7.158 12.206a.598.598 0 0 1-.186-.439.6.6 0 0 1 .186-.439.602.602 0 0 1 .439-.186h3.75v-3.75a.6.6 0 0 1 .186-.439.602.602 0 0 1 .439-.186c.169 0 .315.063.439.186a.605.605 0 0 1 .186.439v3.75h3.75c.169 0 .315.063.439.186a.605.605 0 0 1 .186.439.6.6 0 0 1-.186.439.601.601 0 0 1-.439.186h-3.75v3.75a.6.6 0 0 1-.186.439.601.601 0 0 1-.439.186.597.597 0 0 1-.439-.186.598.598 0 0 1-.186-.439v-3.75h-3.75a.598.598 0 0 1-.439-.186z" />
      </svg>
    );
  }
}

IconTheme188Plus.propTypes = {};

export default IconTheme188Plus;
