import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { keys } from "mobx";
import { withTranslation } from "react-i18next";
import layoutStorage from "../../../storages/layoutStorage";
// import DrawerPopUp from "./DrawerPopUp";
import ShortCartItem from "../../../pages/CartPageContent/ShortCartItem";
import shoppingCartStorage from "../../../storages/shoppingCartStorage";
import productsListStorage from "../../../storages/productsListStorage";
import Money from "../../../UI/atoms/blocks/Money";
import currentUserStorage from "../../../storages/currentUserStorage";
import FreeShippingDiff from "../../../pages/CartPageContent/FreeShippingDiff";
import currentOrderStorage from "../../../storages/orderStorage";
import Core from "../../../core/Core";
import Drawer from "../../../UI/organisms/Drawer";

function CartPopup({ title, t }) {
  useEffect(() => {
    if (layoutStorage.popups.cart) shoppingCartStorage.loadCart();
  }, [layoutStorage.popups.cart]);
  if (!layoutStorage.popups.cart) return null;
  let total = 0;
  return (
    <Drawer
      title={title}
      opened={layoutStorage.popups.cart}
      name="cart"
      onClose={() => layoutStorage.closePopup("cart")}
      position="right"
    >
      {shoppingCartStorage.cartLoaded && shoppingCartStorage.count > 0 ? (
        <div className="popup-cart__content">
          <div className="popup-cart__items mt-15 border-bottom">
            {keys(shoppingCartStorage.items).map((refNum) => {
              if (!productsListStorage.cartItems[refNum]) return null;
              const {
                parent,
                mainColor,
                relativeLink,
                attributes: { mainSize, gender },
              } = productsListStorage.cartItems[refNum];
              const productParent = parent || refNum;
              const {
                title: productTitle,
                price,
                images,
                vendor,
              } = productsListStorage.cartItems[productParent];
              const qty = shoppingCartStorage.items[refNum];
              total += qty * price;
              return (
                <ShortCartItem
                  refNum={refNum}
                  title={productTitle}
                  vendor={vendor}
                  gender={gender}
                  color={mainColor}
                  size={mainSize}
                  price={price}
                  onRemove={() => shoppingCartStorage.removeFromCart(refNum)}
                  qty={qty}
                  image={images[0] && images[0].smallImageUrl}
                  alt={images[0] && images[0].altText}
                  link={relativeLink}
                />
              );
            })}
          </div>
          <div className="popup-cart__subtotal h5 d-flex align-items-center mt-15 mb-0">
            <p className="m-0">
              {t("SUBTOTAL")}:{" "}
              <Money amount={total} currency={t(currentUserStorage.currency)} />
            </p>
            <span className="ml-auto">
              <span className="price" data-js-popup-cart-subtotal />
            </span>
          </div>
          {/* {layoutStorage.allowInfoBlockInPopUp && <ShippingCostRow />} */}

          <div className="popup-cart__buttons mt-15">
            {!currentOrderStorage.currentDeliveryMethod.integration ? (
              <FreeShippingDiff maxCost="50000" />
            ) : (
              <FreeShippingDiff />
            )}
          </div>

          {/* <FreeShippingDiff /> */}

          <div className="popup-cart__buttons mt-15">
            <Link
              to={Core.routing.createRoute("cart", { slug: "checkout" })}
              className="btn btn--full btn--secondary"
              onClick={() => layoutStorage.closePopup("cart")}
            >
              {t("PROCEED TO CHECKOUT")}
            </Link>
            <Link
              to={Core.routing.predefinedRoutes.cart}
              className="btn btn--full mt-20"
              onClick={() => layoutStorage.closePopup("cart")}
            >
              {t("VIEW SHOPPING BAG")}
            </Link>
          </div>
        </div>
      ) : (
        <div className="popup-cart__empty mt-20">
          {t("Your shopping bag is empty")}
        </div>
      )}
    </Drawer>
  );
}

CartPopup.propTypes = {
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CartPopup));
