import React, { Component } from "react";
import classNames from "classnames";
import DropDownArrow from "./Dropdowns/DropDownArrow";

class Accordion extends Component {
  render() {
    const { opened, title, children, toggle, expandable, bottomBordered } =
      this.props;
    const headerClass = classNames({
      "layer-navigation__head py-10 cursor-pointer": true,
      open: opened,
    });
    const accordionClass = classNames({
      "layer-navigation__accordion": true,
      accord: true,
      open: opened,
      // "d-none": !opened,
    });
    return (
      <div className="layer-navigation">
        {expandable && (
          <div className={headerClass} data-js-accordion-button>
            <div
              className="h5 d-flex align-items-center mb-0 text-uppercase"
              onClick={toggle}
            >
              {title}
              <DropDownArrow className="layer-navigation__arrow" />
              {/* <i className="layer-navigation__arrow"> */}
              {/*  <svg aria-hidden="true" focusable="false" role="presentation" */}
              {/*       className="icon icon-theme-229" viewBox="0 0 24 24"> */}
              {/*    <path */}
              {/*      d="M11.783 14.088l-3.75-3.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l3.301 3.32 3.301-3.32a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .841.841 0 0 1-.215-.127z"/> */}
              {/*  </svg> */}
              {/* </i> */}
            </div>
          </div>
        )}
        <div className={accordionClass} data-js-accordion-content>
          {children}
        </div>
        {bottomBordered && <div className="border-bottom" />}
      </div>
    );
  }
}

Accordion.defaultProps = {
  expandable: true,
  bottomBordered: true,
};

Accordion.propTypes = {};

export default Accordion;
