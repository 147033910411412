import React from "react";
import PropTypes from "prop-types";

function TransparentBackground({
  opacity = 0.5,
  color = "black",
  zIndex = 9999,
  onClick,
}) {
  return (
    <div
      style={{
        backgroundColor: color,
        opacity,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex,
      }}
      onClick={onClick}
    />
  );
}

TransparentBackground.defaultProps = {
  opacity: 0.5,
  color: "black",
  zIndex: 9999,
};

TransparentBackground.propTypes = {
  opacity: PropTypes.number,
  color: PropTypes.string,
  zIndex: PropTypes.number,
};

export default TransparentBackground;
