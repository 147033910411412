import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { observer } from "mobx-react";
import PopperItem from "../../../../components/PopperItem";
import filtersStorage from "../../../../storages/filtersStorage";
import layoutStorage from "../../../../storages/layoutStorage";

class ProductCardColorsCircleItem extends Component {
  render() {
    const { value, active, onClick, disabled } = this.props;
    const colorMapItem = filtersStorage.colorsMap?.[value];
    const webColor = colorMapItem ? colorMapItem.web : value;
    const className = classNames({
      "product-options__value product-options__value--circle rounded-circle text-hide cursor-pointer lazyload loaded": true,
      // [`standard-color-${colors[value]}`]: colors[value],
      [`standard-color-${webColor}`]: value,
      // "standard-color-white": !colors[value],
      "standard-color-white": !colorMapItem,
      active,
    });

    const lang = layoutStorage.currentLanguage;
    const popText = colorMapItem ? colorMapItem[lang] : null;

    return (
      <PopperItem
        popperText={popText || value}
        className={`${className} px-5`}
        onClick={onClick}
        enabled
      >
        {/* <div className={className}>{value}</div> */}
        {value}
      </PopperItem>
    );
  }
}

ProductCardColorsCircleItem.propTypes = {};

export default observer(ProductCardColorsCircleItem);
