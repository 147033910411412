import React from "react";
import IconTheme188Plus from "../../assets/SvgIcons/Themed/IconTheme188_Plus";

function TabContent({
  currentTab,
  setCurrentTab,
  tabIndex,
  tabName,
  children,
}) {
  return (
    <div className={currentTab === tabIndex ? "active" : null}>
      <span
        onClick={() => setCurrentTab(currentTab === tabIndex ? -1 : tabIndex)}
      >
        {tabName}{" "}
        <i>
          <IconTheme188Plus />
        </i>
      </span>
      <div
        className={`tabs__content rte overflow-hidden ${
          currentTab === tabIndex ? "d-block" : ""
        }`}
        data-js-tabs-content
      >
        {children}
      </div>
    </div>
  );
}

TabContent.propTypes = {};

export default TabContent;
