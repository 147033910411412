import React from "react";
import layoutStorage from "../../storages/layoutStorage";
import Indicator from "../../layouts/main/HeaderParts/Indicator";

import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function FaqIndicator() {
  return (
    <Indicator
      openPopup={layoutStorage.openPopup}
      popperText="Поддержка"
      popperPlacement="bottom"
      className="header__btn-services js-popup-button"
      href="#"
      icon={<SvgContaineredImage id="faqIndicator" />}
      name="service"
    />
  );
}

FaqIndicator.propTypes = {};

export default FaqIndicator;
