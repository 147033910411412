import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import layoutStorage from "../../../storages/layoutStorage";
import menuStorage from "../../../storages/menuStorage";
import ArrowRightIcon from "../../../assets/SvgIcons/ArrowRightIcon";

function LinkMenuItem({ t, haveSubitems, menuIndex, menuItem }) {
  const { link, title, name } = menuItem;
  return (
    <div
      className={`menu__item ${
        haveSubitems ? "menu__item--has-children" : ""
      } ${
        ["header"].includes(menuItem.type) && menuIndex == 0
          ? "menu__item--group"
          : ""
      }`}
      onClick={(e) => {
        if (haveSubitems) {
          e.preventDefault();
          e.stopPropagation();
          menuStorage.goToMenu(menuIndex);
        }
      }}
    >
      {haveSubitems ? (
        <div className="d-flex align-items-center justify-content-between px-md-7">
          <span
            // className="text-uppercase"
            className={`text-uppercase ${
              menuItem.title === "Catalogue" ? "text-dark" : ""
            }`}
          >
            {name ||
              t(title) ||
              menuItem[`name_${layoutStorage.currentLanguage}`]}
          </span>
          <span className="d-flex align-items-center">
            {" "}
            {haveSubitems ? <ArrowRightIcon /> : ""}
          </span>
        </div>
      ) : (
        <NavLink
          to={link}
          className="d-flex align-items-center justify-content-between px-md-7"
          onClick={() => layoutStorage.closePopup("navigation")}
        >
          <span className="text-uppercase">{name || t(title)}</span>
        </NavLink>
      )}
    </div>
  );
}

LinkMenuItem.propTypes = {};

export default withTranslation()(LinkMenuItem);
