import React from "react";

function HorizontalSeparator({ text }) {
  return (
    <div className="separator">
      <div className="separator-text">{text}</div>
    </div>
  );
}

HorizontalSeparator.propTypes = {};

export default HorizontalSeparator;
