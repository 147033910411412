import React from "react";
import PropTypes from "prop-types";

function ParagraphCentered({ className, text }) {
  return <p className={`${className} text-center`}>{text}</p>;
}

ParagraphCentered.defaultProps = {
  className: null,
};

ParagraphCentered.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default ParagraphCentered;
