import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Money({ amount, cents, currency, className, digitsAfterComma }) {
  const moneyClass = classNames({
    [className]: !!className,
    money: true,
  });
  return (
    <span className={moneyClass}>
      {(amount / 100).toFixed(cents)} {currency}
    </span>
  );
}

Money.defaultProps = {
  cents: 2,
  currency: "",
  className: null,
};
Money.propTypes = {
  /**
   * Цена в центах/копейках
   */
  amount: PropTypes.number.isRequired,
  cents: PropTypes.number,
  currency: PropTypes.string,
  className: PropTypes.string,
};

export default Money;
