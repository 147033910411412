import React from 'react';

function IconTheme191() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      className="icon icon-theme-191"
      viewBox="0 0 24 24"
    >
      <path d="M3.438 5.479h17.375v1.25H3.438zM3.438 11.104h17.375v1.25H3.438zM3.438 16.729h17.375v1.25H3.438z"/>
    </svg>
  );
}

IconTheme191.propTypes = {};

export default IconTheme191;
