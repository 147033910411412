import React from "react";
import PropTypes from "prop-types";
import LinksTreeBlock from "../../molecules/links/LinksTreeBlock";

/**
 * CSS passed
 *
 * @param h1
 * @param firstBlock
 * @param blocks
 * @returns {JSX.Element}
 * @constructor
 */
function LinksTree({ h1, firstBlock, blocks }) {
  return (
    <div className="links-tree__container">
      <h1>{h1}</h1>
      {firstBlock && <LinksTreeBlock block={firstBlock} />}
      {blocks && blocks.map((block) => <LinksTreeBlock block={block} />)}
    </div>
  );
}

LinksTree.propTypes = {
  h1: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  blocks: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  firstBlock: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default LinksTree;
