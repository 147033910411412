import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import layoutStorage from "../../../storages/layoutStorage";
import IconTheme229 from "../../../assets/SvgIcons/Themed/IconTheme229";
import IconTheme231 from "../../../assets/SvgIcons/Themed/IconTheme231";
import routesGeneragor from "../../../helpers/routesGenerator";
import urls from "../../../storages/api/urls";

/**
 * +
 */
class MegaMenuItem extends Component {
  state = {
    isMenuOpened: false,
    haveOverflow: false,
  };

  closeMenu = () => {
    this.setState({ isMenuOpened: false, haveOverflow: false });
    layoutStorage.closePopup("navigation");
  };

  globalCloseMenu = () => {
    if (this.state.isMenuOpened) this.closeMenu();
  };

  componentDidMount() {
    document.addEventListener("click", this.globalCloseMenu);
    document
      .querySelector(".menu__panel")
      .addEventListener("mouseleave", (event) => {
        const { target } = event;
        if (!!target && target.closest(".menu__panel")) {
          this.globalCloseMenu();
        }
      });
    // console.log(
    //   "RENDERING MENU",
    //   JSON.parse(JSON.stringify(this.props.menuTree))
    // );
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.globalCloseMenu);
  }

  render() {
    const { isMenuOpened, haveOverflow } = this.state;
    const { title, menuTree, t, variant } = this.props;
    const curtainClass = classNames({
      "menu__curtain position-md-absolute": variant === "horizontal",
      open: isMenuOpened,
      close: !isMenuOpened,
      "show visible": isMenuOpened,
      "overflow-auto": haveOverflow,
      // "d-none": !isMenuOpened,
    });
    const menuClass = classNames({
      "menu__megamenu position-md-absolute": true,
      open: isMenuOpened,
      close: !isMenuOpened,
      "show animate visible": isMenuOpened,
      "overflow-auto": haveOverflow,
      // "d-none": !isMenuOpened,

      // accord: true,
      // open: isMenuOpened,
    });
    return (
      <>
        {variant === "horizontal" && (
          <div
            className={curtainClass}
            style={{ height: isMenuOpened ? "540px" : null }}
          />
        )}
        <div className="menu__item menu__item--has-children ">
          <Link
            to={routesGeneragor.getCategoryRoute("cat-all")}
            className="d-flex align-items-center px-md-7"
            onMouseEnter={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState((state) => ({
                isMenuOpened: true,
                haveOverflow: state.haveOverflow,
              }));
              setTimeout(() => {
                this.setState((state) => ({
                  isMenuOpened: state.isMenuOpened,
                  haveOverflow: true,
                }));
              }, 390);
            }}
          >
            <span>{t(title)}</span>{" "}
            <i className="d-none d-md-inline position-md-relative">
              <IconTheme229 />
            </i>
            <i className="d-md-none ml-auto">
              <IconTheme231 />
            </i>
          </Link>
          <div
            className={menuClass}
            style={{
              height: isMenuOpened && variant === "horizontal" ? "540px" : null,
            }}
          >
            <div className="container py-md-40">
              <div className="menu__grid menu__list menu__level-02 row">
                {/* <div className="menu__item menu__back d-md-none"> */}
                {/*  <Link */}
                {/*    to={routesGeneragor.getCategoryRoute(null)} */}
                {/*    onClick={(e) => { */}
                {/*      e.stopPropagation(); */}
                {/*      e.preventDefault(); */}
                {/*      this.setState((state) => ({ */}
                {/*        isMenuOpened: !state.isMenuOpened, */}
                {/*      })); */}
                {/*    }} */}
                {/*  > */}
                {/*    {t(title)} */}
                {/*  </Link> */}
                {/* </div> */}

                {menuTree.map((rootCategory, categoryIndex) => {
                  const { type, enabled } = rootCategory;
                  if (enabled === false) return null;
                  // console.log("RENDERING MENU ROOT", rootCategory);
                  switch (type) {
                    case "group":
                      return (
                        <div
                          key={`rootCategory-${rootCategory.title}-${categoryIndex}`}
                          className="menu__item menu__item--has-children col-md-3 menu__item-header"
                        >
                          <div className="menu__list menu__level-03 row">
                            <div className="col-md">
                              <div className="menu__list--styled">
                                {rootCategory.children.map(
                                  (subItem, subItemIndex) => {
                                    if (subItem.enabled === false) return null;
                                    // console.log(
                                    //   "RENDERING MENU SUBITEM",
                                    //   subItem
                                    // );
                                    switch (subItem.type) {
                                      case "header":
                                        return (
                                          <div
                                            className="menu__item menu__item-header"
                                            key={`subitem-${subItem.link}-${subItemIndex}`}
                                          >
                                            <Link
                                              to={subItem.link}
                                              className="d-flex align-items-center mb-md-10 mt-md-10"
                                              onClick={() => {
                                                subItem.onClick();
                                                layoutStorage.setCurrentCategory(
                                                  subItem
                                                );
                                                this.closeMenu();
                                              }}
                                            >
                                              <span className="menu__title">
                                                {subItem.name}
                                              </span>
                                              {subItem.badge && (
                                                <span className="menu__label menu__label--hot px-3 ml-5 order-1">
                                                  {subItem.badge.title}
                                                </span>
                                              )}
                                            </Link>
                                          </div>
                                        );
                                      default:
                                        return (
                                          <div
                                            className="menu__item menu__item-element"
                                            key={`subitem-${subItem.link}-${subItemIndex}`}
                                          >
                                            <Link
                                              to={subItem.link}
                                              className="d-flex align-items-center px-md-5"
                                              onClick={() => {
                                                subItem.onClick();
                                                layoutStorage.setCurrentCategory(
                                                  subItem
                                                );
                                                this.closeMenu();
                                              }}
                                            >
                                              <span>{subItem.name}</span>
                                              {subItem.badge && (
                                                <span className="menu__label menu__label--hot px-3 ml-5 order-1">
                                                  {subItem.badge.title}
                                                </span>
                                              )}
                                            </Link>
                                          </div>
                                        );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    case "header":
                      return rootCategory.children.map(
                        (subItem, subItemIndex) => {
                          if (subItem.enabled === false) return null;
                          // console.log("RENDERING MENU SUBITEM HEADER", subItem);
                          switch (subItem.type) {
                            case "header":
                              return (
                                <div
                                  className="menu__item menu__item-header"
                                  key={`subitem-${subItem.link}-${subItemIndex}`}
                                >
                                  <Link
                                    to={subItem.link}
                                    className="d-flex align-items-center mb-md-10 mt-md-10"
                                    onClick={() => {
                                      subItem.onClick();
                                      layoutStorage.setCurrentCategory(subItem);
                                      this.closeMenu();
                                    }}
                                  >
                                    <span className="menu__title">
                                      {subItem.name}
                                    </span>
                                    {subItem.badge && (
                                      <span className="menu__label menu__label--hot px-3 ml-5 order-1">
                                        {subItem.badge.title}
                                      </span>
                                    )}
                                  </Link>
                                </div>
                              );
                            default:
                              return (
                                <div
                                  className="menu__item menu__item-element"
                                  key={`subitem-${subItem.link}-${subItemIndex}`}
                                >
                                  <Link
                                    to={subItem.link}
                                    className="d-flex align-items-center px-md-5"
                                    onClick={() => {
                                      subItem.onClick();
                                      layoutStorage.setCurrentCategory(subItem);
                                      this.closeMenu();
                                    }}
                                  >
                                    <span>{subItem.name}</span>
                                    {subItem.badge && (
                                      <span className="menu__label menu__label--hot px-3 ml-5 order-1">
                                        {subItem.badge.title}
                                      </span>
                                    )}
                                  </Link>
                                </div>
                              );
                          }
                        }
                      );
                    case "featured":
                      return (
                        <div className="col-md-4 d-none d-md-block mb-md-30">
                          <a href="/catalog" className="menu__title mb-md-10">
                            Featured products
                          </a>
                          <div className="menu__products row">
                            <div className="col-md-6">
                              <div className="product-search d-flex flex-row flex-md-column align-items-start align-items-md-stretch mb-10">
                                <div className="product-search__image position-relative mr-10 mr-md-0">
                                  <a href="/category" className="d-block">
                                    <div
                                      className="rimage"
                                      style={{ paddingTop: "128.2307692%" }}
                                    >
                                      <img
                                        src="https://cdn.shopify.com/s/files/1/0026/2910/7764/products/3410534250_1_1_1_67eb2dce-35d8-4dfa-8d93-fee6c4b56c49_200x@2x.progressive.jpg?v=1542543797"
                                        loading="lazy"
                                        className="rimage__img rimage__img--fade-in lazyload"
                                        alt="Blend Field Jacket"
                                      />
                                    </div>
                                  </a>
                                </div>
                                <div className="product-search__content d-flex flex-column align-items-start mt-md-15">
                                  <div className="product-search__title mb-3">
                                    <h3 className="h6 m-0">
                                      <a href="/catalog">Blend Field Jacket</a>
                                    </h3>
                                  </div>
                                  <div className="product-search__price mb-10">
                                    <span className="price">
                                      <span>
                                        <span className="money">$470.00</span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="product-search d-flex flex-row flex-md-column align-items-start align-items-md-stretch mb-10">
                                <div className="product-search__image position-relative mr-10 mr-md-0">
                                  <a href="/catalog" className="d-block">
                                    <div
                                      className="rimage"
                                      style={{ paddingTop: "128.2307692%" }}
                                    >
                                      <img
                                        src="https://cdn.shopify.com/s/files/1/0026/2910/7764/products/2121900600_2_3_1_86b90065-d75b-4519-a95e-a02ae468f4e9_200x@2x.progressive.jpg?v=1543244602"
                                        loading="lazy"
                                        className="rimage__img rimage__img--fade-in lazyload"
                                        alt="Jersey Graphic Tee"
                                      />
                                    </div>
                                  </a>
                                </div>
                                <div className="product-search__content d-flex flex-column align-items-start mt-md-15">
                                  <div className="product-search__title mb-3">
                                    <h3 className="h6 m-0">
                                      <a href="/catalog">Jersey Graphic Tee</a>
                                    </h3>
                                  </div>
                                  <div className="product-search__price mb-10">
                                    <span className="price">
                                      <span>
                                        <span className="money">$330.00</span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    case "brands":
                      return (
                        <div className="col-md-2 d-none d-md-block mb-md-30">
                          <a href="/catalog" className="menu__title mb-md-10">
                            Top brands
                          </a>
                          <div className="menu__group menu__group--offset-small row">
                            {rootCategory.children.map((item, itemIndex) => (
                              <div
                                className="col-md-6 d-none d-md-block px-md-5 mb-md-10"
                                key={`item-${item.link}-${itemIndex}`}
                              >
                                <div className="promobox promobox--type-03 d-flex justify-content-center">
                                  <a
                                    href={item.link}
                                    className="image-animation-trigger position-relative w-100"
                                  >
                                    <div className="image-animation image-animation--from-default image-animation--to-default">
                                      <div
                                        className="rimage"
                                        style={{ paddingTop: "100.0%" }}
                                      >
                                        <img
                                          src={`${urls.baseUrl}/uploads/brands/`}
                                          loading="lazy"
                                          className="rimage__img rimage__img--fade-in lazyload"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="promobox__border absolute-stretch" />
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
MegaMenuItem.defaultProps = {
  variiant: "horizontal",
};
MegaMenuItem.propTypes = {};

export default withTranslation()(observer(MegaMenuItem));
