import cloneDeep from "lodash/cloneDeep";
import Core from "../Core";

const routesCache = {};

function parseRoute({ pathname }) {
  const cachedRoute = routesCache[pathname];
  if (cachedRoute) return cloneDeep(routesCache[pathname]);
  let structure = {};
  structure = Core.routing.getBaseStructure();
  const parts = pathname.split("/");
  // eslint-disable-next-line no-unused-vars
  const root = parts.shift();
  structure.language = parts.shift();
  structure.section = parts.shift() || null;
  parts.forEach((part) => {
    const splittedPart = part.split("-");
    const varName = splittedPart.shift();
    const varValue = splittedPart.join("-").split(",");
    structure[varName] = varValue;
  });

  routesCache[pathname] = structure;

  return structure;
}

export default parseRoute;
