import React, {Component} from 'react';
import PropTypes from 'prop-types';

class IconTheme007_RightArrow extends Component {
  render() {
    return (
      <svg
        aria-hidden="true"
        focusable="false"
        role="presentation"
        className="icon icon-theme-007"
        viewBox="0 0 24 24"
      >
        <path d="M6.708 20.917c0-.169.059-.319.176-.449l8.32-8.301-8.32-8.301a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l8.75 8.75c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-8.75 8.75a.91.91 0 0 1-.215.127c-.078.032-.156.049-.234.049s-.156-.017-.234-.049a.91.91 0 0 1-.215-.127.652.652 0 0 1-.176-.449z" />
      </svg>
    );
  }
}

IconTheme007_RightArrow.propTypes = {};

export default IconTheme007_RightArrow;
