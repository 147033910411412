import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Button from "./Button";
import Core from "../core/Core";
import layoutStorage from "../storages/layoutStorage";

class ProductButton extends Component {
  goToCart = () => {
    this.props.history.push(Core.routing.predefinedRoutes.cart);
  };

  render() {
    const { refNum, variant, icon, product, isButtonLarge } = this.props;
    const { inStock, inCart, haveInStock, placeToCart } = product;

    const className = classNames({
      btn: true,
      "js-product-button-add-to-cart":
        !layoutStorage.allowLikes &&
        !layoutStorage.allowCompare &&
        !layoutStorage.allowQuickView,
      "btn-large":
        layoutStorage.allowLikes &&
        layoutStorage.allowCompare &&
        layoutStorage.allowQuickView,
      "btn--status": variant === "smallButton",
      "btn--full btn--status": variant === "bigButton",
      disabled: !haveInStock,
      itemInCart: inCart,
      "btn--secondary": inCart,
      "btn--animated": !inCart,
    });

    let buttonText = "ADD TO CART";
    if (!haveInStock) buttonText = "OUT OF STOCK";
    // if (inCart) buttonText = "IN CART";
    if (inCart) buttonText = "ADD TO CART";

    return (
      <Button
        className={className}
        icon={icon}
        textButton={buttonText}
        onClick={inCart ? this.goToCart : placeToCart}
        disabled={!haveInStock}
        inCart={inCart}
        isButtonLarge={isButtonLarge}
      />
    );
  }
}

ProductButton.propTypes = {};

export default withRouter(observer(ProductButton));
