import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../components/Inputs/Checkbox";

class TreeFilterItem extends Component {
  render() {
    const { title, name, nested, count, level } = this.props;
    return (
      <div
        className="collections-menu__item"
        data-js-accordion="all"
        data-section-for-collection="womens"
      >
        <div
          className="collections-menu__button open d-flex align-items-center mb-15 mb-lg-9 cursor-pointer"
          data-js-accordion-button
        >
          {/* <label className="input-checkbox d-flex align-items-center mb-0 mr-0 cursor-pointer"> */}
          {/*  <input type="radio" className="d-none" name="collection" value="womens" checked="checked" */}
          {/*         data-js-accordion-input/> */}
          {/*  <span className="position-relative d-block mr-8 border"> */}
          {/*    <i className="d-none"> */}
          {/*      <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-theme-146" */}
          {/*           viewBox="0 0 24 24"> */}
          {/*        <path */}
          {/*          d="M9.703 15.489l-2.5-2.5a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449.13-.117.28-.176.449-.176s.319.059.449.176l2.051 2.07 5.801-5.82c.13-.117.28-.176.449-.176s.319.059.449.176c.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-6.25 6.25a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .93.93 0 0 1-.215-.127z"/> */}
          {/*      </svg> */}
          {/*    </i> */}
          {/*  </span> */}
          {/*  <span>{title || name}</span> */}
          {/* </label> */}
          {/* {nested && ( */}
          {/*  <i className="collections-menu__arrow"> */}
          {/*    <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-theme-229" */}
          {/*         viewBox="0 0 24 24"> */}
          {/*      <path */}
          {/*        d="M11.783 14.088l-3.75-3.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l3.301 3.32 3.301-3.32a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .841.841 0 0 1-.215-.127z"/> */}
          {/*    </svg> */}
          {/*  </i> */}
          {/* )} */}
          {/* <span className="ml-auto">{count || 0}</span> */}
          <Checkbox
            name={name}
            title={title}
            haveNested={!!nested}
            appendText={count || 0}
          />
        </div>
        {nested && (
          <div
            className="collections-menu__list ml-25"
            data-js-accordion-content
          >
            {nested.map((item) => (
              <TreeFilterItem
                key={item.title}
                title={item.title}
                name={item.name}
                count={item.count}
                level={level + 1}
                nested={item.children}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

TreeFilterItem.propTypes = {};

export default TreeFilterItem;
