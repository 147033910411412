import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import ru from "./translations/ru.json";
import uk from "./translations/uk.json";
import logger from "../helpers/logger";

// const initTranslations = (cb) => {
//   setTranslations({
//     en,
//     uk,
//     ru,
//   });
//   setDefaultLanguage("ru");
//   if (cb) cb(null, true);
//   // setLanguageCookie("language", {}, "language");
//   // const lang = getLanguage();
//   // // if (lang != 'ua') {
//   // //
//   // // }
//   // appState.setCurrentLanguage(getLanguage());
// };

const translateErrors = {};

export function initI18Next(cb) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: { translation: en },
        ru: { translation: ru },
        uk: { translation: uk },
      },
      lng: "ru",
      fallbackLng: "en",
      saveMissing: true,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        if (!translateErrors[`${lng}-${ns}-${key}`]) {
          logger.log("Missing translate key", lng, ns, key, fallbackValue);
          translateErrors[`${lng}-${ns}-${key}`] = true;
        }
      },

      interpolation: {
        escapeValue: false,
      },
    })
    .then(() => {
      if (cb) cb(null, true);
    });
}

// export default initTranslations;
