import { observer } from "mobx-react";
import layoutStorage from "../storages/layoutStorage";

class RoutesGenerator {
  getCategoryRoute = (category) => {
    let { currentLanguage, defaultLanguage } = layoutStorage;
    currentLanguage =
      currentLanguage !== defaultLanguage ? `${currentLanguage}/` : "";
    let requestCategory = category;
    if (category === "all") {
      requestCategory = "";
    }
    return `/${currentLanguage}catalog/${requestCategory || ""}`;
  };
}

const routesGeneragor = new RoutesGenerator();

export default routesGeneragor;
