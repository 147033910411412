import urls from "../../storages/api/urls";
import filtersStorage from "../../storages/filtersStorage";
import processRoute from "../../helpers/processRoute";

const dictionariesApiProcessor = {
  url: `${urls.baseUrl}/api/dictionaries`,
  processor: (data) => {
    filtersStorage.updateDictionaries(data);
    filtersStorage.setPaymentMethods(data.payment);
    filtersStorage.setDeliveryMethods(data.delivery);
    processRoute();
    filtersStorage.dictionaryLoaded = true;
  },
};

export default dictionariesApiProcessor;
