import RoutingSegments from "../constants/RoutingSegments";
import createRouteFromStructure from "./routing/createRouteFromStructure";
import createProductRoute from "./routing/createProductRoute";
import createOrderPageRoute from "./routing/createOrderPageRoute";
import cyrillicToTranslit from "../helpers/cyrillicToTranslit";
import parseRoute from "./routing/parseRoute";
import createFilterRoute from "./routing/createFilterRoute";
import createRoute from "./routing/createRoute";
import requestGet from "../api/requestGet";
import requestPost from "../api/requestPost";
import filtersStorage from "../storages/filtersStorage";

class CoreModule {
  routing = {
    routingSegments: {
      SEGMENT_BRAND: "brands",
      SEGMENT_CATEGORY: "category",
      SEGMENT_CATALOG: "catalog",
      SEGMENT_GENDER: "gender",
      SEGMENT_CONTENT: "content",
      SEGMENT_CART: "cart",
      SEGMENT_PRODUCT: "product",
      SEGMENT_SIZES: "sizes",
      SEGMENT_SIZE: "size",
      SEGMENT_SINGLES: "singles",
      SEGMENT_SEASONS: "seasons",
      SEGMENT_VGROUP: "vgroup",
      SEGMENT_TRANSLIT: "translit",
      SEGMENT_ORDER: "orders",
      SEGMENT_HOME: "home",
      SEGMENT_GROUP: "group",
      SEGMENT_ACCOUNT: "account",
      SEGMENT_CITY: "cities",
    },
    createRouteFromStructure,
    createProductRoute,
    createOrderRoute: createOrderPageRoute,
    createFilterRoute,
    parseRoute,
    createRoute,
    getBaseStructure: () => ({
      section: null,
      language: null,
      brands: null,
      category: null,
      gender: null,
      group: null,
      seasons: null,
      vgroup: null,
      singles: null,
      sizes: null,
      colors: null,
      price: null,
      selections: 0,
      cities: null,
    }),
    predefinedRoutes: {
      cart: null,
      home: null,
    },
  };

  lang = {
    translit: (text) =>
      cyrillicToTranslit().transform(
        text.replace(/[,-\/]/g, "_").toLowerCase(),
        "_"
      ),
  };

  api = {
    get: requestGet,
    post: requestPost,
  };

  init() {
    filtersStorage.deserialize();
    this.routing.predefinedRoutes.cart = this.routing.createRoute(
      this.routing.routingSegments.SEGMENT_CART,
      {}
    );
    this.routing.predefinedRoutes.home = this.routing.createRoute(
      this.routing.routingSegments.SEGMENT_HOME,
      {}
    );
  }
}

const Core = new CoreModule();

export default Core;
