import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CompareContentTableLeftCell from "./CompareContentTableLeftCell";
import CompareContentTableRightCell from "./CompareContentTableRightCell";

class CompareContentTableRow extends Component {
    render() {
        const { title, data } = this.props;
        return (
            <tr>
                <CompareContentTableLeftCell title={title}/>
                {data.map(text => {
                return <td>
                    <div className="compare__items d-flex">
                            <CompareContentTableRightCell text={text}/>
                    </div>
                </td>
                })}
                {/*<td className="compare__title d-lg-none w-100 pr-0 text-right border-left">*/}
                {/*    <h4 className="h6 mb-0">DESCRIPTION</h4>*/}
                {/*</td>*/}
            </tr>
        );
    }
}

CompareContentTableRow.propTypes = {};

export default CompareContentTableRow;
