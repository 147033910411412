import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import Checkbox from "../../../components/Inputs/Checkbox";
import Accordion from "../../../components/Accordion";
import filtersStorage from "../../../storages/filtersStorage";
import HorizontalSeparator from "../../../UI/atoms/HorizontalSeparator";

function chunk(data, columns) {
  const chunks = [];
  const chunkSize = Math.ceil(data.length / columns);
  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize));
  }
  return chunks;
}

class CheckboxesFilterContainer extends Component {
  render() {
    const {
      title,
      data,
      columns,
      dontShowAppend,
      opened,
      onChange,
      field,
      onToggle,
      t,
      expandable,
      bottomBordered,
      subtitle,
    } = this.props;
    if (data.length == 0 && expandable == false) return null;
    let items = [];
    if (columns > 1) {
      items = chunk(data, columns);
    } else {
      items = [data];
    }
    const columnsClass = classNames({
      "mb-10": true,
      "col-12": columns <= 1 || columns > 4,
      "col-6": columns == 2,
      "col-4": columns == 3,
      "col-3": columns == 4,
    });
    return (
      <Accordion
        opened={opened}
        title={t(title)}
        toggle={onToggle}
        expandable={expandable}
        bottomBordered={bottomBordered}
      >
        {subtitle && <HorizontalSeparator text={subtitle} />}
        <div className="pt-2 pb-10">
          {/* <div> */}
          <div
            className={`collection-filters row filters-${field}`}
            data-js-collection-filters
          >
            {items.map((chunks, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={columnsClass} key={`filter-chunk-${index}`}>
                {chunks.map((item) => (
                  <div
                    className="d-flex align-items-center mb-15 mb-lg-9 cursor-pointer"
                    key={item.title}
                  >
                    <Checkbox
                      name={item.name}
                      title={item.title}
                      filterValue={item.realName}
                      label={t(item.title.toString())}
                      link={item.link}
                      checked={
                        filtersStorage.currentFilters[field]?.[item.realName]
                      }
                      haveNested={false}
                      appendText={item.count || 0}
                      dontShowAppend={dontShowAppend}
                      onChange={(e) => {
                        if (item.count > 0) {
                          onChange(field, item.realName);
                        }
                      }}
                      disabled={item.count <= 0}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          {/* </div> */}
        </div>
      </Accordion>
    );
  }
}

CheckboxesFilterContainer.defaultProps = {
  columns: 1,
  opened: false,
};
CheckboxesFilterContainer.propTypes = {};

export default withTranslation()(observer(CheckboxesFilterContainer));
