import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import PopperItem from "../../../components/PopperItem";
// import McAfeeSvg from "../../../assets/SvgIcons/McAfee";
// import AmericanExpressIcon from "../../../assets/SvgIcons/payments/AmericanExpressIcon";
// import DiscoverNetworkIcon from "../../../assets/SvgIcons/payments/DiscoverNetworkIcon";
import menuStorage from "../../../storages/menuStorage";
import SimpleImage from "../../../UI/atoms/images/SimpleImage";
import layoutStorage from "../../../storages/layoutStorage";

import SvgContaineredImage from "../../../UI/atoms/images/SvgContaineredImage";
import Icons from "../../../assets/SvgIcons/Icons";
// import PayPalIcon from "../../../assets/SvgIcons/payments/PayPalIcon";
// import Aes256Icon from "../../../assets/SvgIcons/payments/Aes256Icon";
// import ShopyfySecureIcon from "../../../assets/SvgIcons/payments/ShopyfySecureIcon";
// import Stripe from "../../../assets/SvgIcons/payments/Stripe";

function FooterTape({ t }) {
  return (
    <div className="footer__tape py-lg-15">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column flex-lg-row align-items-lg-center py-40 py-lg-0">
            <div
              className="footer__logo m-auto ml-lg-0 mr-lg-15"
              // style={{ maxWidth: "64px" }}
            >
              <a href="/" className="d-block text-hide">
                <SimpleImage
                  src={layoutStorage.logoImageUrl}
                  altText={t(layoutStorage.shopName)}
                  className="logo-small"
                />
              </a>
            </div>
            <div
              className="footer__copyright pt-lg-0 mt-20 mt-lg-0"
              dangerouslySetInnerHTML={{ __html: menuStorage.copyright }}
            />
            <div className="d-lg-none">
              <div className="footer__social-media-mobile d-lg-none">
                <div className="social-media d-flex flex-wrap justify-content-center justify-content-lg-start">
                  {menuStorage.socials.Facebook && (
                    <a
                      href={menuStorage.socials.Facebook}
                      target="_blank"
                      className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10"
                    >
                      <Icons id="FacebookIcon" />
                    </a>
                  )}
                  {/* <a */}
                  {/*  href="https://twitter.com/" */}
                  {/*  target="_blank" */}
                  {/*  className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10" */}
                  {/* > */}
                  {/*  <i> */}
                  {/*    <TwitterIcon /> */}
                  {/*  </i> */}
                  {/* </a> */}
                  {/* <a */}
                  {/*  href="https://plus.google.com/" */}
                  {/*  target="_blank" */}
                  {/*  className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10" */}
                  {/* > */}
                  {/*  <i> */}
                  {/*    <GooglePlus /> */}
                  {/*  </i> */}
                  {/* </a> */}
                  {menuStorage.socials.Instagram && (
                    <a
                      href={menuStorage.socials.Instagram}
                      target="_blank"
                      className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10"
                    >
                      <Icons id="InstagramIcon" />
                    </a>
                  )}
                  {menuStorage.socials.Viber && (
                    <a
                      href={menuStorage.socials.Viber}
                      target="_blank"
                      className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10"
                    >
                      <Icons id="ViberIcon" />
                    </a>
                  )}
                  {/* <a */}
                  {/*  href="https://www.youtube.com/" */}
                  {/*  target="_blank" */}
                  {/*  className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10" */}
                  {/* > */}
                  {/*  <i> */}
                  {/*    <Pinterest /> */}
                  {/*  </i> */}
                  {/* </a> */}
                  {/* <a */}
                  {/*  href="https://www.instagram.com/" */}
                  {/*  target="_blank" */}
                  {/*  className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10" */}
                  {/* > */}
                  {/*  <i> */}
                  {/*    <YoutubeIcon /> */}
                  {/*  </i> */}
                  {/* </a> */}
                  {/* <a */}
                  {/*  href="https://www.behance.net/" */}
                  {/*  target="_blank" */}
                  {/*  className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10" */}
                  {/* > */}
                  {/*  <i> */}
                  {/*    <BehanceIcon /> */}
                  {/*  </i> */}
                  {/* </a> */}
                  {/* <a */}
                  {/*  href="https://www.skype.com/" */}
                  {/*  target="_blank" */}
                  {/*  className="d-flex align-items-center mb-6 mx-15 mx-lg-0 mr-lg-10" */}
                  {/* > */}
                  {/*  <i> */}
                  {/*    <SkypeIcon /> */}
                  {/*  </i> */}
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="footer__border border-top w-100 d-lg-none" />
          <div className="col-lg-6 d-flex flex-column flex-lg-row align-items-lg-center pt-20 pb-15 py-lg-0">
            <div className="footer__payments d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center ml-lg-auto">
              {/* <PopperItem popperText="Power by stripe" className="px-5"> */}
              {/*  <Stripe /> */}
              {/* </PopperItem> */}
              {/* <PopperItem popperText="Shopify secure" className="px-5"> */}
              {/*  <ShopyfySecureIcon /> */}
              {/* </PopperItem> */}
              {/* <PopperItem popperText="Aes 256bit" className="px-5"> */}
              {/*  <Aes256Icon /> */}
              {/* </PopperItem> */}
              {/* <PopperItem popperText="Paypal" className="px-5"> */}
              {/*  <PayPalIcon /> */}
              {/* </PopperItem> */}
              <PopperItem popperText="Visa" className="px-5">
                <SvgContaineredImage id="visaIcon" width={64} />
              </PopperItem>
              <PopperItem popperText="Mastercard" className="px-5">
                <SvgContaineredImage id="mastercardIcon" height={64} />
              </PopperItem>
              <PopperItem popperText="LiqPay" className="px-5">
                <SvgContaineredImage id="liqPayIcon" width={64} />
              </PopperItem>
              {/* <PopperItem popperText="Discover network" className="px-5"> */}
              {/*  <DiscoverNetworkIcon /> */}
              {/* </PopperItem> */}
              {/* <PopperItem popperText="American Express" className="px-5"> */}
              {/*  <AmericanExpressIcon /> */}
              {/* </PopperItem> */}

              {/* <PopperItem popperText="McAfee Secure" className="px-5"> */}
              {/*  <McAfeeSvg /> */}
              {/* </PopperItem> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FooterTape.propTypes = {};

export default withTranslation()(observer(FooterTape));
