import urls from "../../storages/api/urls";
import currentUserStorage from "../../storages/currentUserStorage";
import homePageStorage from "../../storages/forPages/homePageStorage";

const profileApiProcessor = {
  url: `${urls.baseUrl}/api/v3/profile`,
  processor: (data) => {
    const profile = data?.profile;
    const structures = data?.structure;
    if (profile) {
      currentUserStorage.setUser(profile);
    }
    if (structures) {
      // @todo: Доделать загрузку категорий
      const heros = JSON.parse(structures.heros);
      const herosSliderConfig = JSON.parse(structures.herosSliderConfig);
      const promotions = JSON.parse(structures.promos);
      const featuredCategories = JSON.parse(structures.featuredCategories);
      homePageStorage.setHero(heros);
      homePageStorage.setHerosSliderConfig(herosSliderConfig);
      homePageStorage.setPromos(promotions);
      homePageStorage.setFeaturedCategories(featuredCategories);
    }
    currentUserStorage.isLoaded = true;
  },
};

export default profileApiProcessor;
