import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import HeaderTape from "./HeaderParts/HeaderTape";
import TopLine from "./HeaderParts/TopLine";
import SecondLine from "./HeaderParts/SecondLine";
import currentCategoryStorage from "../../storages/currentCategoryStorage";
import device from "../../helpers/deviceDetect";

class Header extends React.Component {
  currentScrollPos = 0;

  state = {
    scrollDirectionUp: true,
  };

  componentDidMount() {
    const { location } = this.props;
    currentCategoryStorage.loadTexts(location.pathname);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props;
    if (location?.pathname !== prevProps.location?.pathname) {
      currentCategoryStorage.loadTexts(location.pathname);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (e) => {
    const { scrollDirectionUp } = this.state;
    const delta = window.pageYOffset - this.currentScrollPos;
    if (delta <= 0 && scrollDirectionUp !== true) {
      this.setState((state) => ({
        scrollDirectionUp: true,
      }));
    }
    if (delta > 0 && scrollDirectionUp !== false) {
      this.setState((state) => ({
        scrollDirectionUp: false,
      }));
    }
    this.currentScrollPos = window.pageYOffset;
  };

  render() {
    const cn = classNames({
      "theme-section theme-section top-sm-0 top-xs-0 slide-menu-up-": true,
      "d-block hide-header": !this.state.scrollDirectionUp,
      "animate show-header": this.state.scrollDirectionUp,
    });
    const headerClass = classNames({
      "header position-lg-relative": true,
    });
    return (
      <>
        <div
          id="theme-section-header"
          // className="theme-section top-sm-0 top-xs-0 slide-menu-up"
          className={cn}
        >
          <header
            id="header"
            className={headerClass}
            data-js-static="desktop_and_mobile"
            data-js-desktop-sticky-sidebar="true"
          >
            <HeaderTape />
            <div className="header__content" data-js-mobile-sticky="true">
              <TopLine />
              {(!device.isMobile || document.width > 1024) && <SecondLine />}
            </div>
          </header>
        </div>
      </>
    );
  }
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(observer(Header));
