import React, {Component} from 'react';
import PropTypes from 'prop-types';

class IconTheme006_LeftArrow extends Component {
  render() {
    return (
      <svg
        aria-hidden="true"
        focusable="false"
        role="presentation"
        className="icon icon-theme-006"
        viewBox="0 0 24 24"
      >
        <path d="M16.736 3.417a.652.652 0 0 1-.176.449l-8.32 8.301 8.32 8.301c.117.13.176.28.176.449s-.059.319-.176.449a.91.91 0 0 1-.215.127c-.078.032-.156.049-.234.049s-.156-.017-.234-.049a.93.93 0 0 1-.215-.127l-8.75-8.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449l8.75-8.75a.652.652 0 0 1 .449-.176c.169 0 .319.059.449.176.117.13.176.28.176.449z" />
      </svg>
    );
  }
}

IconTheme006_LeftArrow.propTypes = {};

export default IconTheme006_LeftArrow;
