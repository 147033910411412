import { runInAction } from "mobx";
import merge from "lodash/merge";
import layoutStorage from "../storages/layoutStorage";
import getFilterObject from "./getFilterObject";
import filtersStorage from "../storages/filtersStorage";
import productsListStorage from "../storages/productsListStorage";
import logger from "./logger";
import Core from "../core/Core";

const deepFind = (income, value) => {
  for (let i = 0; i < income.length; i++) {
    if (income[i].children) {
      for (let j = 0; j < income[i].children.length; j++) {
        if (
          income[i].children[j].name === value ||
          income[i].children[j].slug === `cat-${value}` ||
          income[i].children[j].slugId === `cat-${value}`
        )
          return income[i].children[j];
      }
    }
  }
  return null;
};

export default function processRoute(arg, cb) {
  const structure = Core.routing.parseRoute(window.location);
  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = urlParams.get("page");
  if (currentPage && +currentPage > 0) {
    productsListStorage.currentPage = +currentPage - 1;
  } else {
    productsListStorage.currentPage = 0;
  }
  let filters = {};
  if (structure.cat) {
    if (structure.language !== layoutStorage.currentLanguage) {
      layoutStorage.changeLanguage(structure.language);
    }
    logger.log("STRUCTURE", structure);
    if (structure.brands) {
      filters.brands = {};
      structure.brands.forEach((brand) => {
        const obj = getFilterObject("brands", brand);
        if (obj) {
          filters.brands[obj.realName] = true;
        }
      });
    } else {
      filters.brands = {};
    }
    if (structure.group) {
      filters.group = {};
      structure.group.forEach((group) => {
        const obj = getFilterObject("group", group);
        if (obj) {
          filters.group[obj.realName] = true;
        }
      });
    } else {
      filters.group = {};
    }
    if (structure.size) {
      filters.sizes = {};
      structure.size.forEach((size) => {
        const obj = getFilterObject("sizes", size);
        if (obj) {
          filters.sizes[obj.realName] = true;
        }
      });
    } else {
      filters.sizes = {};
    }
    if (structure.seasons) {
      filters.seasons = {};
      structure.seasons.forEach((season) => {
        const obj = getFilterObject("seasons", season);
        if (obj) {
          filters.seasons[obj.realName] = true;
        }
      });
    } else {
      filters.seasons = {};
    }
    if (structure.vgroup) {
      filters.vgroup = {};
      structure.vgroup.forEach((vgroup) => {
        const obj = getFilterObject("vgroup", vgroup);
        if (obj) {
          filters.vgroup[obj.realName] = true;
        }
      });
    } else {
      filters.vgroup = {};
    }

    if (structure.gender) {
      filters.gender = {};
      structure.gender.forEach((gender) => {
        const obj = getFilterObject("gender", gender);
        // console.log(
        //   "RQ:",
        //   obj,
        //   gender,
        //   filtersStorage.filters.find((f) => f.field === "gender")
        // );
        if (obj) {
          filters.gender[obj.realName] = true;
        }
      });
    }
    if (structure.singles) {
      filters.singles = {};
      structure.singles.forEach((gender) => {
        const obj = getFilterObject("singles", gender);
        // console.log(
        //   "RQ:",
        //   obj,
        //   gender,
        //   filtersStorage.filters.find((f) => f.field === "gender")
        // );
        if (obj) {
          filters.singles[obj.realName] = true;
        }
      });
    }
    if (structure.cities) {
      filters.cities = {};
      structure.cities.forEach((city) => {
        const obj = getFilterObject("cities", city);
        if (obj) {
          filters.cities[obj.realName] = true;
        }
      });
    }
    runInAction(() => {
      let categoryData;
      if (structure.cat) {
        categoryData = deepFind(layoutStorage.categories, structure.cat);
        if (categoryData) {
          layoutStorage.setCurrentCategory(categoryData);
          // console.log(
          //   "PROCESS ROUTE CATEGORY: ",
          //   JSON.parse(JSON.stringify(layoutStorage.currentCategory))
          // );
          if ([/* 48, 1, 2, 3, */ 50, 49].includes(categoryData.id)) {
            filters = merge(filters, categoryData.filters, {
              category_id: { [categoryData.id]: true },
            });
          } else {
            filters = merge(filters, {
              category_id: { [categoryData.id]: true },
            });
          }
        }
      } else {
        categoryData = deepFind(layoutStorage.categories, "all");
        layoutStorage.setCurrentCategory(categoryData);
      }
      // console.log("STRUCTURE", filters);
      filtersStorage.setFilters(filters);
      productsListStorage.loading = false;
      productsListStorage.loadProducts(true, cb);
    });
  }
}
