import React from "react";
import PropTypes from "prop-types";

function SvgContaineredImage({
  id,
  wrapperClass,
  className,
  width = 24,
  height = 24,
  isWrapped = true,
}) {
  const icon = (
    <svg
      role="img"
      width={width}
      height={height}
      className={className}
      id={`${id}-container`}
    >
      <use href={`/images/svgContainer-v3.svg#${id}`} />
    </svg>
  );
  return isWrapped ? <i className={wrapperClass}>{icon}</i> : icon;
}

SvgContaineredImage.propTypes = {
  id: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isWrapped: PropTypes.bool,
};

export default SvgContaineredImage;
