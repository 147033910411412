function cyrillicToTranslit() {
  // letters shared between languages
  const alphabet = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "io",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "kh",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ъ: "_",
    ы: "i",
    ь: "j",
    э: "e",
    ю: "yu",
    я: "ya",
    ґ: "g",
    ѓ: "g",
    і: "i",
    "'": "_",
    "’": "_",
    ʼ: "_",
    є: "ye",
    ї: "yi",
    " ": "-",
    "/": "-",
  };

  function transform(input, spaceReplacement = "_") {
    if (!input) {
      return "";
    }

    const normalizedInput = input.trim().toLowerCase();
    let result = "";

    for (let i = 0; i < normalizedInput.length; i++) {
      const letter = normalizedInput[i];
      const replacer = alphabet[letter] || letter;
      result += replacer;
    }
    return result;
  }

  return {
    transform,
  };
}

module.exports = cyrillicToTranslit;
