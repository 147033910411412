import React from "react";
import PopperItem from "../PopperItem";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";
import Indicator from "../../layouts/main/HeaderParts/Indicator";
import layoutStorage from "../../storages/layoutStorage";
import {withTranslation} from "react-i18next";
import productsListStorage from "../../storages/productsListStorage";
import shoppingCartStorage from "../../storages/shoppingCartStorage";
import {observer} from "mobx-react";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function CompareIndicator({ t }) {
  return (
    <div>
      <Indicator
        openPopup={layoutStorage.openPopup}
        popperText={t("Compare")}
        popperPlacement="bottom"
        className="header__btn-account js-popup-button"
        href="/compare"
        icon={<SvgContaineredImage id="iconTheme039" isWrapped />}
        name="compare"
        counterValue={productsListStorage.compareProductsCount}
      >
      </Indicator>
    </div>
  );
}

CompareIndicator.propTypes = {};

export default withTranslation()(observer(CompareIndicator));
