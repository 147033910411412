/* eslint-disable no-console */
class Logger {
  log = (...data) => {
    if (process.env.NODE_ENV === "development") {
      console.log(...data);
    }
  };
}

const logger = new Logger();

export default logger;
