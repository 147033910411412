import React from "react";

function ErrorBox({ text }) {
  if (!text) return null;
  return <div className="text-danger mb-0">{text}</div>;
}

ErrorBox.propTypes = {};

export default ErrorBox;
