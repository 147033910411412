import React, { Component } from "react";
import PropTypes from "prop-types";

class ProductCardDescription extends Component {
  render() {
    const { text } = this.props;
    return text ? (
      <div className="product-collection__description d-none- mb-15">
        {text}
      </div>
    ) : null;
  }
}

ProductCardDescription.propTypes = {};

export default ProductCardDescription;
