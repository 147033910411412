import React from "react";
import PropTypes from "prop-types";

function SimpleImage({ src, altText, alt, className }) {
  return src ? (
    <img src={src} loading="lazy" className={className} alt={alt || altText} />
  ) : (
    <div className={`${className} ph-item`} style={{ minHeight: "200px" }}>
      <img className="ph-picture w-90 h-100" />
    </div>
  );
}

SimpleImage.defaultProps = {
  className:
    "rimage__img rimage__img--cover rimage__img--fade-in lazyload loaded",
};
SimpleImage.propTypes = {
  altText: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SimpleImage;
