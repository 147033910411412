import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Settings from "./Settings";
import FooterTape from "./Footer/FooterTape";
import MainFoter from "./Footer/MainFoter";
import NotificationsContainer from "../../modules/notifications/components/NotificationsContainer";
import CookieNotification from "./Notifications/CookieNotification";
// import ConfirmTransferPopUp from "./Popups/ConfirmTransferPopUp";
// import DeliveryReturnPopUp from "./Popups/ConfirmTransferPopUp";
import layoutStorage from "../../storages/layoutStorage";
import FootersLinks from "../../UI/organisms/FootersLinks/FootersLinks";
import CloseableBottomDialog from "../../UI/molecules/CloseableBottomDialog";
// import SidebarBlogPopUp from "./Popups/SidebarBlogPopUp";
// import SizeGuidePopUp from "./Popups/SizeGuidePopUp";
// import WishListFullPopup from "./Popups/WishListFullPopup";
// import WishListPopup from "./Popups/WishListPopup";
// import SidebarPopUp from "./Popups/SidebarPopUp";
// import SubscribePopup from "./Popups/SubscribePopup";
// import QuickViewPopUp from "./Popups/QuickViewPopUp";
// import CompareFullPopup from "./Popups/CompareFullPopup";
const Admin = React.lazy(() =>
  import(/* webpackChunkName: "Admin" */ "../../UI/admin/Admin")
);

function Footer({ t }) {
  return (
    <>
      <div id="theme-section-footer" className="theme-section">
        <div data-section-id="footer" data-section-type="footer">
          <footer
            id="footer"
            className="footer mt-0  text-center text-lg-left js-footer"
            data-js-footer-fixed
          >
            <div className="footer__border border-top d-none d-lg-block" />
            <FootersLinks />
            <MainFoter />
            <div className="footer__border border-top" />
            <FooterTape />
          </footer>
          <Link
            to="#header"
            className="footer__back-to-top d-flex position-fixed flex-center"
            data-js-button-back-to-top="600"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.scroll(0, 0);
            }}
          >
            <i>
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-theme-014"
                viewBox="0 0 24 24"
              >
                <path d="M11.791 21.505a.598.598 0 0 1-.439-.186.601.601 0 0 1-.186-.439V4.883l-4.551 4.57a.649.649 0 0 1-.449.177.652.652 0 0 1-.449-.176.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449l5.625-5.625a2.48 2.48 0 0 1 .107-.068c.032-.02.068-.042.107-.068a.736.736 0 0 1 .468 0c.039.026.075.049.107.068.032.02.068.042.107.068l5.625 5.625c.117.13.176.28.176.449a.652.652 0 0 1-.176.449.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .877.877 0 0 1-.215-.127l-4.551-4.57V20.88a.6.6 0 0 1-.186.439.59.59 0 0 1-.437.186z" />
              </svg>
            </i>
            {/* <span className="d-lg-none mt-4 ml-2">TOP</span> */}
          </Link>
        </div>
      </div>
      {/* <div id="theme-section-popup" className="theme-section"> */}
      {/*  */}
      {/* </div> */}
      {layoutStorage.allowCookieNotifications && (
        <div id="theme-section-footbar" className="theme-section">
          <div data-section-id="footbar" data-section-type="footbar">
            <div className="footbar d-flex flex-column align-items-lg-start position-fixed bottom-0 left-0 w-100 pointer-events-none">
              <NotificationsContainer />
              <CookieNotification />
            </div>
          </div>
        </div>
      )}
      <Settings />
      <div className="scroll-offset-example" />
      {layoutStorage.isEditorEnabled && (
        <CloseableBottomDialog
          className="admin__container"
          onClose={layoutStorage.toggleEditorEnable}
        >
          <Admin />
        </CloseableBottomDialog>
      )}
    </>
  );
}

Footer.propTypes = {};

export default withTranslation()(observer(Footer));
