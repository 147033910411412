import React, { Component } from "react";
import { Redirect, BrowserRouter, Switch, Route } from "react-router-dom";
import { observer } from "mobx-react";
import ReactGA from "react-ga";

// import Blog from "./pages/Blog";
// import Gallery from "./pages/Gallery";
// import ListCollection from "./pages/ListCollection";
// import LookBook from "./pages/LookBook";
// import ScrollRestoration from "./helpers/ScrollRestoration";

import { withTranslation } from "react-i18next";
import currentUserStorage from "./storages/currentUserStorage";
import layoutStorage from "./storages/layoutStorage";
import storages from "./storages/storages";
import initialLoad from "./api/initial/initial";
import logger from "./helpers/logger";
import AccountPopup from "./layouts/main/Popups/AccountPopup";
import FiltersPopUp from "./layouts/main/Popups/FiltersPopUp";
import ServicesPopup from "./layouts/main/Popups/ServicesPopup";
import CartPopup from "./layouts/main/Popups/CartPopup";
import NavigationPopUp from "./layouts/main/Popups/NavigationPopUp";
import device from "./helpers/deviceDetect";
import SearchPopUp from "./layouts/main/Popups/SearchPopUp";
import Layout from "./layouts/main/Layout";
import CompareFullPopup from "./layouts/main/Popups/CompareFullPopup/CompareFullPopup";
import productsListStorage from "./storages/productsListStorage";
import compareProductsAdapter from "./helpers/adapters/compareProductsAdapter";
import Test from "./pages/Test";

// const Layout = React.lazy(() =>
//   import(/* webpackChunkName: "LayoutPage" */ "./layouts/main/Layout")
// );
const Error404 = React.lazy(() =>
  import(/* webpackChunkName: "Error404Page" */ "./pages/Error404")
);
const ProductDetail = React.lazy(() =>
  import(/* webpackChunkName: "ProductDetailPage" */ "./pages/ProductDetail")
);
const Products = React.lazy(() =>
  import(/* webpackChunkName: "ProductsPage" */ "./pages/Products")
);
const Landing = React.lazy(() =>
  import(/* webpackChunkName: "LandingPage" */ "./pages/Landing")
);
const AccountPage = React.lazy(() =>
  import(/* webpackChunkName: "AccountPage" */ "./pages/AccountPage")
);
const CheckOutPage = React.lazy(() =>
  import(/* webpackChunkName: "CheckoutPage" */ "./pages/CheckOutPage")
);
const CartPage = React.lazy(() =>
  import(/* webpackChunkName: "CartPage" */ "./pages/CartPage")
);
const ContentPage = React.lazy(() =>
  import(/* webpackChunkName: "ContentPage" */ "./pages/ContentPage")
);
const ChecksPage = React.lazy(() =>
  import(/* webpackChunkName: "ChecksPage" */ "./pages/Checks")
);
const OrderStatusPage = React.lazy(() =>
  import(/* webpackChunkName: "OrderStatusPage" */ "./pages/OrderStatusPage")
);
const SitemapPage = React.lazy(() =>
  import(/* webpackChunkName: "SitemapPage" */ "./pages/Sitemap")
);
const PromosListPage = React.lazy(() =>
  import(/* webpackChunkName: "PromosListPage" */ "./pages/PromosListPage")
);

class App extends Component {
  componentDidMount() {
    logger.log("LANDING - app component mounted");
    const init = () =>
      new Promise(async (resolve) => {
        ReactGA.initialize("UA-2360502-14");
        storages.init();
        initialLoad((...rest) => {
          logger.log("LANDING - initials loaded");
          resolve(...rest);
        });
      });
    init().then((result) => {});
  }

  render() {
    const { t } = this.props;
    return (
      <BrowserRouter>
        <Layout>
          <AccountPopup />
          {device.isMobile && <FiltersPopUp title={t("Filters")} />}
          <ServicesPopup />
          <CartPopup title={t("Bag")} />
          <CompareFullPopup
            title={t("Compare")}
            productsForCompare={compareProductsAdapter(
              [
                productsListStorage.compareProductsIdList
                  // .newProductsList
                  .map((refNum) => productsListStorage.products[refNum]),
                // .slice(0,4)
              ],
              [
                { title: "name", path: "productTitle" },
                { title: "gender", path: "attributes.gender" },
              ]
            )}
          />
          {/* <SidebarPopUp /> */}
          {/* <CompareFullPopup /> */}
          {/* <ConfirmTransferPopUp /> */}
          {/* <DeliveryReturnPopUp /> */}
          {device.isMobile && <NavigationPopUp title={t("Navigation")} />}
          {/* <QuickViewPopUp /> */}
          {/* <SidebarBlogPopUp /> */}
          {/* <SizeGuidePopUp /> */}
          {/* <SubscribePopup /> */}
          {/* <WishListFullPopup /> */}
          {/* <WishListPopup /> */}
          {layoutStorage.error404 ? (
            <Error404 />
          ) : (
            <Switch>
              {/* <Route */}
              {/*  path="/swagger" */}
              {/*  render={() => ( */}
              {/*    <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" /> */}
              {/*  )} */}
              {/* /> */}
              <Redirect exact from="/" to="/ru" />
              <Route exact path="/:lang" component={Landing} />
              <Route exact path="/:lang/test" component={Test} />
              <Route
                path="/:lang?/products/detail/:id/:subID?"
                component={ProductDetail}
              />
              <Route
                path="/:lang?/product/:slug/:id/:subID?"
                component={ProductDetail}
              />
              <Route path="/:lang?/catalog" component={Products} />
              <Route path="/:lang?/cart/checkout" component={CheckOutPage} />
              <Route path="/:lang?/cart" component={CartPage} />
              <Route path="/:lang?/account" component={AccountPage} />
              <Route path="/:lang?/content/:slug" component={ContentPage} />
              <Route path="/:lang?/serve/checks" component={ChecksPage} />
              <Route path="/:lang?/sitemap" component={SitemapPage} />
              <Route path="/:lang?/promo" component={PromosListPage} />
              <Route
                path="/:lang?/orders/status/:orderId"
                component={OrderStatusPage}
              />
              <Route
                path="/payments/result/:id"
                render={(props) => (
                  <Redirect
                    to={`/${currentUserStorage.language}/orders/status/${props.match.params.id}`}
                  />
                )}
              />
              <Route path="/:lang?/404" component={Error404} />
              {/* <Route path="/:lang?/catalog" component={Products} /> */}
              {/* <Route path="/:lang?/blog" component={Blog} /> */}
              {/* <Route path="/:lang?/gallery" component={Gallery} /> */}
              {/* <Route path="/:lang?/collection" component={ListCollection} /> */}
              {/* <Route path="/:lang?/lookbook" component={LookBook} /> */}
            </Switch>
          )}
        </Layout>
        {/* <HtmlTextDialog */}
        {/*  html={[ */}
        {/*    contentStorage.pages.terms_ru?.content?.content, */}
        {/*    contentStorage.pages.terms_ru?.content?.content, */}
        {/*    contentStorage.pages.terms_ru?.content?.content, */}
        {/*  ].join("\n")} */}
        {/*  title="Title" */}
        {/*  opened */}
        {/* /> */}
        <SearchPopUp />
      </BrowserRouter>
    );
  }
}

export default withTranslation()(observer(App));
