import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/**
 * CSS passed
 *
 * @param title
 * @param link
 * @param childItems
 * @returns {JSX.Element}
 * @constructor
 */
function LinksBlockHeader({ title, link, childItems }) {
  return (
    <div className="links-tree__header">
      <Link to={link} className="links-tree__title">
        {title}
      </Link>
      {childItems &&
        childItems.map((child) => (
          <div className="links-tree__item">
            <Link to={child.link}>{child.title}</Link>
          </div>
        ))}
    </div>
  );
}

LinksBlockHeader.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  childItems: PropTypes.array.isRequired,
};

export default LinksBlockHeader;
