// import "react-app-polyfill/ie9";
// import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
// import { getCLS, getFID, getLCP, getFCP, getTTFB } from "web-vitals";
import axios from "axios";
import { configure } from "mobx";
// import reportWebVitals from "./reportWebVitals";

// import ReactGA from "react-ga";
import logger from "./helpers/logger";
import { initI18Next } from "./i18n/init";
import App from "./App";
import Core from "./core/Core";
import "./scss/node-components.scss";
import "./scss/main.scss";

logger.log("LANDING - scripts loaded");
if (process.env.NODE_ENV === "production") {
  import("./utils/sentry").then((d) => {
    const initSentry = d.default;
    initSentry();
  });
}

if (!window.Proxy) {
  configure({ useProxies: "never" });
}
axios.defaults.withCredentials = true;

// initTranslations();
initI18Next();
logger.log("LANDING - translations loaded");
// const App = React.lazy(() => import("./App"));
Core.init();

ReactDOM.render(
  <React.StrictMode>
    {/* <React.Suspense fallback={<Loader />}> */}
    <React.Suspense fallback={null}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

logger.log("LANDING - after render");
logger.log("LANDING - at the end");

// const logVitals = (...rest) => logger.log("VITALS", ...rest);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(logVitals);

if (process.env.NODE_ENV === "development") {
  // setInterval(() => {
  // getCLS(logVitals, true);
  // getFID(logVitals, true);
  // getFCP(logVitals, true);
  // getLCP(logVitals, true);
  // getTTFB(logVitals, true);
  // }, 5000);
}
