import React from "react";
import PropTypes from "prop-types";
import BreadcrumbHome from "../../atoms/breadcrumbs/BreadcrumbHome";
import BreadcrumbItem from "../../atoms/breadcrumbs/BreadcrumbItem";
import logger from "../../../helpers/logger";
import layoutStorage from "../../../storages/layoutStorage";

/**
 * CSS pass
 *
 * @param homeTitle
 * @param homeLink
 * @param items
 * @param delimiter
 * @param currentTitle
 * @returns {JSX.Element}
 * @constructor
 */
function BreadcrumbsBlock({
  homeTitle,
  homeLink,
  items,
  delimiter,
  currentTitle,
}) {
  return (
    <div className="breadcrumbs">
      <ul
        className="breadcrumbs__container"
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        <BreadcrumbHome
          title={homeTitle ? homeTitle.toUpperCase() : null}
          link={homeLink}
          delimiter={delimiter}
          position={1}
        />
        {items.map((block, index) => (
          <li
            className="breadcrumbs__block breadcrumbs__block-middle"
            itemProp="itemListElement"
            itemType="https://schema.org/ListItem"
            itemScope
            key={`${block.title}-${index}`}
          >
            {/* <meta itemProp="position" content={index + 1} /> */}
            {/* <meta itemProp="name" content={block.title.toUpperCase()} /> */}
            {/* <meta itemProp="id" content={block.link} /> */}
            <BreadcrumbItem
              title={block?.title ? block.title.toUpperCase() : null}
              link={block.link}
              position={index + 1}
            />
            {delimiter}
          </li>
        ))}
        <li
          className="breadcrumbs__block breadcrumbs__block-last"
          itemProp="itemListElement"
          itemType="https://schema.org/ListItem"
          itemScope
        >
          {/* <span itemProp="item"> */}
          <span itemProp="name">
            {currentTitle ? currentTitle.toUpperCase() : null}
          </span>
          <meta itemProp="id" content={window.location.pathname} />
          <meta itemProp="position" content={items.length + 2} />
          {/* </span> */}
        </li>
      </ul>
    </div>
  );
}

BreadcrumbsBlock.defaultProps = {
  delimiter: ">>",
};
BreadcrumbsBlock.propTypes = {
  homeTitle: PropTypes.string,
  homeLink: PropTypes.string,
  items: PropTypes.array,
  delimiter: PropTypes.string,
  currentTitle: PropTypes.string,
};

export default BreadcrumbsBlock;
