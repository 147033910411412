import React, { useEffect, useState } from "react";
import tippy, { roundArrow } from "tippy.js";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Div = ({ children, ref, ...props }) => <div {...props}>{children}</div>;

function PopperItem({
  enabled,
  placement,
  children,
  popperText,
  className,
  href,
  onClick,
  theme,
  arrow,
  animation,
  style,
  showPopperTextOnClick,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  useEffect(() => {
    if (enabled && popperText && referenceElement && showPopperTextOnClick) {
      tippy(referenceElement, {
        content: popperText,
        theme,
        arrow: arrow || roundArrow,
        animation,
        placement,
        trigger: "click",
        hideOnClick: false,
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 5000);
        },
      });
    }
    if (enabled && popperText && referenceElement && !showPopperTextOnClick) {
      tippy(referenceElement, {
        content: popperText,
        theme,
        arrow: arrow || roundArrow,
        animation,
        placement,
      });
    }
  }, [referenceElement, enabled]);

  let Component = Link;
  if (!href) Component = Div;

  if (!href) {
    return (
      <div
        to={href}
        className={className}
        ref={setReferenceElement}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    );
  }
  return (
    <Link
      to={href}
      className={className}
      ref={setReferenceElement}
      onClick={onClick}
      style={style}
    >
      {children}
    </Link>
  );
}

PopperItem.defaultProps = {
  placement: "top",
  enabled: true,
  popperText: null,
  children: null,
  className: null,
  href: null,
  onClick: null,
  theme: "tomato",
  arrow: null,
  animation: false,
};
PopperItem.propTypes = {
  enabled: PropTypes.bool,
  placement: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  popperText: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  arrow: PropTypes.any,
  animation: PropTypes.bool,
};

export default PopperItem;
