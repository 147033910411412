import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

class CompareContentTableRightCell extends Component {
    render() {
        const { text } = this.props;
        return (
            <div
                className="compare__item col py-5"
                data-js-store-lists-product-compare
                data-product-handle="skinny-mid-rise-trousers"
            >
                <p className="fs-lg mb-0">
                    {text}
                </p>
            </div>
        );
    }
}

CompareContentTableRightCell.propTypes = {};

export default CompareContentTableRightCell;
