import React, { useState } from "react";
import Tab from "../atoms/Tab";

function TabsPanel({ tabs, currentTab, setCurrentTab }) {
  return (
    <div className="tabs__slider justify-content-lg-center">
      {tabs.map((tabText, index) => (
        <Tab
          key={tabText}
          active={currentTab === index}
          text={tabText}
          onClick={() => setCurrentTab(index)}
        />
      ))}
    </div>
  );
}

TabsPanel.propTypes = {};

export default TabsPanel;
