import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import DrawerPopUp from "../DrawerPopUp";
import layoutStorage from "../../../../storages/layoutStorage";
import Drawer from "../../../../UI/organisms/Drawer";
import ProductCard from "../../../../containers/ProductCard/ProductCard";
import ProductCardBadgesBlock from "../../../../containers/ProductCard/components/image/badges/ProductCardBadgesBlock";
import CompareFullPopupContent from "./CompareContent";
import CloseIcon from "../../../../assets/SvgIcons/CloseIcon";
import CompareFullPopupHeader from "./CompareHeader";
import productsListStorage from "../../../../storages/productsListStorage";

class CompareFullPopup extends Component {
  render() {
    const { title, t, productsForCompare } = this.props;
    const closePopup = () => layoutStorage.closePopup("compare");
    if (!layoutStorage.popups.compare) return null;
    return (
      <Drawer
        // title={t(title)}
        opened={layoutStorage.popups.compare}
        name="compare"
        onClose={closePopup}
        position="center"
        hideHeader
      >
        {/* <div className="popup__body position-relative flex-center px-15"> */}
        {/* <div */}
        {/*  className="popup-compare-full py-25 px-20 mr-auto ml-auto w-100" */}
        {/*  // onClick={() => layoutStorage.closePopup("compare")} */}
        {/* > */}
        <CompareFullPopupHeader
          onClose={closePopup}
          disableRemoveAll={productsListStorage.compareProductsCount === 0}
          onClick={productsListStorage.clearCompareList}
        />
        {productsListStorage.compareProductsCount ? (
          <CompareFullPopupContent productsForCompare={productsForCompare} />
        ) : (
          <div
            style={{
              width: "100%",
              minHeight: 70,
              display: "flex",
              justifyContent: "center",
              fontSize: 24,
            }}
          >
            {t("Nothing to compare")}
          </div>
        )}
        {/* </div> */}
        {/* </div> */}
      </Drawer>
    );
  }
}

CompareFullPopup.propTypes = {};

export default withTranslation()(observer(CompareFullPopup));
