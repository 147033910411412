import attributesStorage from "../../storages/attributesStorage";
import layoutStorage from "../../storages/layoutStorage";
import createRouteFromStructure from "./createRouteFromStructure";
import Core from "../Core";

function createFilterRoute(slug, segment) {
  // console.log("CITIES", segment, slug);
  const newStructure = Core.routing.parseRoute(window.location);
  if (!newStructure[segment]) newStructure[segment] = [];
  const attribute = attributesStorage[segment]?.[slug];
  let internalSlug = null;
  if (attribute) {
    internalSlug = attribute[`slug_${layoutStorage.currentLanguage}`];
  } else {
    switch (segment) {
      case "cities":
        internalSlug = slug.replace(/\s/g, "_").toLowerCase();
        break;
      default:
        internalSlug = Core.lang.translit(slug);
    }
  }
  const index = newStructure[segment].findIndex((f) => f == internalSlug);
  if (index >= 0) {
    newStructure[segment].splice(index, 1);
  } else {
    newStructure[segment].push(internalSlug);
  }
  return createRouteFromStructure(newStructure);
}

export default createFilterRoute;
