import urls from "../../storages/api/urls";
import attributesStorage from "../../storages/attributesStorage";
import layoutStorage from "../../storages/layoutStorage";
import filtersStorage from "../../storages/filtersStorage";
import homePageStorage from "../../storages/forPages/homePageStorage";
import menuStorage from "../../storages/menuStorage";

const categoriesApiProcessor = {
  url: `${urls.baseUrl}/api/v7/categories`,
  processor: (data) => {
    attributesStorage.fillData(data.attributes);
    if (data.configure) {
      layoutStorage.setBrands(data.configure.brands);
      filtersStorage.setColors(data.configure.colors);
      menuStorage.setSocials(data.configure.socialLinks);
      menuStorage.setFooterSections(data.configure.footerSection);
      // homePageStorage.setHero(data.configure.hero);
      menuStorage.setMainMenu(data.configure.mainMenu);
    }
    layoutStorage.setCategories(data.categories);
  },
};

export default categoriesApiProcessor;
