import React from "react";
import PopperItem from "../PopperItem";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function LikesIndicator() {
  return (
    <div className="ml-25 ml-lg-15">
      {/* <a */}
      {/*  href="#" */}
      {/*  className="header__btn-wishlist d-flex align-items-center position-relative text-nowrap js-popup-button" */}
      {/*  data-js-popup-button="wishlist" */}
      {/*  data-js-sticky-replace-element="wishlist" */}
      {/*  data-js-tooltip */}
      {/*  data-tippy-content="Wish list" */}
      {/*  data-tippy-placement="bottom" */}
      {/*  data-tippy-distance="6" */}
      {/* > */}
      <PopperItem
        popperText="Wish list"
        className="header__btn-wishlist d-flex align-items-center position-relative text-nowrap js-popup-button"
        href="/wishlist"
      >
        <SvgContaineredImage id="iconTheme180" isWrapped />
        <span className="header__counter" data-js-wishlist-count="0">
          0
        </span>
      </PopperItem>
      {/* </a> */}
    </div>
  );
}

LikesIndicator.propTypes = {};

export default LikesIndicator;
