import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DropDownArrow from "../Dropdowns/DropDownArrow";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

function Checkbox({
  title,
  name,
  haveNested,
  appendText,
  checked,
  dontShowAppend,
  onChange,
  disabled,
  label,
  link,
}) {
  return (
    <>
      <Link
        to={link}
        onClick={(e) => {
          if (disabled) {
            e.stopPropagation();
            e.preventDefault();
          } else {
            onChange();
          }
        }}
      >
        <label
          className={`input-checkbox d-flex align-items-center mb-0 mr-0 cursor-pointer ${
            disabled ? "disabled" : ""
          }`}
        >
          <input
            type="checkbox"
            className="d-none"
            name="collection"
            checked={checked}
            value={name || title}
            onChange={onChange}
            data-js-accordion-input
            disabled={disabled}
          />
          <span className="position-relative d-block mr-8 border">
            {checked && <SvgContaineredImage id="iconTheme146" isWrapped />}
          </span>

          {/* {link ? ( */}
          {/*  // <span> */}

          {label}
          {/* </Link> */}
          {/* ) : ( */}
          {/*  // </span> */}
          {/*  label */}
          {/* )} */}
        </label>
      </Link>
      {haveNested && (
        <DropDownArrow className="collections-menu__arrow" />
        // <i className="collections-menu__arrow">
        //   <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-theme-229"
        //        viewBox="0 0 24 24">
        //     <path
        //       d="M11.783 14.088l-3.75-3.75a.652.652 0 0 1-.176-.449c0-.169.059-.319.176-.449a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176l3.301 3.32 3.301-3.32a.65.65 0 0 1 .449-.176c.169 0 .318.059.449.176.117.13.176.28.176.449a.652.652 0 0 1-.176.449l-3.75 3.75a.877.877 0 0 1-.215.127.596.596 0 0 1-.468 0 .841.841 0 0 1-.215-.127z"/>
        //   </svg>
        // </i>
      )}
      {!dontShowAppend && appendText && (
        <span className="ml-auto">{appendText}</span>
      )}
    </>
  );
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
};
Checkbox.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  haveNested: PropTypes.bool,
  appendText: PropTypes.string,
  checked: PropTypes.bool,
  dontShowAppend: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Checkbox;
