import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import AddToCartButton from "../UI/molecules/CartButtons/AddToCartButton";

class Button extends Component {
  onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  render() {
    const { t } = this.props;
    const { textButton, className, icon, disabled, inCart, isButtonLarge } =
      this.props;
    return (
      <AddToCartButton
        text={t(textButton)}
        className={className}
        icon={icon}
        onClick={this.onClick}
        disabled={disabled}
        inCart={inCart}
        isButtonLarge={isButtonLarge}
      />
    );
  }
}

Button.defaultProps = {
  textButton: null,
  className: null,
  icon: null,
};
Button.propTypes = {
  textButton: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};

export default withTranslation()(Button);
