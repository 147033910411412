import React from "react";
import { observer } from "mobx-react";
import RootMenuItem from "../../../containers/MainMenuContainer/RootMenuItem";
import menuStorage from "../../../storages/menuStorage";
import layoutStorage from "../../../storages/layoutStorage";
import MegaMenuItem from "./MegaMenuItem";
import IconTheme168 from "../../../assets/SvgIcons/Themed/IconTheme168";
import Menu from "./Menu";

// import MegaMenuSimpleMultilevel from "../MegaMenu/MegaMenuSimpleMultilevel";
// import MegaMenuOneSimpleDropdown1 from "../MegaMenu/MegaMenuOneSimpleDropdown1";
// import MegaMenuOneSimpleDropdown from "../MegaMenu/MegaMenuOneSimpleDropdown";
// import MegaMenuWomens from "../MegaMenu/MegaMenuWomens";
// import MegaMenuMens from "../MegaMenu/MegaMenuMens";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function SecondLine() {
  return (
    <>
      <div className="header__border border-bottom d-none d-md-block" />
      <div className="gl-header__menu d-sm-none d-md-block">
        <div className="header__line-bottom position-relative d-md-flex py-lg-6">
          <div
            className="header__sticky-logo d-none align-items-md-center w-100 h-100 header__sticky-logo--displaced position-absolute top-0 left-0 py-6 ml-15"
            style={{ maxWidth: "64px" }}
          />
          <div className="container d-md-flex">
            <div className="header__nav d-none d-md-flex justify-content-between">
              <nav className="menu js-menu js-position">
                <div
                  className="menu__panel menu__list menu__level-01 d-flex flex-column flex-md-row flex-md-wrap menu__panel--bordered"
                  style={{ width: "420px" }}
                >
                  <Menu />
                  {/* <MegaMenuSimpleMultilevel /> */}
                  {/* <MegaMenuOneSimpleDropdown1 /> */}
                  {/* <MegaMenuOneSimpleDropdown /> */}
                  {/* <MegaMenuWomens /> */}
                  {/* <MegaMenuMens /> */}
                </div>
              </nav>
            </div>
            <a
              href="/search"
              className="header__btn-search d-none d-md-flex align-items-md-center ml-md-auto js-popup-button d-none-important"
            >
              <span className="mr-md-7">SEARCH</span>
              <i>
                <IconTheme168 />
              </i>
            </a>
          </div>
          <div className="header__sticky-sidebar position-absolute d-none align-items-md-center top-0 right-0 h-100 mr-15">
            <div className="ml-md-15" data-js-sticky-replace-here="wishlist" />
            <div className="ml-md-15" data-js-sticky-replace-here="compare" />
            <div className="ml-md-15" data-js-sticky-replace-here="cart" />
          </div>
        </div>
        <div className="header__border border-bottom d-none d-md-block" />
      </div>
      <div className="header__border border-bottom d-md-none" />
    </>
  );
}

SecondLine.defaultProps = {};
SecondLine.propTypes = {};

export default observer(SecondLine);
