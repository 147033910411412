import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import shoppingCartStorage from "../../storages/shoppingCartStorage";
import layoutStorage from "../../storages/layoutStorage";
import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

/**
 * +
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
function CartIndicator({ t }) {
  return (
    <div className="ml-25 ml-lg-15">
      <a
        href="/cart"
        className="header__btn-cart position-relative d-flex align-items-center text-nowrap js-popup-button"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          layoutStorage.openPopup("cart");
        }}
      >
        <SvgContaineredImage id="iconTheme109" isWrapped />
        <span className="d-none d-lg-inline mt-lg-3">
          {t("Bag")} ({shoppingCartStorage.count})
        </span>
        <span className="header__counter d-lg-none">
          {shoppingCartStorage.count}
        </span>
      </a>
    </div>
  );
}

CartIndicator.propTypes = {};

export default withTranslation()(observer(CartIndicator));
