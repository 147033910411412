import React, { useState } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import menuStorage from "../../../storages/menuStorage";
import Icons from "../../../assets/SvgIcons/Icons";
import layoutStorage from "../../../storages/layoutStorage";
import SvgContaineredImage from "../../../UI/atoms/images/SvgContaineredImage";

function FooterSection({ section, sectionClass, iconClass, t }) {
  const [opened, setOpened] = useState(false);
  if (
    menuStorage.footerSections.length < section ||
    !menuStorage.footerSections[section]
  )
    return null;
  const { title, type, items, html, subtitle, icon } =
    menuStorage.footerSections[section];
  // console.log("SECTION", section, menuStorage.footerSections[section]);

  const accordionClass = classNames({
    // "d-lg-block": true,
    "layer-navigation__accordion": true,
    "accord-new": true,
    open: opened,

    // sectionClass: true,
    // "footer__section-content d-lg-block": !opened,
    //
  });

  return (
    <>
      <div className="footer__section">
        <div
          className="footer__section-head position-relative"
          onClick={() => setOpened(!opened)}
        >
          <div className="h5 py-10 py-lg-0 mb-0 mb-lg-10 text-uppercase">
            {t(title)}
          </div>
          <div className="footer__section-btn d-flex d-lg-none position-absolute align-items-center justify-content-center">
            <SvgContaineredImage id="iconTheme188" isWrapped />
          </div>
        </div>
        <div
          // className={`footer__section-content ${
          //   !opened && "d-none"
          // } d-lg-block ${sectionClass}`}
          className={accordionClass}
        >
          {html && <span dangerouslySetInnerHTML={{ __html: html }} />}
          {subtitle && <h6 className="mb-15">{t(subtitle)}</h6>}
          {/* {type === "simple" && ( */}
          <ul className="list-unstyled pb-15 pb-lg-0">
            {items.map((item) => {
              if (item.enabled !== true) return null;
              const onAuthClick = (e) => {
                e.preventDefault();
                e.stopPropagation();
                layoutStorage.openPopup("account");
                return false;
              };
              return (
                <li
                  key={item.title}
                  className="py-5 py-lg-0 d-flex align-items-center justify-content-sm-center justify-content-md-start"
                >
                  {item.icon && (
                    <i className={iconClass}>
                      <Icons id={item.icon} />
                    </i>
                  )}
                  {item.html && (
                    <span dangerouslySetInnerHTML={{ __html: item.html }} />
                  )}
                  {item.link &&
                    item.title &&
                    (item.type !== "external" ? (
                      <Link
                        to={item.link}
                        onClick={(e) =>
                          // console.log("Link ", item.link);
                          item.link == "auth" ? onAuthClick(e) : true
                        }
                      >
                        {t(item.title)}
                      </Link>
                    ) : (
                      // eslint-disable-next-line react/jsx-no-target-blank
                      <a href={item.link} target="_blank">
                        {t(item.title)}
                      </a>
                    ))}
                </li>
              );
            })}
          </ul>
          {/* )} */}
          {/* {type === "html" && ( */}
          {/*  <p className="d-flex justify-content-center justify-content-lg-start mb-15"> */}
          {/*    {icon} */}
          {/*    <a href="mailto:shellashopify2019@gmail.com" className="btn-link"> */}
          {/*      Send us an email */}
          {/*    </a> */}
          {/*  </p> */}
          {/* )} */}
        </div>
      </div>
    </>
  );
}

FooterSection.defaultProps = {
  iconClass: "mr-10",
  sectionClass: null,
};
FooterSection.propTypes = {
  section: PropTypes.number.isRequired,
  sectionClass: PropTypes.string,
  iconClass: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(FooterSection));
