import React, { useEffect } from "react";
import { observer } from "mobx-react";
// import CurrentFilters from "../../containers/Filters/CurrentFilters";
import { withTranslation } from "react-i18next";
import filtersStorage from "../../storages/filtersStorage";
import TreeFilterContainer from "../../containers/Filters/TreeFilter/TreeFilterContainer";
import CheckboxesFilterContainer from "../../containers/Filters/CheckboxesFilter/CheckboxesFilterContainer";
import ColorFilters from "./FilterContainers/ColorFilters";
import PriceFilter from "./FilterContainers/PriceFilter";
import attributesStorage from "../../storages/attributesStorage";
import HorizontalSeparator from "../../UI/atoms/HorizontalSeparator";
import Accordion from "../../components/Accordion";

function Filters({ t, expanded }) {
  console.log("ROUTES", filtersStorage.filters);
  return (
    <>
      {/* <CurrentFilters /> */}
      {filtersStorage.filters.map((filter) => {
        if (filter.enabled === false) return null;
        switch (filter.type) {
          case "tree":
            return (
              <TreeFilterContainer
                key={filter.title}
                title={filter.title}
                data={filter.items}
                expanded={expanded}
              />
            );
            break;
          case "colors":
            return (
              <ColorFilters
                key={filter.title}
                title={filter.title}
                data={filter.items}
                expanded={expanded}
              />
            );
            break;
          case "checkboxes":
            return (
              <CheckboxesFilterContainer
                key={filter.title}
                title={t(filter.title)}
                name={filter.title}
                data={filter.items.filter((i) => i.count > 0)}
                columns={filter.columns}
                dontShowAppend={!!filter.dontShowAppend}
                opened={filter.opened}
                onChange={filtersStorage.setFilter}
                field={filter.field}
                onToggle={filter.toggleOpened}
                expanded={expanded}
              />
            );
            break;
          case "sizes":
            return (
              <>
                <Accordion
                  opened={filter.opened}
                  title={t(filter.title)}
                  toggle={filter.toggleOpened}
                  expandable
                  bottomBordered
                >
                  <CheckboxesFilterContainer
                    key={filter.title}
                    title={t(filter.title)}
                    name={filter.title}
                    data={filter.items.filter(
                      (i) =>
                        i.count > 0 &&
                        i.name.match(/^\d+(|[-_]\d+)$/) &&
                        parseFloat(i.name.replace(/[-_]/, ".")) <= 43
                    )}
                    columns={filter.columns}
                    dontShowAppend={!!filter.dontShowAppend}
                    opened={filter.opened}
                    onChange={filtersStorage.setFilter}
                    field={filter.field}
                    onToggle={filter.toggleOpened}
                    expanded={expanded}
                    bottomBordered={false}
                    subtitle={t("Foot size")}
                    expandable={false}
                  />
                  <CheckboxesFilterContainer
                    key={filter.title}
                    // title={t(filter.title)}
                    name={filter.title}
                    data={filter.items.filter(
                      (i) => i.count > 0 && i.name.match(/^\d+sm$/)
                    )}
                    columns={filter.columns}
                    dontShowAppend={!!filter.dontShowAppend}
                    opened={filter.opened}
                    onChange={filtersStorage.setFilter}
                    field={filter.field}
                    onToggle={filter.toggleOpened}
                    expanded={expanded}
                    expandable={false}
                    subtitle={t("Head size")}
                    bottomBordered={false}
                  />
                  <CheckboxesFilterContainer
                    key={filter.title}
                    title={t(filter.title)}
                    name={filter.title}
                    data={filter.items.filter(
                      (i) =>
                        i.count > 0 &&
                        i.name.match(/^\d+(|[-_]\d+)$/) &&
                        parseFloat(i.name.replace(/[-_]/, ".")) > 43
                    )}
                    columns={filter.columns}
                    dontShowAppend={!!filter.dontShowAppend}
                    opened={filter.opened}
                    onChange={filtersStorage.setFilter}
                    field={filter.field}
                    onToggle={filter.toggleOpened}
                    expanded={expanded}
                    bottomBordered={false}
                    subtitle={t("Height")}
                    expandable={false}
                  />
                  <CheckboxesFilterContainer
                    key={filter.title}
                    title={t(filter.title)}
                    name={filter.title}
                    data={filter.items.filter(
                      (i) => i.count > 0 && i.name.match(/^[a-z]+$/i)
                    )}
                    columns={filter.columns}
                    dontShowAppend={!!filter.dontShowAppend}
                    opened={filter.opened}
                    onChange={filtersStorage.setFilter}
                    field={filter.field}
                    onToggle={filter.toggleOpened}
                    expanded={expanded}
                    bottomBordered
                    subtitle={t("Other size")}
                    expandable={false}
                  />
                </Accordion>
              </>
            );
            break;
          case "range":
            return (
              <PriceFilter
                key={filter.title}
                title={t(filter.title)}
                data={filter.items}
                opened={filter.opened}
                onToggle={filter.toggleOpened}
                expanded={expanded}
              />
            );
            break;
          default:
            return null;
        }
      })}
      {/* <ColorFilters/> */}
      {/* <SizesFilter/> */}
      {/* <PriceFilter /> */}
      {/* <BrandsFilter/> */}
    </>
  );
}

Filters.defaultProps = {
  expanded: true,
};
Filters.propTypes = {};

export default withTranslation()(observer(Filters));
