import React, { Component } from "react";
import PropTypes from "prop-types";
import noImageLink from "../../../assets/images/noImage.jpg";
import preloader from "../../../assets/images/shella-preloader.svg";

class LoadableImage extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   link: preloader,
    //   loadedStatus: "loading",
    // };
  }

  componentDidMount() {
    const { link } = this.props;
    // this.tryLoadImage(link);
  }

  // eslint-disable-next-line no-unused-vars
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { link } = this.props;
  //   const { link: prevLink } = prevProps;
  //   if (prevLink !== link) {
  //     this.tryLoadImage(link);
  //   }
  // }

  tryLoadImage = (link) => {
    if (!link) {
      return;
    }
    this.setState(
      (status) => ({
        link: status.loadedStatus === "loaded" ? status.link : preloader,
        status: status.loadedStatus !== "loaded" ? status.status : "loading",
      }),
      () => {
        const image = document.createElement("img");
        image.onload = () => {
          this.setState(() => ({ loadedStatus: "loaded", link }));
        };
        image.onerror = () => {
          this.setState(() => ({ loadedStatus: "loaded", link: noImageLink }));
        };
        image.src = link;
      }
    );
  };

  render() {
    // const { link, loadedStatus } = this.state;
    const { link, className, alt, title, lazy = true } = this.props;

    return (
      <>
        {/* {loadedStatus === "loading" && ( */}
        {/*  <div */}
        {/*    className={`${className} d-flex justify-content-center align-items-center`} */}
        {/*  > */}
        {/*    <img src={preloader} style={{ height: "90%" }} alt="" /> */}
        {/*  </div> */}
        {/* )} */}
        {/* {loadedStatus === "loaded" && ( */}
        <img
          // className={`${
          //   loadedStatus === "loaded"
          //     ? className
          //     : "rimage__img lazyload loaded"
          // }`}
          className={className}
          style={{
            backgroundImage: `url(${preloader})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "initial",
            backgroundPosition: "center",
          }}
          src={link}
          alt={alt}
          title={title}
          loading={lazy ? "lazy" : null}
        />
        {/* )} */}
      </>
    );
  }
}

LoadableImage.defaultProps = {
  link: null,
  alt: null,
  className: "",
};

LoadableImage.propTypes = {
  link: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default LoadableImage;
