import React from "react";
import layoutStorage from "../../storages/layoutStorage";
import Indicator from "../../layouts/main/HeaderParts/Indicator";

import SvgContaineredImage from "../../UI/atoms/images/SvgContaineredImage";

/**
 * +
 * @returns {JSX.Element}
 * @constructor
 */
function SearchIndicator() {
  return (
    <Indicator
      openPopup={layoutStorage.openPopup}
      popperText="Поиск"
      popperPlacement="bottom"
      className="header__btn-search js-popup-button"
      href={null}
      icon={<SvgContaineredImage id="searchIcon" />}
      name="search"
    />
  );
}

SearchIndicator.propTypes = {};

export default SearchIndicator;
