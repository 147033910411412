import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DropDownIcon from "../../assets/SvgIcons/DropDownIcon";

class DropDownArrow extends Component {
  render() {
    const { className } = this.props;
    return (
      <i className={className}>
        <DropDownIcon />
      </i>
    );
  }
}

DropDownArrow.defaultProps = {
  className: "d-none d-lg-inline position-lg-relative"
};
DropDownArrow.propTypes = {
  className: PropTypes.string,
};

export default DropDownArrow;
