import React, { Component, useState } from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import layoutStorage from "../../../storages/layoutStorage";
// import DrawerPopUp from "./DrawerPopUp";
import accountPageStorage from "../../../storages/forPages/accountPageStorage";
import TextInput from "../../../UI/atoms/inputs/TextInput";
import currentUserStorage from "../../../storages/currentUserStorage";
import HorizontalSeparator from "../../../UI/atoms/HorizontalSeparator";
import ErrorBox from "../../../UI/atoms/blocks/ErrorBox";
import LabeledTextInput from "../../../UI/molecules/inputs/LabeledTextInput";
import currentOrderStorage from "../../../storages/orderStorage";
import Drawer from "../../../UI/organisms/Drawer";
import Core from "../../../core/Core";

class AccountPopup extends Component {
  onEnterPressAuth = (e) => {
    if (e.keyCode === 13 && currentUserStorage.isAuthDataFilled) {
      currentUserStorage.login();
    }
  };

  onEnterPressReg = (e) => {
    if (e.keyCode === 13 && currentUserStorage.isRegisterDataFilled) {
      currentUserStorage.register();
    }
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const { title, id } = accountPageStorage;
    if (!layoutStorage.popups.account) return false;
    return (
      <Drawer
        title={t(title)}
        opened={layoutStorage.popups.account}
        name="account"
        position="right"
        onClose={() => layoutStorage.closePopup("account")}
      >
        <div className="popup-account__login">
          {!currentUserStorage.ID && (
            <>
              <HorizontalSeparator text={t("Log in")} />
              <input type="hidden" name="utf8" value="✓" />
              <label className="w-100">
                {t("Phone")}
                {currentOrderStorage.validateErrors.loginPhone !== "" && (
                  <ErrorBox
                    text={t(currentOrderStorage.validateErrors.loginPhone)}
                  />
                )}
                <TextInput
                  type="text"
                  name="login"
                  placeholder="+380112223344"
                  required="required"
                  value={currentUserStorage.authData.login}
                  onChange={(text) => currentUserStorage.setAuthLogin(text)}
                  onKeyPress={this.onEnterPressAuth}
                  isValid={
                    currentUserStorage.isAuthLoginValid &&
                    currentOrderStorage.validateErrors.loginPhone === ""
                  }
                />
              </label>
              <label className="w-100">
                {t("Password")}
                <TextInput
                  type="password"
                  className="form-item"
                  name="password"
                  onKeyPress={this.onEnterPressAuth}
                  placeholder="*********"
                  required="required"
                  onChange={(text) => currentUserStorage.setAuthPassword(text)}
                  value={currentUserStorage.authData.password}
                  isValid={currentUserStorage.isAuthPasswordValid}
                />
              </label>
              <ErrorBox text={t(currentUserStorage.authStatusMessage)} />
              <button
                className="btn btn--full btn--secondary mb-20 form-item"
                onClick={() => currentUserStorage.login()}
                disabled={!currentUserStorage.isAuthDataFilled}
              >
                {t("Log in")}
              </button>
              {/* <button */}
              {/*  className="btn btn--full btn--secondary mb-20" */}
              {/*  onClick={() => currentUserStorage.register()} */}
              {/*  disabled={!currentUserStorage.isAuthLoginValid} */}
              {/* > */}
              {/*  {t("Restore password")} */}
              {/* </button> */}
              <HorizontalSeparator text={t("Register")} />
              <input type="hidden" name="utf8" value="✓" />
              <label className="w-100">
                {t("Phone")}
                {currentOrderStorage.validateErrors.registerPhone !== "" && (
                  <ErrorBox
                    text={t(currentOrderStorage.validateErrors.registerPhone)}
                  />
                )}
                <TextInput
                  type="text"
                  name="login"
                  placeholder="+380112223344"
                  required="required"
                  onChange={(text) => currentUserStorage.setRegLogin(text)}
                  value={currentUserStorage.regData.login}
                  onKeyPress={this.onEnterPressReg}
                  isValid={
                    currentUserStorage.isRegisterLoginValid &&
                    currentOrderStorage.validateErrors.registerPhone === ""
                  }
                />
              </label>
              <label className="w-100">
                {t("Password")}
                <TextInput
                  type="password"
                  name="password"
                  placeholder="*********"
                  required="required"
                  value={currentUserStorage.regData.password}
                  onChange={(text) => currentUserStorage.setRegPassword(text)}
                  onKeyPress={this.onEnterPressReg}
                  isValid={
                    currentUserStorage.isRegisterPasswordValid /* ||
                  !currentUserStorage.isRegDataStartFill */
                  }
                />
              </label>
              <label className="w-100">
                {t("Name")}
                <TextInput
                  type="text"
                  name="Name"
                  placeholder={t("Name")}
                  required="required"
                  onChange={(text) => currentUserStorage.setRegUserName(text)}
                  onKeyPress={this.onEnterPressReg}
                  value={currentUserStorage.regData.userName}
                  isValid={currentUserStorage.isRegisterUserNameValid}
                />
              </label>
              <label className="w-100">
                {t("Surname")}
                <TextInput
                  type="text"
                  name="userSurname"
                  placeholder={t("Surname")}
                  required="required"
                  onChange={(text) =>
                    currentUserStorage.setRegUserSurname(text)
                  }
                  onKeyPress={this.onEnterPressReg}
                  value={currentUserStorage.regData.userSurname}
                  isValid={currentUserStorage.isRegisterUserSurNameValid}
                />
              </label>
              <ErrorBox text={t(currentUserStorage.regStatusMessage)} />

              <div className="pb-15 font-weight-bold">
                <LabeledTextInput
                  id="agreementWithTerms"
                  name="agreementWithTerms"
                  label={t("I agree with terms and conditions")}
                  onChange={() => currentUserStorage.toggleCheckbox()}
                  onKeyPress={this.onEnterPressReg}
                  value={currentUserStorage.enabled}
                  type="checkbox"
                  labelAppend
                  isRequired
                  isAgreementLabel
                  isValid={currentUserStorage.enabled}
                />
                <Link to="/ru/content/toc" className="font-weight-light pl-20">
                  {t("Read the terms and conditions here")}
                </Link>
              </div>

              <button
                className="btn btn--full btn--secondary mb-20"
                onClick={() => currentUserStorage.register()}
                disabled={!currentUserStorage.isRegisterDataFilled}
              >
                {t("Register")}
              </button>
            </>
          )}
          {currentUserStorage.ID && (
            <>
              {currentUserStorage.userName} {currentUserStorage.userSoname}
              <br />
              <br />
              <button
                className="btn btn--full btn--secondary mb-20"
                onClick={() => currentUserStorage.logout()}
              >
                {t("LOG OUT")}
              </button>
              {layoutStorage.allowUserAccount && (
                <Link
                  className="btn btn--full btn--secondary mb-20"
                  to={Core.routing.createRoute(
                    Core.routing.routingSegments.SEGMENT_ACCOUNT,
                    {}
                  )}
                  onClick={() => layoutStorage.closePopup("account")}
                >
                  {t("Account")}
                </Link>
              )}
            </>
          )}
          {/*  <div className="mb-10"> */}
          {/*    <a */}
          {/*      href="login.html" */}
          {/*      className="btn-link js-button-block-visibility" */}
          {/*      data-block-link="#recover" */}
          {/*      data-action="open" */}
          {/*      data-action-close-popup="account" */}
          {/*    > */}
          {/*      Forgot Your Password? */}
          {/*    </a> */}
          {/*  </div> */}
          {/*  <hr className="my-15" /> */}
          {/*  <div className="popup-account__head d-flex align-items-center mb-10"> */}
          {/*    <h5 className="m-0">SIGN UP</h5> */}
          {/*  </div> */}
          {/*  <div> */}
          {/*    <p className="h6 mb-15">Enjoy the benefits of registering:</p> */}
          {/*    <ul className="mb-20"> */}
          {/*      <li className="mb-15"> */}
          {/*        Order: view Order History, track and manage purchases and returns */}
          {/*      </li> */}
          {/*      <li className="mb-15"> */}
          {/*        Address Book and Card Wallet: safely store delivery and payment */}
          {/*        details for faster checkout */}
          {/*      </li> */}
          {/*      <li className="mb-15"> */}
          {/*        Saved for later: wish list your preferred items and track their */}
          {/*        availability */}
          {/*      </li> */}
          {/*    </ul> */}
          {/*  </div> */}
          {/*  <a */}
          {/*    href="register.html" */}
          {/*    className="btn btn--full btn--secondary mb-20 js-popup-account-show-sign-up" */}
          {/*  > */}
          {/*    Sign up now! */}
          {/*  </a> */}
        </div>
      </Drawer>
    );
  }
}

AccountPopup.propTypes = {};

export default withTranslation()(observer(AccountPopup));
