import React from "react";
import classNames from "classnames";
import IconInButton from "../../atoms/buttons/Icons/IconInButton";
import IconTheme110 from "../../../assets/SvgIcons/Themed/IconTheme110";

function AddToCartButton({
  icon,
  onClick,
  className,
  text,
  disabled,
  inCart,
  isButtonLarge,
}) {
  const buttonClass = classNames({
    "product-collection__button-add-to-cart mb-10": true,
    "btn-add-large": isButtonLarge,
    "btn-add-middle": !isButtonLarge,
  });
  return (
    <div
      className={buttonClass}
      // className="product-collection__button-add-to-cart mb-10"
      // style={{ maxWidth: "70%" }}
    >
      <button
        className={className}
        name="add"
        onClick={onClick}
        disabled={disabled}
      >
        <span className="d-flex flex-center">
          {/* {!inCart && <IconInButton icon={icon} />} */}
          <IconInButton icon={icon} />
          <span className="btn__text" style={{ whiteSpace: "nowrap" }}>
            {text}
          </span>
        </span>

        {/* <span className="d-flex flex-center" data-button-content="added"> */}
        {/* <i className="mr-5 mb-4">{inCart && <IconTheme110 />}</i> */}
        {/* ADDED */}
        {/* </span> */}
        {/* <span className="d-flex flex-center" data-button-content="sold-out"> */}
        {/* SOLD OUT */}
        {/* </span> */}
      </button>
    </div>
  );
}

AddToCartButton.propTypes = {};

export default AddToCartButton;
