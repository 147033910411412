import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import currentCategoryStorage from "../storages/currentCategoryStorage";
import globalStorage from "../storages/globalStorage";
import BreadcrumbsBlock from "../UI/molecules/breadcrumbs/BreadcrumbsBlock";
import Page from "../layouts/Page";
import sitemapStorage from "../storages/forPages/sitemapStorage";
import LinksTree from "../UI/organisms/links/LinksTree";
import "./Sitemap.scss";
import Core from "../core/Core";

/**
 * Css passing
 */
class Test extends Component {
  render() {
    const {
      metaTitle,
      metaDescription,
      ogTitle,
      ogDescription,
      ogUrl,
      ogImage,
      h1,
      afterProducts,
    } = currentCategoryStorage.texts;
    const { t } = this.props;
    const breadcrumbsItems = [];
    return (
      <Page id="SitemapPage" className="page sitemapPage" loadingConfig={{}}>
        <MetaTags>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={ogTitle || metaTitle} />
          <meta
            property="og:description"
            content={ogDescription || metaDescription}
          />
          <meta property="og:url" content={ogUrl || window.location.href} />
          <meta property="og:image" content={ogImage} />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json" id="ldjsonscript">
            {JSON.stringify(
              [
                globalStorage.jsonLdSectionWebSite,
                globalStorage.jsonLdSectionOrganization,
                ...globalStorage.jsonLdSectionStore,
              ],
              null,
              4
            )}
          </script>
        </MetaTags>
        <div className="page-container">
          <BreadcrumbsBlock
            homeLink={Core.routing.predefinedRoutes.home}
            homeTitle={t("HOME")}
            currentTitle={h1}
            items={breadcrumbsItems}
          />
          <LinksTree
            h1={h1}
            firstBlock={{
              title: t("HOME PAGE"),
              link: Core.routing.predefinedRoutes.home,
            }}
            blocks={sitemapStorage.structure}
          />
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: afterProducts }}
          />
        </div>
      </Page>
    );
  }
}

Test.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(Test));
