import React from "react";
import PropTypes from "prop-types";
import LinksBlockHeader from "./LinksBlockHeader";

/**
 * CSS passed
 *
 * @param block
 * @returns {JSX.Element}
 * @constructor
 */
function LinksTreeBlock({ block }) {
  return (
    <div className="links-tree__block">
      <LinksBlockHeader
        title={block.title}
        link={block.link}
        childItems={block.children}
      />
    </div>
  );
}

LinksTreeBlock.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.array.isRequired,
  }).isRequired,
};

export default LinksTreeBlock;
