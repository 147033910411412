import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import notificationsStorage from "../storages/NotificationsStorage";
import NotificationComponent from "./NotificationComponent";
import NotificationModel from "../models/NotificationModel";

class NotificationsContainer extends Component {
  notificationsModel = new NotificationModel();

  componentDidMount() {
    notificationsStorage.loadNotifications();
    this.notificationsModel.closeAllNotificationsByTime();
  }

  render() {
    return (
      <div className="notification position-relative js-notification">
        <div
          className="notification__inner px-sm-10 px-lg-15 mb-sm-10 mb-lg-15 js-notification-products show visible"
          data-js-delay="8"
          data-js-interval-min="10"
          data-js-interval-max="18"
          data-js-max-time-life="14"
          data-js-notification-inner
        >
          {notificationsStorage.notifications.map((notification) => (
            <div className="notification-products p-10 pointer-events-all">
              <NotificationComponent
                {...notification}
                onClose={() => {
                  this.notificationsModel.closeNotification(notification);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

NotificationsContainer.propTypes = {};

export default observer(NotificationsContainer);
